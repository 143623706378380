import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import WOW from 'wowjs';
import { CircleArrow as ScrollUpButton } from 'react-scroll-up-button';
import { toast } from 'react-toastify';
import moment from 'moment';
import parse from 'html-react-parser';
import AdminHeader from './adminheader';
import AdminPostDetail from './adminPostDetail';
import httpAdminService from '../../services/httpAdminService';

class AdminPostDetailPage extends Component {
  state = {
    postDetail: '',
    user: '',
  };
  async componentDidMount() {
    let model;
    if (window.location.href.includes('/bookClubPost')) model = 'bookClubPost';
    if (model) this.postAPI();
    else this.postDetail();

    new WOW.WOW({
      live: false,
    }).init();
  }
  async postAPI(name, page, sort = '') {
    try {
      const { data } = await httpAdminService.get(
        `${process.env.REACT_APP_APIENDPOINT}/admin/bookClubPost/post?id=${this.props.match.params.id}`,
      );

      if (data) {
        data.postList[0].data.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('MMM DD, YYYY h:mm A');
          item.description = parse(item.description);
        });
        const listData = data.postList[0].data;
        this.setState({ postDetail: listData });

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  async postDetail() {
    try {
      const { data } = await httpAdminService.get(
        `${process.env.REACT_APP_APIENDPOINT}/admin/post-detail/${this.props.match.params.id}`,
      );
      if (data) {
        data.postDetail[0].creationDate = moment(data.postDetail[0].creationDate).format('MMM DD, YYYY h:mm A');
        data.postDetail[0].description = parse(data.postDetail[0].description);
        const postDetail = data.postDetail;
        this.setState({ postDetail: postDetail });
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  render() {
    return (
      <div className="userhomepage">
        <div className="container-fluid menu-parts wow slideInDown">
          <AdminHeader />
        </div>
        <div className="container-fluid user-activity">
          <div className="container">
            <div className="row">
              <div className="col-12 wow fadeInUp">
                <div className="row">
                  <div className="col-8 offset-2 wow fadeInUp">
                    <AdminPostDetail
                      postDetail={this.state.postDetail}
                      {...this.props}
                      user={this.state.user}
                      {...this.props}
                      postComment={this.state.postComment}
                      {...this.props}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ScrollUpButton />
      </div>
    );
  }
}

export default AdminPostDetailPage;
