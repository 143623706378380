import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-tabs/style/react-tabs.css';
import Form from '../macroComponents/form/form';
import Joi from 'joi-browser';
import * as registerService from './../services/registerServices';
class Feedbacksupport extends Form {
  state = {
    formData: {
      message: '',
    },
    errors: {},
  };
  schema = {
    message: Joi.string().max(300).required(),
  };

  doSubmit = async () => {
    this.props.onSubmit();
    this.setState({
      formData: {
        message: '',
      },
    });
    const { error } = await registerService.feedback(this.state.formData);
    if (error) return;
    this.state.feedback = false;
  };
  constructor() {
    super();
    this.state.feedback = false;
  }

  render() {
    return (
      <div className="dashboard-contactus-section">
        <div className="collection">
          <h4 className="text-center">Give Us Feedback </h4>
          <p className="text-center">Please enter your suggestions below </p>

          <div className="selectpart">
            <div className="form-group form-section">
              <form>
                {this.renderTextArea('message', 'Message')}
                {this.renderButton('Send Feedback', this.handleSubmit)}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Feedbacksupport;
