import httpAdminService from './httpAdminService';
import { toast } from 'react-toastify';

export async function createEventOrClass(formData) {
  try {
    const { data } = await httpAdminService.post(
      `${process.env.REACT_APP_APIENDPOINT}/admin/createEventOrClass`,
      formData,
    );
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function createLearnWithBthrivePost(formData) {
  try {
    const { data } = await httpAdminService.post(
      `${process.env.REACT_APP_APIENDPOINT}/admin/createLearnWithBthrivePost`,
      formData,
    );
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function editLearnWithBthrivePost(formData) {
  try {
    const { data } = await httpAdminService.post(
      `${process.env.REACT_APP_APIENDPOINT}/admin/editLearnWithBthrivePost`,
      formData,
    );
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function adminUpdateCommentStatus(formData) {
  try {
    const { data } = await httpAdminService.post(`${process.env.REACT_APP_APIENDPOINT}/admin/updateCommentStatus`, {
      type: formData.type,
      _id: formData._id,
    });
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function adminChangePassword(formData) {
  try {
    const { data } = await httpAdminService.post(`${process.env.REACT_APP_APIENDPOINT}/admin/changePassword`, formData);
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function addZoomLink(formData) {
  try {
    const { data } = await httpAdminService.post(`${process.env.REACT_APP_APIENDPOINT}/admin/addZoomLink`, formData);
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}
