import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import About from './tabspart/about';
import Post from './tabspart/post';
import Bookmark from './tabspart/bookmark';
import Collection from './tabspart/collection';
import Blogs from './tabspart/blogs';

class ProfileTabs extends Component {
  state = {};
  render() {
    return (
      <div className="tabs-profile-section mt-3">
        <div className="tabs-section">
          <Tabs>
            <TabList>
              <Tab>About</Tab>
              <Tab>Post </Tab>
              <Tab>Collections</Tab>
              <Tab>Bookmarks </Tab>
              {this.props.user && this.props.user.isBlogger == true ? <Tab>Blog Posts </Tab> : ''}
            </TabList>

            <TabPanel>
              <About user={this.props.user} />
            </TabPanel>
            <TabPanel>
              <Post user={this.props.user} />
            </TabPanel>
            <TabPanel>
              <Collection />
            </TabPanel>
            <TabPanel>
              <Bookmark user={this.props.user} {...this.props} />
            </TabPanel>
            <TabPanel>
              <Blogs />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default ProfileTabs;
