import React, { Component } from 'react';
import GoogleLogin from 'react-google-login';
import * as registerService from '../services/registerServices';
import { withRouter } from 'react-router-dom';

const clientId = '943111964667-vb9o9kl3pv6nv12r12mu8cfhi43s4ldp.apps.googleusercontent.com';
class GoogleRegisteration extends Component {
  constructor(props) {
    super(props);
    this.onSuccess = this.onSuccess.bind(this);
  }
  onSuccess = async (res) => {
    const { data, error } = await registerService.googleAuthentication(res.tokenId);
    if (error) return;
    if (data.isregistered)
      return await this.props.history.push({
        pathname: `/choose-interest`,
        state: data.user,
      });
    else return await this.props.history.push(`/user-home`);
  };
  render() {
    return (
      <div className="Login-sec">
        <GoogleLogin
          clientId={clientId}
          buttonText="Continue to Signin with Google"
          onSuccess={this.onSuccess}
          onFailure={this.onFailure}
          cookiePolicy={'single_host_origin'}
        />
      </div>
    );
  }
}
export default withRouter(GoogleRegisteration);
