import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { Button, Modal } from 'react-bootstrap';
import ImageUploader from 'react-images-upload';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import httpService from '../../services/httpService';
import { toast } from 'react-toastify';
import WOW from 'wowjs';
import Joi from 'joi-browser';
import * as registerService from '../../services/registerServices';
import Form from '../../macroComponents/form/form';
import MediaQuery from 'react-responsive';

class RightPart extends Form {
  state = {
    formData: {
      idea: '',
      date: '',
    },
    host: '',
    selectedFile1: null,
    errors: {},
  };
  schema = {
    idea: Joi.string().max(30).allow(''),
    date: Joi.date().required(),
  };
  doSubmit = async () => {
    if (!this.state.selectedFile1) {
      toast.error('Please select Cover Picture');
      return;
    }
    if (this.state.selectedFile1.size < 2880) {
      toast.error('Minimum File size should be 1MB');
      return;
    } else if (this.state.selectedFile1.size > 5242880) {
      toast.error('File size exceeded. Max size should be 5MB.');
      return;
    }
    if (new Date(this.state.formData.date) < new Date(Date.now())) {
      toast.error('select an upcoming date');
      return;
    }
    let formData = new FormData();
    {
      let data = { ...this.state.formData };
      for (const item in data) {
        if (data[item] === '') {
          data[item] = 'N/A';
        }
        formData.append(item, data[item]);
      }
    }

    formData.append('eventImage', this.state.selectedFile1, this.state.selectedFile1.name);

    this.handleModalShowHide();
    const { error } = await registerService.becomeHost(formData);

    this.setState({ selectedFile1: null });
    if (error) return;
  };

  handleModalShowHide1 = () => {
    this.setState({ showHide1: !this.state.showHide1 });
  };
  async componentDidMount() {
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/getHost`);
      if (data) {
        this.setState({ host: data.host });
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }

    new WOW.WOW({
      live: false,
    }).init();
  }
  onFileSelection = async (event) => {
    let file = event[0];

    if (file && file.size < 2880) {
      toast.error('Minimum File size should be 1MB.');
    } else if (file && file.size > 5242880) {
      toast.error('File size exceeded. Max size should be 5MB.');
    } else {
      // Update the state
      await this.setState({ selectedFile1: event[0] });
    }
  };
  getDate = (value) => {
    if (new Date(value) < new Date(Date.now())) toast.error('select an upcoming date');
    const formData = { ...this.state.formData };
    formData.date = new Date(value);

    this.setState({ formData });
  };
  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide });
    this.setState({
      formData: {
        idea: '',
        date: '',
      },
    });
  }
  constructor() {
    super();
    this.state.becomeHost = false;
  }

  render() {
    return (
      <>
        <div style={{ overflow: 'auto' }}>
          <div className="book-club-right">
            <div className="heading-part">
              <h3>Currently Reading</h3>
            </div>
            <div className="main-part">
              {this.state.host && (
                <>
                  <div className="book-part">
                    <img src={this.state.host.coverImage} alt="Event" />
                    <h5> {this.state.host.idea}</h5>
                  </div>
                  <div className="host-month text-center">
                    <p>Host of the month</p>
                    <img
                      className={this.state.host.host_data.isBlogger ? 'blogger' : ''}
                      src={
                        this.state.host.host_data.profilePicture
                          ? this.state.host.host_data.profilePicture
                          : '../../assets/images/user/01.jpg'
                      }
                      alt="Host"
                    />
                    <h5>{this.state.host.host_data.name}</h5>
                  </div>
                </>
              )}
              <div className="hostlink">
                <Link onClick={() => this.handleModalShowHide()}>Become a Host</Link>
                <Modal show={this.state.showHide} className="becomehost-modal ">
                  <Modal.Body>
                    <Link className="closeicon" onClick={() => this.handleModalShowHide()}>
                      <i className="fa fa-close"></i>
                    </Link>
                    <div className="collection">
                      <h4 className="text-center">Become a Host</h4>
                      <p className="text-center">Select an available date to Continue </p>
                      <div className="selectpart">
                        <div className="host-date">
                          <Calendar
                            onChange={(data) => {
                              this.getDate(data);
                            }}
                          />
                        </div>

                        {this.renderInput('idea', 'Book idea/Host idea (optional)')}
                        <div className="form-group">
                          <label>Add a Cover Image</label>
                          <div className="collection-image">
                            <ImageUploader
                              withIcon={true}
                              withPreview={true}
                              buttonText={<i className="fa fa-camera"></i>}
                              onChange={this.onFileSelection}
                              imgExtension={['.jpg', '.jpeg', '.gif', '.png']}
                              maxFileSize={5242880}
                              singleImage={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="admin-btn button">{this.renderButton('Become a Host', this.handleSubmit)}</div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
            <div className="book-club-about">
              <div className="heading-part mb-0">
                <h3>About the Book Club</h3>
                <p className="">
                  This book club is a space to exchange insights and discussions around a specific book for each month
                </p>
              </div>
            </div>
            <div className="past-event bookclub-button" style={{ marginLeft: '-15px' }}>
              <Link to="/past-events">Past Book Club</Link>
            </div>
          </div>
        </div>
        <MediaQuery query="(max-width:767px)">
          <Link onClick={() => this.handleModalShowHide1()}>
            {' '}
            <span className="event-btn">Host</span>
          </Link>
          <div>
            <div className="book-club-right1">
              <div className="heading-part">
                <h3>Currently Reading</h3>
              </div>
              <div className="main-part">
                {this.state.host && (
                  <>
                    <div className="book-part">
                      <img src={this.state.host.coverImage} alt="Event" />
                      <h5> {this.state.host.idea}</h5>
                    </div>
                    <div className="host-month text-center">
                      <p>Host of the month</p>
                      <img
                        className={this.state.host.host_data.isBlogger ? 'blogger' : ''}
                        src={
                          this.state.host.host_data.profilePicture
                            ? this.state.host.host_data.profilePicture
                            : '../../assets/images/user/01.jpg'
                        }
                        alt="Host"
                      />
                      <h5>{this.state.host.host_data.name}</h5>
                    </div>
                  </>
                )}
                <div className="hostlink">
                  <Link onClick={() => this.handleModalShowHide()}>Become a Host</Link>
                  <Modal show={this.state.showHide} className="becomehost-modal ">
                    <Modal.Body>
                      <Link className="closeicon" onClick={() => this.handleModalShowHide()}>
                        <i className="fa fa-close"></i>
                      </Link>
                      <div className="collection">
                        <h4 className="text-center">Become a Host</h4>
                        <p className="text-center">Select an available date to Continue </p>
                        <div className="selectpart">
                          <div className="host-date">
                            <Calendar
                              onChange={(data) => {
                                this.getDate(data);
                              }}
                            />
                          </div>

                          {this.renderInput('idea', 'Book idea/Host idea (optional)')}
                          <div className="form-group">
                            <label>Add a Cover Image</label>
                            <div className="collection-image">
                              <ImageUploader
                                withIcon={true}
                                withPreview={true}
                                buttonText={<i className="fa fa-camera"></i>}
                                onChange={this.onFileSelection}
                                imgExtension={['.jpg', '.jpeg', '.gif', '.png']}
                                maxFileSize={5242880}
                                singleImage={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="admin-btn button">{this.renderButton('Become a Host', this.handleSubmit)}</div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
              <div className="book-club-about1">
                <div className="heading-part mb-0">
                  <h3>About the Book Club</h3>
                  <p className="">
                    This book club is a space to exchange insights and discussions around a specific book for each month
                  </p>
                </div>
              </div>
              <div className="past-event bookclub-button">
                <Link to="/past-events">Past Book Club</Link>
              </div>
            </div>
          </div>
        </MediaQuery>
      </>
    );
  }
}

export default RightPart;
