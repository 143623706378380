import httpService from './httpService';
import { toast } from 'react-toastify';

export async function createPost(formData) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/createPost`, formData);
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function editPost(formData) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/editPost`, formData);
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function createBookClubPost(formData) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/createBookClubPost`, formData);
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}
export async function createBookClubBookMark(post_id) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/createBookClubBookMark/${post_id}`);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}
export async function createBookMark(post_id) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/createBookMark/${post_id}`);
    // toast.success(data.message);
    // if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}
export async function deleteBookMark(post_id) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/deleteBookMark/${post_id}`);
    // toast.success(data.message);
    // if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function deleteBookClubBookMark(post_id) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/deleteBookClubBookMark/${post_id}`);
    // toast.success(data.message);
    // if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}
export async function createComment(formData, type = 'post', modal = '') {
  try {
    const { data } = await httpService.post(
      `${process.env.REACT_APP_APIENDPOINT}/createComment/${type}?modal=${modal}`,
      {
        postId: formData.postId,
        description: formData.description,
        type: formData.type,
      },
    );
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function updateCommentStatus(formData) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/updateCommentStatus`, {
      type: formData.type,
      _id: formData._id,
    });
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function createCollectionLike(formData) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/createCollectionLike`, {
      collectionId: formData.postId,
      type: formData.type,
    });
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function createLike(formData) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/createLike/post`, {
      postId: formData.postId,
      type: formData.type,
    });
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function createBookClubLike(formData) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/createLike/bookClubPost`, {
      postId: formData.postId,
      type: formData.type,
    });
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function createCollection(formData) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/createCollection`, formData);
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function addPostToCollection(formData, postId) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/addPostToCollection`, {
      postId: postId,
      collectionId: formData.collection_name,
    });
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function removePostFromCollection(postId, collectionId) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/removePostFromCollection`, {
      postId: postId,
      collectionId: collectionId,
    });
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function addBlog(formData) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/createBlog`, {
      description: formData.description,
      link: formData.link,
    });
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function editBlog(formData, id) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/editBlog/${id}`, {
      description: formData.description,
      link: formData.link,
    });
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function deleteBlog(id) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/deleteBlog/${id}`, {});
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}
export async function report(formData, id) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/reportPost/${id}`, formData);
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}
