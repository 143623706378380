import React from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-tabs/style/react-tabs.css';
import Form from '../macroComponents/form/form';
import Joi from 'joi-browser';
import * as registerService from './../services/registerServices';
import Feedbacksupport from './feedbacksupport';
import { toast } from 'react-toastify';
class Contactus extends Form {
  state = {
    formData: {
      name: '',
      email: '',
      message: '',
    },
    errors: {},
  };

  handleModalShowHide = () => {
    this.setState({ showHide: !this.state.showHide });
  };
  contactModalShowHide = () => {
    this.setState({ contactshowHide: !this.state.contactshowHide });
    this.setState({
      formData: {
        name: '',
        email: '',
        message: '',
      },
    });
  };
  ValidateEmail = (inputText) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (inputText.match(mailformat)) return true;
    return false;
  };

  schema = {
    name: Joi.string().min(3).max(30).required(),
    email: Joi.string().min(3).required().email(),
    message: Joi.string().max(100).required(),
  };
  doSubmit = async () => {
    if (!this.ValidateEmail(this.state.formData.email)) {
      toast.error('Invalid Email');
      return;
    }
    this.contactModalShowHide();
    const { error } = await registerService.contactUs(this.state.formData);
    if (error) return;
    this.state.contactUs = false;
  };
  constructor() {
    super();
    this.state.contactUs = false;
  }

  render() {
    return (
      <div>
        <div className="feedback-support">
          <div className="main-section">
            <Link onClick={() => this.handleModalShowHide()}>Give Feedback</Link>
            <Link onClick={() => this.contactModalShowHide()}>Contact Support</Link>
          </div>
          <div>
            <Modal show={this.state.showHide} className="addcollection-modal">
              <Modal.Body>
                <Link className="closeicon" onClick={() => this.handleModalShowHide()}>
                  <i className="fa fa-close"></i>
                </Link>
                <Feedbacksupport onSubmit={this.handleModalShowHide} />
              </Modal.Body>
            </Modal>
            <Modal show={this.state.contactshowHide} className="addcollection-modal ">
              <Modal.Body>
                <Link className="closeicon" onClick={() => this.contactModalShowHide()}>
                  <i className="fa fa-close"></i>
                </Link>
                <div className="dashboard-contactus-section">
                  <div className="top-section text-center">
                    <h3>Contact Us</h3>
                    <p>Kindly fill the detail below</p>
                  </div>
                  <div className="form-section wow bounceIn">
                    <form>
                      {this.renderInput('name', 'Name', 'Enter Name')}
                      {this.renderInput('email', 'Email', 'Enter Email')}
                      {this.renderTextArea('message', 'Message')}
                      {this.renderButton('Submit', this.handleSubmit)}
                    </form>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
          <div></div>
        </div>
      </div>
    );
  }
}

export default Contactus;
