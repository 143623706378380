import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import Header from './../commonComponents/header';
import WOW from 'wowjs';
import Footer from './../commonComponents/footer';
import Form from '../macroComponents/form/form';
import Joi from 'joi-browser';
import * as registerService from './../services/registerServices';
import { toast } from 'react-toastify';
import GoogleRegisteration from './googleRegisteration';
import LinkedinRegisteration from './linkedinRegisteration';
class LoginPage extends Form {
  state = {
    formData: {
      email: '',
      password: '',
    },
    errors: {},
  };
  schema = {
    email: Joi.string()
      .email({ tlds: { allow: ['com', 'net'] } })
      .required(),
    password: Joi.string().required().min(8),
  };
  ValidateEmail = (inputText) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (inputText.match(mailformat)) return true;
    return false;
  };
  doSubmit = async () => {
    if (!this.ValidateEmail(this.state.formData.email)) {
      toast.error('Invalid Email');
      return;
    }
    const { data, error } = await registerService.authentication(this.state.formData);
    if (error) return;

    //check whether registeration process completed or not
    if (data.registerationCompleted) {
      return await this.props.history.push('/user-home');
    } else {
      toast.error(data.message);
      if (!data.isVerified) {
        const { error: ex } = await registerService.otpGeneration(this.state.formData.email);
        if (ex) return;
        this.props.submitEmail(this.state.formData.email);
        return await this.props.history.push({
          pathname: `/verify`,
          email: this.state.formData.email,
        });
      }
      if (!data.isRegistered) {
        return await this.props.history.push('/get-start');
      }
    }
  };
  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();
  }
  render() {
    return (
      <div className="loginpage">
        <div className="container-fluid login-header">
          <Header {...this.props} />
        </div>
        <div className="login-section ">
          <div className="container">
            <div className="top-section text-center">
              <Link to="#">
                <img src="assets/images/icons/login.png" alt="Login logo" />{' '}
              </Link>
              <h3>Login to B-Thrive</h3>
              <p>Welcome back, please sign in</p>
            </div>
            <div className="form-section wow bounceIn">
              <form>
                {this.renderInput('email', 'Enter Email Address', 'Enter Email Address')}
                {this.renderInput('password', 'Enter Password', 'Enter Password', 'password')}
                {this.renderButton('Sign In', this.handleSubmit)}
                <div className="social-login ">
                  <p className="mt-3 mb-3 text-center">or</p>
                  {/* <Link to="#">Continue to signin with Facebook <img src="assets/images/icons/facebook.png" alt="Facebook" /> </Link> */}
                  <GoogleRegisteration />
                  <LinkedinRegisteration />
                </div>
              </form>
            </div>
            <div className="login-footer text-center mt-5">
              <p>
                New to B-Thrive? <Link to="/registration">Create An Account</Link>
              </p>
              <p>
                Can't Login? <Link to="/forgot-password">Reset Password</Link>
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default LoginPage;
