import httpService from './httpService';
import { toast } from 'react-toastify';

export async function createAccount(formData) {
  try {
    await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/register`, formData);
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/otpGeneration`, {
      email: formData.email,
    });
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function setUpProfileService(formData) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/setUpProfile`, formData);
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function saveInterest(interests) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/saveInterest`, {
      interest: interests,
    });
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function otpGeneration(email) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/otpGeneration`, {
      email: email,
    });
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function otpVerification(formData, email) {
  try {
    const { data, headers } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/otpVerification`, {
      email: email,
      otp: formData.otp,
    });
    if (data) {
      await window.localStorage.removeItem('token');
      await window.localStorage.setItem('token', headers['x-auth-token']);
      toast.success(data.message);
      if (!data.message) toast.success(data);
      return { data };
    }
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function resetPassword(formData) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/resetPassword`, formData);
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function authentication(formData) {
  try {
    const { data, headers } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/login`, {
      email: formData.email,
      password: formData.password,
    });
    await localStorage.removeItem('token');
    await localStorage.setItem('token', headers['x-auth-token']);

    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function contactUs(formData) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/contactUs`, formData);

    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function feedback(formData) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/feedback`, formData);
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function editProfileService(formData) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/editProfile`, formData);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function adminAuthentication(formData) {
  try {
    const { data, headers } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/admin/login`, {
      email: formData.email,
      password: formData.password,
    });
    await localStorage.removeItem('tokenAdmin');
    await localStorage.setItem('tokenAdmin', headers['x-auth-token-admin']);

    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function adminOtpGeneration(email) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/admin/forgot-password`, {
      email: email,
    });
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function adminOtpVerification(formData, email) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/admin/otp-verification`, {
      email: email,
      otp: formData.otp,
    });
    if (data) {
      toast.success(data.message);
      if (!data.message) toast.success(data);
      return { data };
    }
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function adminResetPassword(formData) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/admin/reset-password`, formData);
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function createInterest(formData) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/createInterest`, formData);
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}
export async function changePassword(formData) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/changePassword`, formData);
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}
export async function becomeHost(formData) {
  try {
    const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/becomeAHost`, formData);

    toast.success(data.message);
    if (!data.message) toast.success(data);

    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function googleAuthentication(token) {
  try {
    const { data, headers } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/auth/google`, {
      tokenId: token,
    });
    await window.localStorage.removeItem('token');
    await window.localStorage.setItem('token', headers['x-auth-token']);
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function linkedinAuthentication(token, uri) {
  try {
    const { data, headers } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/auth/linkedin`, {
      code: token,
      uri: uri,
    });
    await localStorage.removeItem('token');
    await localStorage.setItem('token', headers['x-auth-token']);
    toast.success(data.message);
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}
