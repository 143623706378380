import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import ShowMoreText from 'react-show-more-text';
class About extends Component {
  render() {
    if (!this.props.user) return null;
    return (
      <div className="about-section">
        <div className="about-me">
          <h3>About Me</h3>
          <p style={{ color: 'red!important' }}>
            <ShowMoreText
              /* Default options */
              lines={3}
              more="Read more"
              less="Read less"
              onClick={this.executeOnClick}
              expanded={false}
            >
              {this.props.user.description}
            </ShowMoreText>
          </p>
        </div>
      </div>
    );
  }
}

export default About;
