import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import WOW from 'wowjs';
import { CircleArrow as ScrollUpButton } from 'react-scroll-up-button';
import { toast } from 'react-toastify';
import moment from 'moment';
import parse from 'html-react-parser';
import $ from 'jquery';
import AllPostQuestionList from './../../commonComponents/allPostQuestionList';
import AdminCollectionDetailSection from './adminCollectionDetail';
import Collectionback from '../../collectionsComponent/collectionsSection/collectionback';
import AdminHeader from './adminheader';
import httpAdminService from '../../services/httpAdminService';

class AdminCollectionDetailPage extends Component {
  pageData = [];
  tabType = 'post';
  totalData = '';
  state = {
    listData: '',
    page: Number(1),
    user: '',
    collectionDetail: '',
  };

  async componentDidMount() {
    this.collectionDetail();
    this.postAPI('post', this.state.page);

    //window.addEventListener("scroll", this.loadMore);

    new WOW.WOW({
      live: false,
    }).init();
  }

  componentWillUnmount() {
    //window.removeEventListener("scroll", this.loadMore);
  }

  loader(type) {
    var shwload = document.getElementById('page_loader');
    shwload.style.display = type;
  }

  async postAPI(name, page) {
    this.loader('block');
    try {
      const { data } = await httpAdminService.get(
        `${process.env.REACT_APP_APIENDPOINT}/admin/otherpost/${null}/${name}?page=${page}&collectionId=${
          this.props.match.params.id
        }`,
      );

      if (data) {
        data.postList[0].data.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('MMM DD, YYYY h:mm A');
          item.description = parse(item.description);
        });
        const listData = data.postList[0].data;
        this.totalData = data.postList[0].metadata[0] ? data.postList[0].metadata[0].total : '';
        this.pageData = [...this.pageData, ...listData];
        this.setState({ listData: this.pageData });

        this.loader('none');

        return;
      }
    } catch (error) {
      this.loader('none');
      toast.error(error.message);
      return;
    }
  }

  loadMore = () => {
    if ($(window).scrollTop() + $(window).height() === $(document).height()) {
      if (this.pageData.length !== this.totalData) {
        var page_count = this.state.page + Number(1);
        this.setState({
          page: page_count,
        });
        this.postAPI(this.tabType, this.state.page);
      }
    }
  };

  async collectionDetail() {
    try {
      const { data } = await httpAdminService.get(
        `${process.env.REACT_APP_APIENDPOINT}/admin/collection-detail/${this.props.match.params.id}`,
      );
      if (data) {
        data.collectionDetail[0].creationDate = moment(data.collectionDetail[0].creationDate).format(
          'MMM DD, YYYY h:mm A',
        );

        const collectionDetail = data.collectionDetail;
        this.setState({ collectionDetail: collectionDetail });
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  render() {
    return (
      <div className="userhomepage">
        <div className="container-fluid menu-parts wow slideInDown">
          <AdminHeader />
        </div>
        <div className="container-fluid user-activity">
          <div className="container">
            <div className="row">
              <div className="col-12 wow fadeInUp">
                <div className="row">
                  <div className="col-8 offset-2 wow fadeInUp">
                    <Collectionback />
                    <AdminCollectionDetailSection collectionDetail={this.state.collectionDetail} />
                    <AllPostQuestionList listData={this.state.listData} user={this.state.user} {...this.props} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ScrollUpButton />
      </div>
    );
  }
}

export default AdminCollectionDetailPage;
