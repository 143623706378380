import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-tabs/style/react-tabs.css';
import ReactItemSlider from 'react-items-slider';
import httpService from './../../services/httpService';
import AllPostQuestionList from '../../commonComponents/allPostQuestionList';
import { toast } from 'react-toastify';
import WOW from 'wowjs';
import moment from 'moment';
import parse from 'html-react-parser';
import $ from 'jquery';
class SpaceSection extends Component {
  totalData = '';
  state = {
    interestList: [
      { _id: '1h212h', name: 'ahjsgsha' },
      { _id: '1h212h', name: 'ahjsgsha' },
      { _id: '1h212h', name: 'ahjsgsha' },
    ],
    listData: [],
    selectedTab: '',
    selectedTabId: '',
    page: Number(1),
    activeIndex: 0,
  };
  pageData = [];
  componentDidMount = async () => {
    this.hitmyinterest();
    new WOW.WOW({
      live: false,
    }).init();
    window.addEventListener('scroll', this.loadMore);
    document.body.addEventListener('touchmove', this.loadMore);
  };
  componentDidUpdate = (prevProps) => {
    if (this.props.numberOfInterest != prevProps.numberOfInterest) {
      this.hitmyinterest();
    }
  };
  hitmyinterest = async (id = 0) => {
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/myInterest`);
      this.setState({ interestList: data.interests });
      if (data.interests[0]) {
        this.setState({ selectedTab: data.interests[0].name });
        this.setState({ selectedTabId: data.interests[0]._id });
        if (!id) this.postAPI(data.interests[0]._id, 1);
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.loadMore);
    document.body.removeEventListener('touchmove', this.loadMore);
  }
  hitAPI(name, id, index) {
    this.setState({
      selectedTab: name,
      selectedTabId: id,
    });
    this.setState({ activeIndex: index });
    this.pageData = [];
    this.postAPI(id, 1);
  }

  hitRemoveAPI = (id) => {
    if (this.state.interestList.length == 1) {
      toast.error('You can not remove all interest');
      return;
    }
    this.removeInterestApi(id);
    this.props.onRemoveSpace();
  };
  removeInterestApi = async (id) => {
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/deleteInterestInUser/${id}`);
      if (data) {
        this.hitmyinterest(1);
        toast.success('Interest Deleted Successfully');

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  };

  loader(type) {
    var shwload = document.getElementById('page_loader');
    shwload.style.display = type;
  }
  // loader(type){
  //     var shwload = document.getElementById( "page_loader" );
  //     shwload.style.display = type;
  // }
  postAPI = async (id, page) => {
    //this.loader("block");
    try {
      const { data } = await httpService.get(
        `${process.env.REACT_APP_APIENDPOINT}/post/post?interestId=${id}&page=${page}`,
      );
      if (data) {
        data.postList[0].data.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('MMM DD, YYYY h:mm A');
          item.description = parse(item.description);
        });
        const listData = data.postList[0].data;
        this.totalData = data.postList[0].metadata[0] ? data.postList[0].metadata[0].total : '';
        this.pageData = [...this.pageData, ...listData];
        const pageData = this.filterlist(this.pageData);
        this.setState({ listData: pageData });
        //this.loader("none");
        return;
      }
    } catch (error) {
      this.loader('none');
      toast.error(error.message);
      return;
    }
  };
  loadMore = () => {
    if (
      $(window).scrollTop() + $(window).height() == $(document).height() ||
      $(window).scrollTop() + window.innerHeight >= document.body.scrollHeight
    ) {
      if (this.pageData.length != this.totalData) {
        var page_count = this.state.page + Number(1);
        this.setState({
          page: page_count,
        });
        this.postAPI(this.state.selectedTabId, this.state.page);
      }
    }
  };
  onBlock = () => {
    this.setState({ page: Number(1) });
    this.pageData = [];
    this.componentDidMount();
  };

  filterlist = (data) => {
    let listData = data;
    if (this.props.user) {
      for (var index = 0; index < listData.length; index++) {
        if (
          this.props.user.usersBlocked?.includes(listData[index].createdBy._id) ||
          listData[index].createdBy.usersBlocked?.includes(this.props.user._id)
        ) {
          listData.splice(index, 1);
          index--;
        }
      }
    }
    return listData;
    //this.setState({ listData: listData });
  };

  handleLike = (likeType, index, isChange) => {
    const listData = [...this.state.listData];
    if (isChange == 1) {
      listData[index].my_like[0].type = 1;
      if (listData[index].likeList.length) {
        var index2 = listData[index].likeList.findIndex((p) => p.createdBy._id === this.props.user._id);
        if (index2 > -1) {
          listData[index].likeList.splice(index2, 1);
        }
      }
    } else {
      if (listData[index].my_like.length) {
        listData[index].my_like[0].type = likeType;
      } else {
        listData[index].my_like.push({ type: likeType });
      }
      if (listData[index].likeList.length) {
        const found2 = listData[index].likeList.some((el) => el.createdBy._id === this.props.user._id);
        if (!found2) {
          listData[index].likeList.push({
            createdBy: { name: this.props.user.name, _id: this.props.user._id },
          });
        }
      } else {
        listData[index].likeList.push({
          createdBy: { name: this.props.user.name, _id: this.props.user._id },
        });
      }
    }
    this.setState({
      listData,
    });
    // this.setState({language: langValue});
  };
  renderSliderButtons = () => {
    return this.state.interestList.length
      ? this.state.interestList.map((interest, index) => (
          <button
            key={index}
            className={index === this.state.activeIndex ? 'active' : 'inactive'}
            onClick={() => this.hitAPI(interest.name, interest._id, index)}
          >
            {interest.name} <i className="fa fa-times" onClick={() => this.hitRemoveAPI(interest._id)}></i>
          </button>
        ))
      : '';
  };
  render() {
    return (
      <div>
        <div className="space-itemislider">
          {/* <SpacesItemSlider interestList={this.state.interestList} /> */}
          <div className="heading-parts">
            <h3>{this.state.selectedTab} Space</h3>
            <p>This space enables you to find content easily according to the different categories and tags provided</p>
          </div>
          <div className="slider-parts">
            <div>
              {this.props.user ? (
                <ReactItemSlider productsInView="max" arrowSize="small">
                  {this.renderSliderButtons()}
                </ReactItemSlider>
              ) : null}
            </div>
          </div>
        </div>
        {this.state.listData.length ? (
          <AllPostQuestionList
            listData={this.state.listData}
            {...this.props}
            onLikeChange={this.handleLike}
            user={this.props.user}
            onBlock={this.onBlock}
            onEdit={this.onBlock}
          />
        ) : null}
      </div>
    );
  }
}
export default SpaceSection;
