import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import AllPostQuestionList from '../../../../commonComponents/allPostQuestionList';
import { toast } from 'react-toastify';
import moment from 'moment';
import parse from 'html-react-parser';
import $ from 'jquery';
import httpAdminService from '../../../../services/httpAdminService';
class Post extends Component {
  pageData = [];
  tabType = 'post';
  totalData = '';
  state = {
    listData: '',
    page: Number(1),
  };

  async componentDidMount() {
    this.postAPI('post', this.state.page);

    window.addEventListener('scroll', this.loadMore);
    document.body.addEventListener('touchmove', this.loadMore);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.loadMore);
    document.body.removeEventListener('touchmove', this.loadMore);
  }

  loader(type) {
    var shwload = document.getElementById('page_loader');
    shwload.style.display = type;
  }

  async postAPI(name, page) {
    try {
      const { data } = await httpAdminService.get(
        `${process.env.REACT_APP_APIENDPOINT}/admin/otherpost/${this.props.user._id}/${name}?page=${page}&type=my`,
      );

      if (data) {
        data.postList[0].data.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('MMM DD, YYYY h:mm A');
          item.description = parse(item.description);
        });
        const listData = data.postList[0].data;
        this.totalData = data.postList[0].metadata[0] ? data.postList[0].metadata[0].total : '';
        this.pageData = [...this.pageData, ...listData];
        this.setState({ listData: this.pageData });
        return;
      }
    } catch (error) {
      this.loader('none');
      toast.error(error.message);
      return;
    }
  }

  constructor(props) {
    super(props);
  }

  loadMore = () => {
    if (
      $(window).scrollTop() + $(window).height() == $(document).height() ||
      $(window).scrollTop() + window.innerHeight >= document.body.scrollHeight
    ) {
      if (this.pageData.length != this.totalData) {
        var page_count = this.state.page + Number(1);
        this.setState({
          page: page_count,
        });
        this.postAPI(this.tabType, this.state.page);
      }
    }
  };

  handleLike = (likeType, index, isChange) => {
    const listData = [...this.state.listData];

    if (isChange == 1) {
      listData[index].my_like[0].type = 1;
      if (listData[index].likeList.length) {
        var index2 = listData[index].likeList.findIndex((p) => p.createdBy._id === this.props.user._id);
        if (index2 > -1) {
          listData[index].likeList.splice(index2, 1);
        }
      }
    } else {
      if (listData[index].my_like.length) {
        listData[index].my_like[0].type = likeType;
      } else {
        listData[index].my_like.push({ type: likeType });
      }

      if (listData[index].likeList.length) {
        const found2 = listData[index].likeList.some((el) => el.createdBy._id === this.props.user._id);
        if (!found2) {
          listData[index].likeList.push({
            createdBy: { name: this.props.user.name, _id: this.props.user._id },
          });
        }
      } else {
        listData[index].likeList.push({
          createdBy: { name: this.props.user.name, _id: this.props.user._id },
        });
      }
    }
    this.setState({
      listData,
    });
    // this.setState({language: langValue});
  };

  render() {
    return (
      <div className="user-post-section mt-3">
        <AllPostQuestionList listData={this.state.listData} {...this.props} onLikeChange={this.handleLike} />
      </div>
    );
  }
}

export default Post;
