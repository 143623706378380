import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import WOW from 'wowjs';
import 'react-tabs/style/react-tabs.css';
import ContactShorting from './contactShorting';
import Contactlisttable from './contactlisttable';
import { toast } from 'react-toastify';
import httpAdminService from '../../../services/httpAdminService';
import FeedbackShorting from './feedbackShorting';
import Feedbacklisttable from './feedbacklisttable';
import moment from 'moment';

class ContactTabs extends Component {
  state = {
    contactData: [],
    feedbackData: [],
  };
  async componentDidMount() {
    try {
      const { data } = await httpAdminService.get(`${process.env.REACT_APP_APIENDPOINT}/admin/contactuslist`);
      if (data) {
        data.contactusdata.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('LLL');
        });
        this.setState({ contactData: data.contactusdata });
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }

    try {
      const { data } = await httpAdminService.get(`${process.env.REACT_APP_APIENDPOINT}/admin/feedbacklist`);
      if (data) {
        data.feedbackdata.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('LLL');
        });
        this.setState({ feedbackData: data.feedbackdata });
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
    new WOW.WOW({
      live: false,
    }).init();
  }

  contactusApi = async (sort = '') => {
    // console.log("sorting,", sort);
    try {
      const { data } = await httpAdminService.get(
        `${process.env.REACT_APP_APIENDPOINT}/admin/contactuslist?sort=${sort}`,
      );
      // console.log("sorting2222", data);
      if (data) {
        data.contactusdata.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('LLL');
        });
        this.setState({ contactData: data.contactusdata });
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  };

  feedbackApi = async (sort = '') => {
    console.log('sorting,', sort);
    try {
      const { data } = await httpAdminService.get(
        `${process.env.REACT_APP_APIENDPOINT}/admin/feedbacklist?sort=${sort}`,
      );
      console.log('sorting2222', data);
      if (data) {
        data.feedbackdata.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('LLL');
        });
        this.setState({ feedbackData: data.feedbackdata });
        console.log(data);
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  };

  render() {
    return (
      <div className="tabs-profile-section mt-3">
        <div className="tabs-section eventstabs">
          <Tabs>
            <TabList>
              <Tab>Contact Query</Tab>
              <Tab>Feedback Query </Tab>
            </TabList>
            <TabPanel>
              <ContactShorting contactData={this.state.contactData} {...this.props} onSortChange={this.contactusApi} />
              {this.state.contactData.length ? (
                <Contactlisttable contactData={this.state.contactData} {...this.props} />
              ) : null}
            </TabPanel>
            <TabPanel>
              <FeedbackShorting
                feedbackData={this.state.feedbackData}
                {...this.props}
                onSortChange={this.feedbackApi}
              />
              {this.state.feedbackData.length ? <Feedbacklisttable feedbackData={this.state.feedbackData} /> : null}
            </TabPanel>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default ContactTabs;
