import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import Header from './../commonComponents/header';
import WOW from 'wowjs';
import Footer from './../commonComponents/footer';
import Form from '../macroComponents/form/form';
import Joi from 'joi-browser';
import * as registerServices from '../services/registerServices';

class ResetPasswordPage extends Form {
  state = {
    formData: {
      password: '',
    },
    errors: {},
  };

  schema = {
    password: Joi.string().required().min(8),
  };

  doSubmit = async () => {
    const { data } = await registerServices.resetPassword(this.state.formData);
    if (data) {
      this.props.history.replace(`/login`);
    }
  };

  async componentDidMount() {
    if (!this.props.location.email) return await this.props.history.push(`/login`);
    new WOW.WOW({
      live: false,
    }).init();
  }
  render() {
    return (
      <div className="loginpage">
        <div className="container-fluid login-header">
          <Header {...this.props} />
        </div>
        <div className="login-section ">
          <div className="container">
            <div className="top-section text-center">
              <Link to="#">
                <img src="/assets/images/icons/login.png" alt="Login logo" />{' '}
              </Link>
              <h3>Password Reset!</h3>
              <p>
                We Successfuly reset your password, Please enter a new <br></br> password to continue{' '}
              </p>
            </div>
            <div className="form-section wow bounceIn">
              <form>
                {this.renderInput('password', 'Password', 'Enter Strong Password', 'password')}
                {this.renderButton('Reset Password', this.handleSubmit)}
              </form>
            </div>
            <div className="login-footer text-center mt-5">
              <p>
                New to B-Thrive? <Link to="/registration">Create An Account</Link>
              </p>
              <p>
                Can't Login? <Link to="/forgot-password">Reset Password</Link>
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default ResetPasswordPage;
