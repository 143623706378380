import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Footer from '../commonComponents/footer';
import Header from '../commonComponents/header';
class Guideline extends Component {
  state = {};
  render() {
    return (
      <>
        <Header {...this.props} />
        <div className="guide mb-5">
          <div className="guidebody">
            <div className="rules">
              <center>
                <h1>
                  <b>B-Thrive Guidelines</b>
                </h1>
              </center>
              <center>
                <h3>
                  <b>Overall Members</b>
                </h3>
              </center>
              <p>
                We’re all grown here, and we know everyone knows how to act, but we have to say it anyway…”Behave
                yourself”.
                <br />
                B-Thrive is an edu-social platform promoting the positivity and beauty of those belonging to the African
                diaspora. We expect a high level of respect and integrity from our members.
                <br />
              </p>

              <p className="rulesss">
                Please keep the following guidelines in mind as you engage on the B-Thrive platform.
              </p>
              <ul>
                <li>
                  <ol>
                    {/* <p className="mt-0"> */}
                    <li>
                      Refrain from any expression of discrimination, hate speech, and verbal attacks. Such acts will be
                      investigated, with the possibility of account suspension.{' '}
                    </li>
                    <li>
                      We fully respect the freedom of speech, but that freedom ends when a member or members are
                      attacked by other platform users.
                    </li>
                    <li>All members have the right to block members they wish not to engage with.</li>
                    <li>
                      If you are blocked by a member, please respect their decision and refrain from any further
                      contact.
                    </li>
                    <li>
                      Regarding commenting and during the attendance of live online events, please refrain from
                      insulting, bullying, and/or harassment. Such acts will be investigated, with the possibility of
                      account suspension.
                    </li>
                    <li>We condemn any act of violence.</li>
                    <li>
                      Refrain from spamming other members. All complaints of spamming will be investigated, with the
                      possibility of account suspension.
                    </li>
                    <li>
                      Refrain from creating/registering fake profiles. Such acts will be investigated, with the
                      possibility of all accounts being suspended and removed.
                    </li>
                    <li>
                      Copyright and Trademark: All rights reserved. All content (texts, trademarks, illustrations,
                      photos, graphics, files, designs, arrangements etc.) on the B-Thrive website are protected by
                      copyright and other protective laws. The contents of this website are to be used only in
                      accordance with Internet regulations.
                    </li>
                    <li>
                      Internet Regulations: Without the explicit written permission from B-Thrive, it is prohibited to
                      integrate in whole, or in part, any of the contents published on this website into other programs
                      or other web sites, or to use them by any other means.
                    </li>
                    <li>
                      Liability: B-Thrive has carefully compiled the contents of this website in accordance with our
                      current state of knowledge. Access to and use of this website, as well as web sites related or
                      connected to this by links, are at the user's own risk and responsibility.
                    </li>
                    <br className="mt-2" />
                    Damage and warranty claims arising from missing or incorrect data are excluded. B-Thrive bears no
                    responsibility or liability for damage of any kind, also for indirect or consequential damages
                    resulting from access to or use of this website or websites related or connected to this by links.
                    <li>
                      Links To Other Websites: The B-Thrive website may contain links (cross references) to websites
                      that are run by third parties. B-Thrive takes no responsibility for the content of these other
                      websites.
                    </li>
                  </ol>
                </li>
              </ul>

              <p className="rulesss">Reasons why I could be blocked:</p>
              <ul>
                <li>
                  <ol>
                    <li>The use of aggressive/provocative tone or language.</li>
                    <li>Disrespecting other members</li>
                    <li>All members have the right to block members they wish not to engage with.</li>
                    <li>
                      If you are blocked by a member, please respect their decision and refrain from any further
                      contact.
                    </li>
                  </ol>
                </li>
              </ul>

              <p>Please be:</p>
              <p>Authentic, Open, Accepting, Compassionate, Mindful, Supportive, Trustworthy, and Empathic</p>
              <p>Definitions</p>
              <h6>
                <u>Discrimination:</u>
              </h6>
              <p>
                The unjust or prejudicial treatment of different categories of people, especially on the grounds of
                race, ethnicity, age, religion, sexual orientation, or gender.
              </p>
              <p>
                As a company, B-Thrive accepts people regardless of their sexual orientation, religious belief systems,
                or heritage. No matter where you are on your journey, you are welcome on the B-Thrive platform.
              </p>
              <h6>
                <u>Harassment:</u>
              </h6>
              <p>
                The act of troubling persistently or incessantly. It implies systematic persecution by besieging with
                repeated annoyances, threats, and/or demands.
              </p>
              <h6>
                <u>Bullying:</u>
              </h6>
              <p>
                Bullying is an ongoing and deliberate misuse of power in relationships through repeated verbal,
                physical, and/or social behavior that intends to cause physical, social, and/or psychological harm. It
                can involve an individual or a group misusing their power, or perceived power, over one or more persons
                who feel unable to stop it from happening. (source: National center of bullying)
              </p>
              <center>
                <h3>
                  <b>Guidelines For Bloggers</b>
                </h3>
              </center>
              <p>
                B-Thrive depends on our members, especially our bloggers, to help provide a safe space to connect,
                socialize, and learn about our heritage as people of the African diaspora.
                <br />
                Our bloggers are a key component in our effort to uplift our community and culture through education and
                the creation of thought-provoking content.
                <br />
              </p>
              <p className="rulesss">Use your creativity and help our community thrive by:</p>
              <ol>
                <li>
                  Contributing and creating authentic, intriguing, and informative content focused on topics related to
                  the African diaspora (ie. info about your origin or country; your experiences; your traditions;
                  related visuals, etc)
                </li>
                <li>
                  Being positive and motivational. B-Thrive prides itself on being a supportive community, designed to
                  inspire one another and share our knowledge and experiences, while motivating each other to embrace
                  our collective heritage.Uplift our community through the educational content you provide.
                </li>
                <li>
                  Frequently sharing your knowledge. The more you contribute, the more recognition and engagement you
                  will receive from our community.
                </li>
              </ol>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Guideline;
