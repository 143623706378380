import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import WOW from 'wowjs';
import Form from '../../macroComponents/form/form';
import Joi from 'joi-browser';
import * as registerService from './../../services/registerServices';
class AdminloginPage extends Form {
  state = {
    formData: {
      email: '',
      password: '',
    },
    errors: {},
  };

  schema = {
    email: Joi.string()
      .email({ tlds: { allow: ['com', 'net'] } })
      .required(),
    password: Joi.string().required().min(8),
  };

  doSubmit = async () => {
    const { data, error } = await registerService.adminAuthentication(this.state.formData);
    if (data) {
      const stake = data.profileType;
      return await this.props.history.push('/admin/dashboard');
    }
    if (error) return;
  };
  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();
  }
  render() {
    return (
      <div className="loginpage">
        <div className="login-section ">
          <div className="container">
            <div className="top-section text-center">
              <Link to="#">
                <img src="../assets/images/icons/login.png" alt="Login logo" />{' '}
              </Link>
              <h3>Welcome to B-Thrive Admin</h3>
              <p>Login to Continue</p>
            </div>
            <div className="form-section wow bounceIn">
              <form>
                {this.renderInput('email', 'Email', 'Enter Email')}
                {this.renderInput('password', 'Password', 'Enter Password', 'password')}
                {this.renderButton('Sign In', this.handleSubmit)}
              </form>
            </div>
            <div className="login-footer text-center mt-4">
              <p>
                Can't Login? <Link to="/admin/forgot-password">Reset Password</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminloginPage;
