import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Header from './../commonComponents/header';
import BannerSection from './aboutPageSection/bannerSection';
import WOW from 'wowjs';
import Footer from './../commonComponents/footer';
import VisionMission from './aboutPageSection/visionMission';
import HowItWork from './aboutPageSection/howItWork';
class AboutusPage extends Component {
  state = {};
  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();
  }
  render() {
    return (
      <div className="AboutusPage">
        <div className="container-fluid about-main-section">
          <Header {...this.props} />
          <BannerSection />
        </div>
        <VisionMission />
        <HowItWork />
        <Footer />
      </div>
    );
  }
}

export default AboutusPage;
