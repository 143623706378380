import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { Button, Modal } from 'react-bootstrap';
import 'react-tabs/style/react-tabs.css';
import 'react-calendar/dist/Calendar.css';
import * as postAdminService from '../../../services/postAdminService';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { toast } from 'react-toastify';
import moment from 'moment';
import parse from 'html-react-parser';
import $ from 'jquery';
import httpAdminService from '../../../services/httpAdminService';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import { RplFetcher } from '../../../lib/helpers';
class Learnbackpost extends Component {
  fileArray = [];
  sendFile = [];
  modalType = 'post';
  pageData = [];
  tabType = 'post';
  totalData = '';
  state = {
    selectedTags: [],
    interestList: [],
    old_arr: [],
    description: '',
    link: '',
    web_link: '',
    file: [],
    listData: '',
    page: Number(1),
  };

  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide });
  }

  async componentDidMount() {
    try {
      const { data } = await httpAdminService.get(`${process.env.REACT_APP_APIENDPOINT}/getInterest`);
      this.setState({ interestList: data.interests });
      this.setState({ old_arr: data.interests });
    } catch (error) {
      toast.error(error.message);
      return;
    }

    this.postAPI('post', this.state.page);

    //window.addEventListener("scroll", this.loadMore);
  }

  componentWillUnmount() {
    //window.removeEventListener("scroll", this.loadMore);
  }

  loader(type) {
    var shwload = document.getElementById('page_loader');
    shwload.style.display = type;
  }

  async postAPI(name, page) {
    this.loader('block');
    try {
      const { data } = await httpAdminService.get(
        `${process.env.REACT_APP_APIENDPOINT}/admin/learnWithBthrivepost/${name}?page=${page}`,
      );

      if (data) {
        data.postList[0].data.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('MMM DD, YYYY h:mm A');
          item.description = parse(item.description);
        });
        const listData = data.postList[0].data;
        this.totalData = data.postList[0].metadata[0]?.total;
        this.pageData = [...this.pageData, ...listData];
        this.setState({ listData: this.pageData });

        this.loader('none');

        return;
      }
    } catch (error) {
      this.loader('none');
      toast.error(error.message);
      return;
    }
  }

  constructor(props) {
    super(props);
    // this.state = {
    //     file: [null]
    // }
    this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
    // this.uploadMultipleFiles1 = this.uploadMultipleFiles1.bind(this)
    // this.upload = this.upload.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this);
    // this.tags = this.tags.bind(this);
  }

  loadMore = () => {
    if (
      $(window).scrollTop() + $(window).height() == $(document).height() ||
      $(window).scrollTop() + window.innerHeight >= document.body.scrollHeight
    ) {
      if (this.pageData.length != this.totalData) {
        var page_count = this.state.page + Number(1);
        this.setState({
          page: page_count,
        });
        this.postAPI(this.tabType, this.state.page);
      }
    }
  };

  toggleLinkpart = () => {
    this.setState({ linkpart: !this.state.linkpart });
  };
  toggleWebsitepart = () => {
    this.setState({ websitepart: !this.state.websitepart });
  };
  uploadMultipleFiles(e) {
    for (let i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].size > 20242880) {
        toast.error('File size exceeded. Max size should be 20MB.');
        continue;
      }
      let obj = {
        name: e.target.files[i].name,
        type: e.target.files[i].type,
        fileurl: URL.createObjectURL(e.target.files[i]),
      };
      this.fileArray.push(obj);
      this.sendFile.push(e.target.files[i]);
    }

    this.setState({ file: this.sendFile });
  }
  getExtension(name) {
    if (name) {
      return name.split('.')[name.split('.').length - 1].toLowerCase();
    }
  }

  postModalHandler(type) {
    if (type == '') {
      this.setState({ linkpart: false });
      this.setState({ websitepart: false });
    }
    this.modalType = type;
    this.fileArray = [];
    this.sendFile = [];
    this.setState({ web_link: '' });
    this.setState({ link: '' });
    this.setState({ description: '' });
    this.setState({ selectedTags: [] });
    this.setState({ file: [] });
    this.setState({ interestList: [] });
    this.setState({ showHide: !this.state.showHide });
  }

  onRemoveFileSelection(index) {
    this.fileArray.splice(index, 1);
    this.sendFile.splice(index, 1);
    this.setState({ file: this.sendFile });
  }

  handleInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({
      [name]: value,
    });
  }

  handleKeyPress(e) {
    let originalArr = JSON.parse(JSON.stringify(this.state.old_arr));
    if (e.target.value) {
      let interestList = this.state.old_arr.filter((item, index) => {
        if (item['name'].toLowerCase().includes(e.target.value.toLowerCase())) {
          return item;
        }
      });
      this.setState({ interestList: interestList });
    } else {
      this.setState({ interestList: [...originalArr] });
    }
  }

  tags(name) {
    if (this.state.selectedTags.indexOf(name) == -1) {
      this.state.selectedTags.push(name);
    }
  }

  removeTags(name) {
    var array = [...this.state.selectedTags]; // make a separate copy of the array
    var index = array.indexOf(name);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ selectedTags: array });
    }
  }

  isUrl(s) {
    var shortLink = /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/;
    var fullLink = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return fullLink.test(s) || shortLink.test(s);
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.state.link) {
      let checkUrl = this.isUrl(this.state.link);
      if (!checkUrl) {
        toast.error('Link is invalid');
        return;
      }
    }
    if (this.state.web_link) {
      let checkUrlWebsite = this.isUrl(this.state.web_link);
      if (!checkUrlWebsite) {
        toast.error('Website Link is invalid');
        return;
      }
    }

    const files = this.state.file;
    if (this.state.description == '' && this.state.link == '' && files.length == 0) {
      toast.error('Please enter description');
      return;
    }
    if (files.length == 0 && this.state.description == '') {
      toast.error('Please enter description or add image');
      return;
    }
    this.loader('block');
    const interests = this.state.selectedTags;
    const formData = new FormData();
    formData.append('type', this.modalType);
    formData.append('description', this.state.description);
    formData.append('link', this.state.link);
    formData.append('web_link', this.state.web_link);
    for (let i = 0; i < interests.length; i++) {
      formData.append('interest', interests[i]);
    }
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
    const { data, error } = await postAdminService.createLearnWithBthrivePost(formData);
    if (data) window.location.reload();
    if (error) {
      this.loader('none');
      return;
    } else {
      this.setState({ showHide: !this.state.showHide });
      this.loader('none');

      return;
    }
  };
  onEditorStateChange = (e) => {
    this.setState({ description: e.target.value });
  };

  handleLike = (likeType, index, isChange) => {
    const listData = [...this.state.listData];

    if (isChange == 1) {
      listData[index].my_like[0].type = 1;
      if (listData[index].likeList.length) {
        var index2 = listData[index].likeList.findIndex((p) => p.createdBy._id === this.props.user._id);
        if (index2 > -1) {
          listData[index].likeList.splice(index2, 1);
        }
      }
    } else {
      if (listData[index].my_like.length) {
        listData[index].my_like[0].type = likeType;
      } else {
        listData[index].my_like.push({ type: likeType });
      }

      if (listData[index].likeList.length) {
        const found2 = listData[index].likeList.some((el) => el.createdBy._id === this.props.user._id);
        if (!found2) {
          listData[index].likeList.push({
            createdBy: { name: this.props.user.name, _id: this.props.user._id },
          });
        }
      } else {
        listData[index].likeList.push({
          createdBy: { name: this.props.user.name, _id: this.props.user._id },
        });
      }
    }
    this.setState({
      listData,
    });
    // this.setState({language: langValue});
  };
  handleChanges = (e) => {
    this.props.onSort(e.target.value);
  };

  render() {
    return (
      <div className="shorting-dashboard mt-5">
        <div className="main-section">
          <div className="left-part">
            <p>You have a total of {this.props.data.length} Post </p>
          </div>
          <div className="right-part">
            <div className="form-group">
              <label>Sort By :</label>
              <select className="fomr-control" onChange={this.handleChanges}>
                <option value="All_Time">All Time</option>
                <option value="last_minute">Last Minute</option>
                <option value="last_hour">Last Hours</option>
                <option value="last_week">Last Week</option>
                <option value="last_month">Last Month</option>
                <option value="last_quarter">Last Quarter</option>
                <option value="Last_Year">Last Year</option>
              </select>
            </div>
            <Button className="add-events" onClick={() => this.handleModalShowHide()}>
              Add Post
            </Button>
          </div>
          <Modal show={this.state.showHide} className="messge-post-modal ">
            <Modal.Body>
              <div className="postsection">
                <Link
                  to={{
                    pathName: '#',
                    state: { user: this.props.user },
                  }}
                  className="closeicon"
                  onClick={() => this.postModalHandler('')}
                >
                  <i className="fa fa-close"></i>
                </Link>
                <div className="heading-part">
                  <h3>{this.modalType == 'post' ? 'Make a Post' : 'Ask a question'}</h3>
                </div>
                <div className="write-messge-post">
                  <div className="textarea-part">
                    {this.modalType == 'post' ? (
                      <textarea
                        rows="3"
                        id="description"
                        name="description"
                        className="demo-editor"
                        onChange={this.onEditorStateChange}
                        placeholder="Write your Post"
                      ></textarea>
                    ) : (
                      <textarea
                        rows="3"
                        id="description"
                        name="description"
                        className="demo-editor"
                        onChange={this.onEditorStateChange}
                        placeholder="Ask your Question"
                      ></textarea>
                    )}
                  </div>
                  <div className="form-group preview">
                    <div className="row" style={{ maxHeight: '200px', width: '100%' }}>
                      {this.fileArray.map((item, index) => {
                        return (
                          <div className="col-md-3">
                            <i className="fa fa-times close-icon" onClick={() => this.onRemoveFileSelection(index)}></i>
                            {this.getExtension(item.name) == 'png' ||
                            this.getExtension(item.name) == 'jpg' ||
                            this.getExtension(item.name) == 'jpeg' ? (
                              <img src={item.fileurl} alt="..." height="120" width="120" />
                            ) : (
                              <video height="120" width="120" controls>
                                <source src={item.fileurl} type="video/mp4" />
                              </video>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="tags-part">
                    <ul>
                      {this.state.selectedTags.map((item, index) => {
                        return (
                          <li key={index}>
                            {item}
                            <Link
                              to={{
                                pathName: '#',
                                state: { user: this.props.user },
                              }}
                              onClick={() => this.removeTags(item)}
                            >
                              <i className="fa fa-times-circle-o"></i>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  {this.state.linkpart && this.state.link ? (
                    <div>
                      <LinkPreview
                        fetcher={RplFetcher}
                        url={this.state.link}
                        width="580px"
                        height="250px"
                        imageHeight="320px"
                        descriptionLength="80"
                        className="link-preview ml-5"
                      />
                    </div>
                  ) : null}
                  <div className={this.state.linkpart ? 'link-part active' : 'link-part'}>
                    <div className="form-group">
                      <input
                        type="text"
                        name="link"
                        onChange={this.handleInputChange}
                        className="form-control"
                        placeholder="Enter Your Link"
                      />
                    </div>
                  </div>
                  {this.state.websitepart && this.state.web_link ? (
                    <div>
                      <LinkPreview
                        fetcher={RplFetcher}
                        url={this.state.web_link}
                        width="580px"
                        height="250px"
                        imageHeight="320px"
                        descriptionLength="80"
                        className="link-preview ml-5 mt-3"
                      />
                    </div>
                  ) : null}
                  <div className={this.state.websitepart ? 'link-part active' : 'link-part'}>
                    <div className="form-group">
                      <input
                        type="text"
                        name="web_link"
                        onChange={this.handleInputChange}
                        className="form-control"
                        placeholder="Enter Your Website Link"
                      />
                    </div>
                  </div>
                  <div className="post-footer-part">
                    <div className="left-part">
                      <ul>
                        <li>
                          <div className="upload-image">
                            <label>
                              <i className="fa fa-picture-o"></i>
                              <input
                                type="file"
                                className="form-control"
                                accept=".jpeg,.jpg,.png"
                                onChange={this.uploadMultipleFiles}
                                multiple
                              />{' '}
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="upload-image">
                            <label>
                              <i className="fa fa-play-circle"></i>
                              <input
                                type="file"
                                className="form-control"
                                accept=".mp4"
                                onChange={this.uploadMultipleFiles}
                              />{' '}
                            </label>
                          </div>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathName: '#',
                              state: { user: this.props.user },
                            }}
                            onClick={this.toggleLinkpart}
                          >
                            <i className="fa fa-link"></i>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={{
                              pathName: '#',
                              state: { user: this.props.user },
                            }}
                            onClick={this.toggleWebsitepart}
                          >
                            <i className="fa fa-globe"></i>
                          </Link>
                        </li>
                      </ul>
                      <span className="imagesizedefine">Max size of video should be 20MB</span>
                    </div>
                    <div className="right-part">
                      <div className="button-part">
                        <button className="btn btn-primary" onClick={this.handleSubmit} type="button ">
                          {this.modalType == 'post' ? 'Post' : 'Ask'}
                        </button>
                      </div>
                      <div className="search-part">
                        <input
                          className="form-control mr-sm-2"
                          onChange={(e) => this.handleKeyPress(e)}
                          type="search "
                          placeholder="Add Tags"
                          aria-label="Search"
                        />
                        <div className="tags-search">
                          <ul>
                            {this.state.interestList.map((user, index) => (
                              <li onClick={() => this.tags(user.name)}>
                                <Link
                                  to={{
                                    pathName: '#',
                                    state: { user: this.props.user },
                                  }}
                                >
                                  {user.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}

export default Learnbackpost;
