import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-tabs/style/react-tabs.css';
import 'react-tabs/style/react-tabs.css';
import { Modal } from 'react-bootstrap';
import 'react-tabs/style/react-tabs.css';
import ImageUploader from 'react-images-upload';
import Form from '../../../macroComponents/form/form';
import Joi from 'joi-browser';
import { toast } from 'react-toastify';
import * as registerService from '../../../services/registerServices';
import httpAdminService from '../../../services/httpAdminService';

class InterestSection extends Form {
  state = {
    formData: {
      name: '',
    },
    selectedFile1: null,
    errors: {},
  };
  schema = {
    name: Joi.string().min(3).max(20).required(),
  };
  doSubmit = async () => {
    if (!this.state.selectedFile1) {
      toast.error('Please select Profile Picture');
      return;
    }
    const formData = new FormData();
    {
      let data = { ...this.state.formData };
      for (const item in data) {
        if (data[item] === '') {
          data[item] = 'N/A';
        }
        formData.append(item, data[item]);
      }
    }
    formData.append('profilePicture', this.state.selectedFile1, this.state.selectedFile1.name);
    this.eventsModalShowHide();
    const { error } = await registerService.createInterest(formData);
    if (error) return;
    this.props.changeState();
  };
  onFileSelection = async (event) => {
    let file = event[0];
    if (file && file.size < 2880) {
      toast.error('Minimum File size should be 1MB.');
    } else if (file && file.size > 5242880) {
      toast.error('File size exceeded. Max size should be 5MB.');
    } else {
      await this.setState({ selectedFile1: event[0] });
    }
  };
  eventsModalShowHide() {
    this.setState({ eventsshowHide: !this.state.eventsshowHide });
    this.setState({
      formData: {
        name: '',
      },
    });
    this.setState({ selectedFile1: null });
  }
  hitRemoveAPI(id) {
    this.removeInterestApi(id);
  }
  async removeInterestApi(id) {
    try {
      const { data } = await httpAdminService.delete(`${process.env.REACT_APP_APIENDPOINT}/admin/deleteInterest/${id}`);
      if (data) {
        toast.success('Interest deleted successfully');
        this.props.changeState();

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }
  render() {
    return (
      <div>
        <div className="shorting-dashboard mt-3 ">
          <div className="main-section">
            <div className="left-part">
              <p>You have a total of {this.props.interestList && this.props.interestList.length} Interest</p>
            </div>
            <div className="right-part">
              <Link onClick={() => this.eventsModalShowHide()} className="add-events">
                Add Interest
              </Link>
            </div>
            <Modal show={this.state.eventsshowHide} className="addcollection-modal ">
              <Modal.Body>
                <Link className="closeicon" onClick={() => this.eventsModalShowHide()}>
                  <i className="fa fa-close"></i>
                </Link>
                <div className="collection">
                  <h4 className="text-center">Add an Interest </h4>
                  <div className="selectpart">
                    {this.renderInput('name', 'Enter Name', 'Enter Name')}

                    <div className="form-group">
                      <label>Add a Cover Image</label>
                      <div className="collection-image">
                        <ImageUploader
                          withIcon={true}
                          withPreview={true}
                          buttonText={<i className="fa fa-camera"></i>}
                          onChange={this.onFileSelection}
                          imgExtension={['.jpg', '.jpeg', '.gif', '.png']}
                          maxFileSize={5242880}
                          singleImage={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="admin-btn">{this.renderButton('Publish Interest', this.handleSubmit)}</div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
        <div className="cards-view-part wow fadeInUp">
          <div className="main-section">
            <div className="row">
              <div className="col-4">
                <div className="cards-part wow fadeInUp">
                  <p>Top Interest</p>
                  <h3>{this.props.interestList[0] && this.props.interestList[0].name}</h3>
                </div>
              </div>
              <div className="col-4">
                <div className="cards-part wow fadeInUp">
                  <p>Posts in top Interest</p>
                  <h3>{this.props.interestList[0] && this.props.interestList[0].total_posts}</h3>
                </div>
              </div>
              <div className="col-4">
                <div className="cards-part wow fadeInUp">
                  <p>Users with top Interest</p>
                  <h3>{this.props.interestList[0] && this.props.interestList[0].total_users}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="interest-view-part wow fadeInUp">
          <div className="main-section">
            <div className="row">
              {this.props.interestList
                ? this.props.interestList.map((user, index) => (
                    <div className="col-3">
                      <div className="interest-card">
                        <div className="eclipspart ">
                          {' '}
                          <i className="fa fa-times close-icon" onClick={() => this.hitRemoveAPI(user._id)}></i>
                        </div>
                        <div className="detailpart text-center">
                          <img src={user.profilePicture} alt="Intereste" />
                          <h3 className="text-center">{user.name}</h3>
                        </div>
                      </div>
                    </div>
                  ))
                : ''}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InterestSection;
