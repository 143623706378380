import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import Joi from 'joi-browser';
import Form from '../../macroComponents/form/form';
import * as registerService from './../../services/registerServices';
import { toast } from 'react-toastify';
import ImageUploader from 'react-images-upload';
import httpService from './../../services/httpService';
import WOW from 'wowjs';

class EditprofileImage extends Form {
  state = {
    formData: {
      email: '',
      website: '',
      description: '',
      name: '',
    },
    interestList: null,
    selectedFile1: null,
    selectedTags: [],

    errors: {},
  };

  async componentDidMount() {
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/getInterest`);
      this.setState({ interestList: data.interests });
    } catch (error) {
      toast.error(error.message);
      return;
    }
    new WOW.WOW({
      live: false,
    }).init();
  }

  constructor(props) {
    super(props);
    let { formData } = this.state;
    formData.email = this.props.user.email;
    formData.website = this.props.user.website ? this.props.user.website : '';
    formData.description = this.props.user.description ? this.props.user.description : '';
    formData.name = this.props.user.name;
    this.state.selectedTags = this.props.user.interest;
  }

  schema = {
    email: Joi.string()
      .email({ tlds: { allow: ['com', 'net'] } })
      .required(),
    website: Joi.string().allow(''),
    description: Joi.string().max(150).allow(''),
    name: Joi.string().required().min(3).max(30),
  };

  doSubmit = async () => {
    if (this.state.formData.website) {
      let checkUrl = this.isUrl(this.state.formData.website);
      if (!checkUrl) {
        toast.error('Website is invalid');
        return;
      }
    }
    const formData = new FormData();
    const interests = this.state.selectedTags;
    for (const item in this.state.formData) {
      formData.append(item, this.state.formData[item]);
    }
    formData.append('interests', JSON.stringify(this.state.selectedTags));

    if (this.state.selectedFile1) {
      formData.append('profilePicture', this.state.selectedFile1, this.state.selectedFile1.name);
    }
    const { data, error } = await registerService.editProfileService(formData);
    if (data) {
      toast.success(data.message);
      this.props.history.push('user-profile');
    }
    if (error) {
      return;
    }
  };

  removeTags(name, id) {
    const index = this.state.selectedTags.filter((element) => element._id !== id);
    this.setState({ selectedTags: index });
    document.getElementById('interest').value = '';
  }

  addTags(e) {
    const check = JSON.parse(e.target.value);
    const arr = [...this.state.selectedTags];
    if (!this.state.selectedTags.some((person) => person._id === check._id)) {
      arr.push(check);
      this.setState({ selectedTags: arr });
    }
  }
  isUrl(s) {
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(s);
  }

  onFileSelection = async (event) => {
    let file = event[0];

    if (file && file.size < 2880) {
      toast.error('Minimum File size should be 1MB.');
    } else if (file && file.size > 5242880) {
      toast.error('File size exceeded. Max size should be 5MB.');
    } else {
      // Update the state
      await this.setState({ selectedFile1: event[0] });
    }
  };

  render() {
    const { formData: user, email } = this.state;
    return (
      <div>
        <div className="editprofile-image-section">
          <div className="user-image">
            <div className="user-profile-image">
              <img
                src={
                  this.props.user.profilePicture
                    ? this.props.user.profilePicture
                    : '/../../../assets/images/user/01.jpg'
                }
                alt="User Profile"
                className="user-profileedit"
              />
              <ImageUploader
                withIcon={true}
                withPreview={true}
                buttonText={<i className="fa fa-camera"></i>}
                onChange={this.onFileSelection}
                imgExtension={['.jpg', '.jpeg', '.gif', '.png']}
                maxFileSize={5242880}
                singleImage={true}
              />
            </div>
          </div>
          <div className="edit-form-design">
            <form>
              {this.renderInput('name', 'Name', 'Enter Name')}
              {this.renderInput('email', 'Email', 'Enter Email', 'text', true)}
              {this.renderInput('website', 'Website', 'Enter Website Link')}
              {this.renderInput('description', 'Description', 'Enter Description')}
              <div className="intrest-part">
                <div className="about-me">
                  <h3>Interests</h3>
                  <div className="form-group">
                    <select className="form-control" id="interest" onChange={(e) => this.addTags(e)}>
                      <option value="">Select Option</option>
                      {this.state.interestList &&
                        this.state.interestList.map((user, index) => (
                          <option value={JSON.stringify(user)}>{user.name}</option>
                        ))}
                    </select>
                  </div>
                  <ul>
                    {this.state.selectedTags &&
                      this.state.selectedTags.map((user, index) => (
                        <li>
                          {user.name}{' '}
                          <Link>
                            <i
                              className="fa fa-times-circle-o"
                              onClick={() => this.removeTags(user.name, user._id)}
                            ></i>
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>

              {this.renderButton('Save Changes', this.handleSubmit, 'btn btn-primary')}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default EditprofileImage;
