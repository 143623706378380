import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import WOW from 'wowjs';
import AdminHeader from './../commonComponents/adminheader';
import { CircleArrow as ScrollUpButton } from 'react-scroll-up-button';
import Topmenubar from '../commonComponents/topmenubar';
import Bloggershorting from './bloggerSection/bloggerShorting';
import Bloggerlisttable from './bloggerSection/bloggerlisttable';
import httpAdminService from './../../services/httpAdminService';
import { toast } from 'react-toastify';
import moment from 'moment';

class Bloggerrlist extends Component {
  state = {
    usersData: '',
  };
  async componentDidMount() {
    this.bloggerApi();
    new WOW.WOW({
      live: false,
    }).init();
  }

  async bloggerApi(sort = '') {
    try {
      const { data } = await httpAdminService.get(
        `${process.env.REACT_APP_APIENDPOINT}/admin/usersData?type=blogger&sort=${sort}`,
      );
      if (data) {
        data.usersData.forEach((item) => {
          item.accountCreationDate = item.accountCreationDate && moment(item.accountCreationDate).format('LLL');
        });
        this.setState({ usersData: data.usersData });
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  async blockChangeApi(id) {
    try {
      const { data } = await httpAdminService.get(`${process.env.REACT_APP_APIENDPOINT}/admin/userBlock/${id}`);
      if (data) {
        toast.success(data.message);
        this.bloggerApi();

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  sortChange = (value) => {
    this.bloggerApi(value);
  };
  blockChange = (id) => {
    this.blockChangeApi(id);
  };
  render() {
    if (!this.state.usersData) return null;
    return (
      <div className="userhomepage">
        <div className="container-fluid menu-parts wow slideInDown">
          <AdminHeader />
        </div>
        <div className="container-fluid user-activity">
          <div className="container">
            <div className="row">
              <div className="col-12 wow fadeInUp">
                <Topmenubar user={this.props.location.user} {...this.props} />
                <Bloggershorting user={this.state.usersData} {...this.props} onSortChange={this.sortChange} />
                <Bloggerlisttable user={this.state.usersData} {...this.props} onBlockChange={this.blockChange} />
              </div>
            </div>
          </div>
        </div>
        <ScrollUpButton />
      </div>
    );
  }
}

export default Bloggerrlist;
