import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import * as eventServices from '../services/learnWithBthriveServices';
import EventModal from '../commonComponents/eventmodal';
import { Button, Modal } from 'react-bootstrap';
import MediaQuery from 'react-responsive';

class Events extends Component {
  state = {
    events: [],
    eventdata: '',
  };

  componentDidMount = async () => {
    const { data } = await eventServices.getUpcomingEvents();
    if (data) {
      let events = data.events;
      events.sort((a, b) => (a.date < b.date ? 1 : b.date < a.date ? -1 : 0));
      this.setState({ events: events.slice(0, 2) });
    }
  };

  getList = () => {
    return this.state.events.map((eventDetail, index) => {
      return (
        <li className="" key={index}>
          <Link onClick={() => this.handleModalShowHide(eventDetail)}>
            <img src={eventDetail.imagePath} alt="Event" />
            <h5>{eventDetail.name}</h5>
            <p>
              {eventDetail.date} <span>{eventDetail.time}</span>
            </p>
            {/* <span className="status">Free</span> */}
          </Link>
        </li>
      );
    });
  };
  handleModalShowHide(data) {
    this.setState({ showHide: !this.state.showHide });
    this.setState({ eventdata: data });
  }
  handleModalShowHide1 = () => {
    this.setState({ showHide1: !this.state.showHide1 });
  };
  render() {
    return (
      <>
        <div>
          <div className="user-home-events">
            <div className="heading-part">
              <h3>Upcoming Events</h3>
            </div>
            <div className="main-part">
              <ul>{this.getList()}</ul>
              <Modal show={this.state.showHide} className="upcommingevent-modal ">
                <Modal.Body>
                  <Link className="closeicon" onClick={() => this.handleModalShowHide()}>
                    <i className="fa fa-close"></i>
                  </Link>
                  <EventModal data={this.state.eventdata} type="event" />
                </Modal.Body>
              </Modal>
            </div>
            <div className="past-event">
              <Link to="/past-events">All Events</Link>
            </div>
          </div>
        </div>
        <MediaQuery query="(max-width:767px)">
          <Link onClick={() => this.handleModalShowHide1()}>
            {' '}
            <span className="event-btn">Events</span>
          </Link>
          <div>
            <div className="user-home-events1">
              <div className="heading-part">
                <h3>Upcoming Events</h3>
              </div>
              <div className="main-part">
                <ul>{this.getList()}</ul>
                <Modal show={this.state.showHide} className="upcommingevent-modal ">
                  <Modal.Body>
                    <Link className="closeicon" onClick={() => this.handleModalShowHide()}>
                      <i className="fa fa-close"></i>
                    </Link>
                    <EventModal data={this.state.eventdata} type="event" />
                  </Modal.Body>
                </Modal>
              </div>
            </div>
            <div className="past-event">
              <Link to="/past-events">All Events</Link>
            </div>
          </div>
        </MediaQuery>
      </>
    );
  }
}

export default Events;
