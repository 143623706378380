import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-tabs/style/react-tabs.css';
import httpService from '../services/httpService';
import { toast } from 'react-toastify';
import $ from 'jquery';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import AdminLearnWithBthrivePostQuestionList from './adminLearnWithBthrivePostQuestionList';

class LearnWithBthrivePosts extends Component {
  state = {
    listData: '',
  };

  componentDidMount = async () => {
    const data = this.props.data;
    this.setState({ listData: data });
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/getInterest`);
      this.setState({ interestList: data.interests });
      this.setState({ old_arr: data.interests });
    } catch (error) {
      toast.error(error.message);
      return;
    }
  };

  loader(type) {
    var shwload = document.getElementById('page_loader');
    shwload.style.display = type;
  }

  constructor(props) {
    super(props);
    this.state = {
      listData: props.data,
    };
  }

  loadMore = () => {
    if (
      $(window).scrollTop() + $(window).height() == $(document).height() ||
      $(window).scrollTop() + window.innerHeight >= document.body.scrollHeight
    ) {
      if (this.pageData.length != this.totalData) {
        var page_count = this.state.page + Number(1);
        this.setState({
          page: page_count,
        });
        // this.postAPI(this.tabType, this.state.page);
      }
    }
  };

  handleLike = (likeType, index, isChange) => {
    const listData = [...this.props.data];

    if (isChange == 1) {
      listData[index].my_like[0].type = 1;
      if (listData[index].likeList.length) {
        var index2 = listData[index].likeList.findIndex((p) => p.createdBy._id === this.props.user._id);
        if (index2 > -1) {
          listData[index].likeList.splice(index2, 1);
        }
      }
    } else {
      if (listData[index].my_like.length) {
        listData[index].my_like[0].type = likeType;
      } else {
        listData[index].my_like.push({ type: likeType });
      }

      if (listData[index].likeList.length) {
        const found2 = listData[index].likeList.some((el) => el.createdBy._id === this.props.user._id);
        if (!found2) {
          listData[index].likeList.push({
            createdBy: { name: this.props.user.name, _id: this.props.user._id },
          });
        }
      } else {
        listData[index].likeList.push({
          createdBy: { name: this.props.user.name, _id: this.props.user._id },
        });
      }
    }
    this.setState({
      listData,
    });
  };
  render() {
    if (!this.state.listData) return null;
    return (
      <div>
        <AdminLearnWithBthrivePostQuestionList
          listData={this.props.admin ? this.props.data : this.state.listData}
          onLikeChange={this.handleLike}
          user={this.props.user}
        />
      </div>
    );
  }
}

export default LearnWithBthrivePosts;
