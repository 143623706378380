import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
class BThriveFeature extends Component {
  state = {};
  render() {
    return (
      <div className="bthrive-feature">
        <div className="container">
          <div className="main-section">
            <div className="heading-section text-center">
              <h3 className="wow fadeInUp">B-Thrive Features</h3>
            </div>
            <div className="feature1">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="leftside">
                    <h4 className="wow fadeInUp">LEARN ABOUT OUR CULTURE AND HISTORY THROUGH SHARED CONTENT</h4>
                    <p className="wow fadeInUp">
                      Within Black African culture, oral storytelling is one of the oldest and most consistent ways of
                      passing down information from one generation to the next. Today, we’re privileged to digitize our
                      stories of strength, resilience, authenticity, wisdom, and royalty.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="rightside ">
                    <img src="assets/images/home/feature1.png" alt="Feature 1" className="wow zoomIn" />
                  </div>
                </div>
              </div>
            </div>
            <div className="feature2">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="rightside ">
                    <img src="assets/images/home/feature2.png" alt="Feature 2" className="wow zoomIn" />
                    <img src="assets/images/home/feature3.png" alt="Feature 2" className="secondimg wow zoomIn" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="leftside">
                    <h4 className="wow fadeInUp">ENGAGE WITH OTHERS ABOUT OUR DIVERSE AND BROAD CULTURE</h4>
                    <p className="wow fadeInUp">
                      From coast to coast, continent to continent, people of the African diaspora experience the world
                      uniquely. Ask questions and engage with answers. Educate each other on our greatness.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="feature3">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="leftside">
                    <h4 className="wow fadeInUp">CURATE COLLECTIONS OF OUR AMAZING HISTORY SHARED BY MEMBERS</h4>
                    <p className="wow fadeInUp">
                      Our common thread is our African-ness. Collect stories and information from other B-Thrive members
                      about our unique heritage. Gone are the days of allowing someone else to craft our narrative.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="rightside ">
                    <img src="assets/images/home/feature4.png" alt="Feature 4" className="wow zoomIn" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BThriveFeature;
