import { Component } from 'react';

class AdminSignOut extends Component {
  componentDidMount = async () => {
    localStorage.removeItem('tokenAdmin');
    return this.props.history.push('/admin/login');
  };
  render() {
    return null;
  }
}

export default AdminSignOut;
