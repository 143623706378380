import React from 'react';
import { Link } from 'react-router-dom';
import Form from '../../macroComponents/form/form';
import Joi from 'joi-browser';
import * as registerService from './../../services/registerServices';
import 'bootstrap/dist/css/bootstrap.css';
import WOW from 'wowjs';
class AdminOtpVerification extends Form {
  state = {
    formData: { otp: '' },
    errors: {},
  };

  schema = {
    otp: Joi.number().required(),
  };

  componentDidMount = async () => {
    localStorage.removeItem('tokenAdmin');
    if (!this.props.location.email) return await this.props.history.push(`/admin/login`);
    new WOW.WOW({
      live: false,
    }).init();
  };

  doSubmit = async () => {
    await localStorage.removeItem('tokenAdmin');
    const { data, error } = await registerService.adminOtpVerification(this.state.formData, this.props.location.email);
    if (error) return;
    else
      return await this.props.history.push({
        pathname: `/admin/reset-password`,
        email: this.props.location.email,
      });
  };

  render() {
    return (
      <div className="loginpage">
        <div className="login-section ">
          <div className="container">
            <div className="top-section text-center">
              <Link to="#">
                <img src="/assets/images/icons/login.png" alt="Login logo" />{' '}
              </Link>
              <h3>Please enter OTP</h3>
            </div>
            <div className="form-section wow bounceIn">
              <form>
                {this.renderInput('otp', 'One Time Password', 'Enter OTP')}
                {this.renderButton('Submit', this.handleSubmit)}
              </form>
            </div>
            <div className="login-footer text-center mt-4">
              <p>
                Back to <Link to="/admin/login">Login</Link> Page
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminOtpVerification;
