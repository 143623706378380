import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import WOW from 'wowjs';
import Form from '../../macroComponents/form/form';
import Joi from 'joi-browser';
import * as registerService from './../../services/registerServices';
class AdminforgotPasswordPage extends Form {
  state = {
    formData: {
      email: '',
    },
    errors: {},
  };
  schema = {
    email: Joi.string()
      .email({ tlds: { allow: ['com', 'net'] } })
      .required(),
  };
  doSubmit = async () => {
    const { data, error } = await registerService.adminOtpGeneration(this.state.formData.email);
    if (data) {
      this.props.history.push({
        pathname: `/admin/otp-verification`,
        email: this.state.formData.email,
      });
    }
    if (error) {
      return;
    }
  };
  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();
  }
  render() {
    return (
      <div className="loginpage">
        <div className="login-section ">
          <div className="container">
            <div className="top-section text-center">
              <Link to="#">
                <img src="../assets/images/icons/reset.png" alt="reset logo" />{' '}
              </Link>
              <h3>Reset Your Password</h3>
              <p>
                When you click the button, a verification code will be sent to <br></br>you via email
              </p>
            </div>
            <div className="form-section wow bounceIn">
              {this.renderInput('email', 'Email Address', 'Enter Email Address')}
              {this.renderButton('Send', this.handleSubmit)}
            </div>
            <div className="login-footer text-center mt-4">
              <p>
                Back to <Link to="/admin/login">Login</Link> Page
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminforgotPasswordPage;
