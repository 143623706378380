import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-tabs/style/react-tabs.css';
import { Tab, Tabs, TabList } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Modal } from 'react-bootstrap';
import * as eventServices from '../../services/learnWithBthriveServices';
import EventModal from './../../commonComponents/eventmodal';
import MediaQuery from 'react-responsive';
class PastClasses extends Component {
  state = {
    eventList: [],
    classdata: '',
  };

  async classList(type) {
    let classes;
    if (type == 'past') classes = (await eventServices.getPastClasses()).data.classes;
    else classes = (await eventServices.getUpcomingClasses()).data.classes;

    classes.sort((a, b) => (a.creationDate < b.creationDate ? 1 : b.creationDate < a.creationDate ? -1 : 0));
    this.setState({ eventList: classes.slice(0, 4) });
  }
  tabType = 'past';
  async componentDidMount() {
    this.classList('past');
  }
  hitAPI(tabName) {
    this.tabType = tabName;
    if (tabName == 'past') {
      this.classList('past');
    } else {
      this.classList('current');
    }
  }
  handleModalShowHide(data) {
    this.setState({ showHide: !this.state.showHide });
    this.setState({ classdata: data });
  }
  render() {
    return (
      <>
        <div className="events-display-section ">
          <div className="collection-display-section ">
            <div className="main-section">
              <div className="showing-comment">
                <div className="comments-section">
                  <div className="tabs-section  pt-1">
                    <Tabs>
                      <TabList>
                        <Tab onClick={() => this.hitAPI('past')}>Past Classes</Tab>
                        <Tab onClick={() => this.hitAPI('current')}>Current classes</Tab>
                      </TabList>
                    </Tabs>
                  </div>
                </div>
              </div>
              {this.state.eventList.map((item, index) => {
                return (
                  <div className="post-block mb-4 wow fadeInUp" key={index}>
                    <div className="row">
                      <div className="col-3">
                        <Link
                          className="nav-link"
                          onClick={this.tabType == 'current' ? () => this.handleModalShowHide(item) : null}
                        >
                          <div className="collection-image-part">
                            <img src={item.imagePath} alt="Events" />
                          </div>
                        </Link>
                      </div>
                      <div className="col-9">
                        <div className="collection-content">
                          <div className="collection-toppart">
                            <div className="left-part">
                              <Link
                                className="nav-link"
                                onClick={this.tabType == 'current' ? () => this.handleModalShowHide(item) : null}
                              >
                                <p className="">{item.name}</p>
                              </Link>
                            </div>
                            <div className="right-part">
                              <p className="smile-icon mr-3">
                                <i className="fa fa-database"></i>
                                {` ${item.type}`}
                              </p>
                            </div>
                          </div>
                          <div className="events-points">
                            <ul>
                              <li>
                                <i className="fa fa-calendar"></i> {item.date}
                              </li>
                              <li>
                                <i className="fa fa-clock-o"></i>
                                {item.time}
                              </li>
                              <li>
                                {' '}
                                Host{' '}
                                <img
                                  className={item.host_email?.isBlogger ? 'blogger' : ''}
                                  src={
                                    item.host_email ? item.host_email.profilePicture : '../../assets/images/user/01.jpg'
                                  }
                                  alt="Events"
                                />{' '}
                                {item.host_email ? item.host_email.name : item.host_email}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              <Modal show={this.state.showHide} className="upcommingevent-modal ">
                <Modal.Body>
                  <Link className="closeicon" onClick={() => this.handleModalShowHide()}>
                    <i className="fa fa-close"></i>
                  </Link>
                  <EventModal data={this.state.classdata} type="class" />
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
        <MediaQuery query="(max-width:767px)">
          <div className="events-display-section ">
            <div className="collection-display-section1 ">
              <div className="main-section">
                <div className="showing-comment">
                  <div className="comments-section">
                    <div className="tabs-section  pt-1">
                      <Tabs>
                        <TabList>
                          <Tab onClick={() => this.hitAPI('past')}>Past Classes</Tab>
                          <Tab onClick={() => this.hitAPI('current')}>Current classes</Tab>
                        </TabList>
                      </Tabs>
                    </div>
                  </div>
                </div>
                {this.state.eventList.map((item, index) => {
                  return (
                    <div className="post-block mb-4 wow fadeInUp" key={index}>
                      <div className="row">
                        <div className="col-md-5">
                          <Link
                            className="nav-link"
                            onClick={this.tabType == 'current' ? () => this.handleModalShowHide(item) : null}
                          >
                            <div className="collection-image-part mt-5">
                              <img src={item.imagePath} alt="Events" />
                            </div>
                          </Link>
                        </div>
                        <div className="col-md-7">
                          <div className="collection-content">
                            <div className="collection-toppart">
                              <div className="right-part">
                                <p className="smile-icon mr-3">
                                  <i className="fa fa-database"></i>
                                  {` ${item.type}`}
                                </p>
                              </div>
                              <div className="left-part">
                                <Link
                                  className="nav-link"
                                  onClick={this.tabType == 'current' ? () => this.handleModalShowHide(item) : null}
                                >
                                  <p
                                    style={{
                                      marginTop: '-15px',
                                      width: '200px',
                                    }}
                                  >
                                    {item.name}
                                  </p>
                                </Link>
                              </div>
                            </div>
                            <div className="events-points">
                              <ul>
                                <li style={{ marginTop: '-10px' }}>
                                  <i className="fa fa-calendar"></i> {item.date}
                                </li>
                                <li>
                                  <i className="fa fa-clock-o"></i>
                                  {item.time}
                                </li>
                                <li>
                                  {' '}
                                  Host{' '}
                                  <img
                                    className={item.host_email?.isBlogger ? 'blogger' : ''}
                                    src={
                                      item.host_email
                                        ? item.host_email.profilePicture
                                        : '../../assets/images/user/01.jpg'
                                    }
                                    alt="Events"
                                  />{' '}
                                  {item.host_email ? item.host_email.name : item.host_email}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <Modal show={this.state.showHide} className="upcommingevent-modal ">
                  <Modal.Body>
                    <Link className="closeicon" onClick={() => this.handleModalShowHide()}>
                      <i className="fa fa-close"></i>
                    </Link>
                    <EventModal data={this.state.classdata} type="class" />
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </MediaQuery>
      </>
    );
  }
}

export default PastClasses;
