import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Header from './../commonComponents/userheader';
import WOW from 'wowjs';
import Sidebar from './../commonComponents/sidebar';
import { CircleArrow as ScrollUpButton } from 'react-scroll-up-button';
import httpService from './../services/httpService';
import { toast } from 'react-toastify';
import moment from 'moment';
import Events from './../commonComponents/events';
import Collectionback from './collectionsSection/collectionback';
import CollectionDetailSection from './collectionsSection/collectionDetailSection';
import parse from 'html-react-parser';
import $ from 'jquery';
import AllPostQuestionList from '../commonComponents/allPostQuestionList';
import BookClubPostQuestionList from './../commonComponents/bookClubPostQuestionList';
import MediaQuery from 'react-responsive';
import Contactus from '../commonComponents/contactus';

class collectionDetailPage extends Component {
  pageData = [];
  tabType = 'post';
  totalData = '';
  state = {
    listData: '',
    listData1: '',
    page: Number(1),
    user: '',
    collectionDetail: '',
  };

  componentDidMount = async () => {
    this.collectionDetail();
    this.postAPI('post', this.state.page);
    window.addEventListener('scroll', this.loadMore);
    document.body.addEventListener('touchmove', this.loadMore);
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/myData`);
      if (data) {
        const user = data.user;
        this.setState({ user: user });

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }

    new WOW.WOW({
      live: false,
    }).init();
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.loadMore);
    document.body.removeEventListener('touchmove', this.loadMore);
  }

  loader(type) {
    var shwload = document.getElementById('page_loader');
    shwload.style.display = type;
  }

  async postAPI(name, page) {
    //this.loader("block");
    try {
      const { data } = await httpService.get(
        `${process.env.REACT_APP_APIENDPOINT}/post/${name}?page=${page}&collectionId=${this.props.match.params.id}`,
      );

      if (data) {
        data.postList[0].data.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('MMM DD, YYYY h:mm A');
          item.description = parse(item.description);
        });
        const listData = data.postList[0].data;
        this.totalData = data.postList[0].metadata[0] ? data.postList[0].metadata[0].total : '';
        this.pageData = [...this.pageData, ...listData];
        this.setState({ listData: this.pageData });
        this.filterlist(this.state.listData, 'post');
        //this.loader("none");

        return;
      }
    } catch (error) {
      this.loader('none');
      toast.error(error.message);
      return;
    }
  }
  async bookClubPostAPI(name, page) {
    //this.loader("block");
    try {
      const { data } = await httpService.get(
        `${process.env.REACT_APP_APIENDPOINT}/bookClubPost/${name}?page=${page}&collectionId=${this.props.match.params.id}`,
      );
      if (data) {
        data.postList[0].data.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('MMM DD, YYYY h:mm A');
          item.description = parse(item.description);
        });
        const listData = data.postList[0].data;
        // this.totalData = data.postList[0].metadata[0].total;
        // this.pageData = [...this.pageData, ...listData];
        this.setState({ listData1: listData });
        this.filterlist(this.state.listData1, 'bookClubPost');
        //this.loader("none");
        return;
      }
    } catch (error) {
      this.loader('none');
      toast.error(error.message);
      return;
    }
  }

  constructor(props) {
    super(props);
  }

  loadMore = () => {
    if (
      $(window).scrollTop() + $(window).height() == $(document).height() ||
      $(window).scrollTop() + window.innerHeight >= document.body.scrollHeight
    ) {
      if (this.pageData.length != this.totalData) {
        var page_count = this.state.page + Number(1);
        this.setState({
          page: page_count,
        });
        //this.postAPI(this.tabType, this.state.page);
      }
    }
  };

  collectionDetail = async () => {
    try {
      const { data } = await httpService.get(
        `${process.env.REACT_APP_APIENDPOINT}/collection-detail/${this.props.match.params.id}`,
      );
      if (data) {
        data.collectionDetail[0].creationDate = moment(data.collectionDetail[0].creationDate).format(
          'MMM DD, YYYY h:mm A',
        );

        const collectionDetail = data.collectionDetail;
        this.setState({ collectionDetail: collectionDetail });
        if (this.state.collectionDetail[0].bookClubPost) this.bookClubPostAPI('post', this.state.page);
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  };

  handleLike = (likeType, index, isChange) => {
    const listData = [...this.state.listData];

    if (isChange == 1) {
      listData[index].my_like[0].type = 1;
      if (listData[index].likeList.length) {
        var index2 = listData[index].likeList.findIndex((p) => p.createdBy._id === this.state.user._id);
        if (index2 > -1) {
          listData[index].likeList.splice(index2, 1);
        }
      }
    } else {
      if (listData[index].my_like.length) {
        listData[index].my_like[0].type = likeType;
      } else {
        listData[index].my_like.push({ type: likeType });
      }

      if (listData[index].likeList.length) {
        const found2 = listData[index].likeList.some((el) => el.createdBy._id === this.state.user._id);
        if (!found2) {
          listData[index].likeList.push({
            createdBy: { name: this.state.user.name, _id: this.state.user._id },
          });
        }
      } else {
        listData[index].likeList.push({
          createdBy: { name: this.state.user.name, _id: this.state.user._id },
        });
      }
    }
    this.setState({
      listData,
    });
    // this.setState({language: langValue});
  };

  handleLike2 = (likeType, index, isChange) => {
    const collectionDetail = [...this.state.collectionDetail];

    if (isChange == 1) {
      collectionDetail[0].my_like[0].type = 1;
      if (collectionDetail[0].likeList.length) {
        var index2 = collectionDetail[0].likeList.findIndex((p) => p.createdBy._id === this.state.user._id);
        if (index2 > -1) {
          collectionDetail[0].likeList.splice(index2, 1);
        }
      }
    } else {
      if (collectionDetail[0].my_like.length) {
        collectionDetail[0].my_like[0].type = likeType;
      } else {
        collectionDetail[0].my_like.push({ type: likeType });
      }

      if (collectionDetail[0].likeList.length) {
        const found2 = collectionDetail[0].likeList.some((el) => el.createdBy._id === this.state.user._id);
        if (!found2) {
          collectionDetail[0].likeList.push({
            createdBy: { name: this.state.user.name, _id: this.state.user._id },
          });
        }
      } else {
        collectionDetail[0].likeList.push({
          createdBy: { name: this.state.user.name, _id: this.state.user._id },
        });
      }
    }
    this.setState({
      collectionDetail,
    });
    // this.setState({language: langValue});
  };
  onBlock = () => {
    this.componentDidMount();
  };

  filterlist = (data, model) => {
    let listData = data;
    if (this.state.user) {
      for (var index = 0; index < listData.length; index++) {
        if (
          this.state.user.usersBlocked.includes(listData[index].createdBy._id) ||
          listData[index].createdBy.usersBlocked.includes(this.state.user._id)
        ) {
          listData.splice(index, 1);
          index--;
        }
      }
    }
    if (model == 'post') this.setState({ listData: listData });
    else this.setState({ listData1: listData });
  };
  onEdit = () => {
    this.setState({ page: Number(1) });
    this.pageData = [];
    this.postAPI(this.tabType, this.state.page);
    this.bookClubPostAPI('post', this.state.page);
  };

  render() {
    if (!this.state.collectionDetail) return null;
    return (
      <>
        <div className="userhomepage">
          <div className="container-fluid menu-parts wow slideInDown">
            <Header user={this.state.user} {...this.props} />
          </div>
          <div className="container-fluid user-activity">
            <div className="container">
              <Contactus />
              <div className="row">
                <div className="col-2 wow slideInLeft">
                  <Sidebar {...this.props} />
                </div>
                <div className="col-8 wow bounceIn">
                  <Collectionback />
                  <CollectionDetailSection
                    collectionDetail={this.state.collectionDetail}
                    user={this.state.user}
                    onLikeChange2={this.handleLike2}
                  />
                  {(
                    this.state.collectionDetail[0].bookClubPost
                      ? !this.state.collectionDetail[0].bookClubPost.length ||
                        this.state.collectionDetail[0].post.length
                      : true
                  ) ? (
                    <AllPostQuestionList
                      listData={this.state.listData}
                      {...this.props}
                      onLikeChange={this.handleLike}
                      user={this.state.user}
                      onBlock={this.onBlock}
                      onEdit={this.onEdit}
                    />
                  ) : (
                    ''
                  )}
                  {this.state.collectionDetail[0].bookClubPost ? (
                    this.state.collectionDetail[0].bookClubPost.length ? (
                      <BookClubPostQuestionList
                        listData={this.state.listData1}
                        {...this.props}
                        onLikeChange={this.handleLike}
                        user={this.state.user}
                        onBlock={this.onBlock}
                        onEdit={this.onBlock}
                      />
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )}
                </div>
                <div className="col-2 wow slideInLeft">
                  <Events />
                </div>
              </div>
            </div>
          </div>
          <ScrollUpButton />
        </div>
        <MediaQuery query="(max-width:767px)">
          <div className="userhomepage1">
            <div className="container-fluid menu-parts wow slideInDown">
              <Header user={this.state.user} {...this.props} />
            </div>
            <div className=" user-activity">
              <div className="">
                <div className="row">
                  <div className="col-2 wow slideInLeft">
                    <Sidebar {...this.props} />
                  </div>
                  <div className="col-12 wow bounceIn">
                    <Collectionback />
                    <CollectionDetailSection
                      collectionDetail={this.state.collectionDetail}
                      user={this.state.user}
                      onLikeChange2={this.handleLike2}
                    />
                    {(
                      this.state.collectionDetail[0].bookClubPost
                        ? !this.state.collectionDetail[0].bookClubPost.length ||
                          this.state.collectionDetail[0].post.length
                        : true
                    ) ? (
                      <AllPostQuestionList
                        listData={this.state.listData}
                        {...this.props}
                        onLikeChange={this.handleLike}
                        user={this.state.user}
                        onBlock={this.onBlock}
                        onEdit={this.onEdit}
                      />
                    ) : (
                      ''
                    )}
                    {this.state.collectionDetail[0].bookClubPost ? (
                      this.state.collectionDetail[0].bookClubPost.length ? (
                        <BookClubPostQuestionList
                          listData={this.state.listData1}
                          {...this.props}
                          onLikeChange={this.handleLike}
                          user={this.state.user}
                          onBlock={this.onBlock}
                          onEdit={this.onBlock}
                        />
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
            <ScrollUpButton />
          </div>
        </MediaQuery>
      </>
    );
  }
}

export default collectionDetailPage;
