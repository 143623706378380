import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
class Topmenubar extends Component {
  state = {};
  render() {
    return (
      <div className="top-menu-bar">
        <div className="main-section">
          <ul>
            <li className={`menulink ${window.location.href.includes('dashboard') ? 'active' : ''}`}>
              <Link
                to={{
                  pathname: '/admin/dashboard',
                  state: { user: this.props.user },
                }}
              >
                Overview
              </Link>
            </li>
            <li className={`menulink ${window.location.href.includes('user-list') ? 'active' : ''}`}>
              <Link
                to={{
                  pathname: '/admin/user-list',
                  state: { user: this.props.user },
                }}
              >
                Users
              </Link>
            </li>
            <li className={`menulink ${window.location.href.includes('blogger-list') ? 'active' : ''}`}>
              <Link
                to={{
                  pathname: '/admin/blogger-list',
                  state: { user: this.props.user },
                }}
              >
                Blogger
              </Link>
            </li>
            <li className={`menulink ${window.location.href.includes('event-list') ? 'active' : ''}`}>
              <Link
                to={{
                  pathname: '/admin/event-list',
                  state: { user: this.props.user },
                }}
              >
                Events
              </Link>
            </li>
            <li className={`menulink ${window.location.href.includes('interest-list') ? 'active' : ''}`}>
              <Link
                to={{
                  pathname: '/admin/interest-list',
                  state: { user: this.props.user },
                }}
              >
                Interests
              </Link>
            </li>
            <li className={`menulink ${window.location.href.includes('book-club') ? 'active' : ''}`}>
              <Link
                to={{
                  pathname: '/admin/book-club',
                  state: { user: this.props.user },
                }}
              >
                Book Club
              </Link>
            </li>
            <li className={`menulink ${window.location.href.includes('all-reports-list') ? 'active' : ''}`}>
              <Link
                to={{
                  pathname: '/admin/all-reports-list',
                  state: { user: this.props.user },
                }}
              >
                Reports
              </Link>
            </li>
            <li className={`menulink ${window.location.href.includes('contact-list') ? 'active' : ''}`}>
              <Link
                to={{
                  pathname: '/admin/contact-list',
                  state: { user: this.props.user },
                }}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Topmenubar;
