import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { Button, Modal } from 'react-bootstrap';
import 'react-tabs/style/react-tabs.css';
import ImageUploader from 'react-images-upload';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Form from '../../../macroComponents/form/form';
import Joi from 'joi-browser';
import { toast } from 'react-toastify';
import * as postAdminService from '../../../services/postAdminService';
import TimePicker from 'react-time-picker';

class Eventbackpost extends Form {
  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide });
    this.setState({
      formData: {
        name: '',
        type: 'event',
        host_email: '',
        date: '',
        time: '',
      },
    });
    this.setState({ selectedFile1: null });
  }
  state = {
    formData: {
      name: '',
      type: 'event',
      host_email: '',
      date: '',
      time: '',
    },
    selectedFile1: null,
    errors: {},
  };

  schema = {
    name: Joi.string().min(3).max(30).required(),
    type: Joi.string().required(),
    host_email: Joi.string().min(3).required().email(),
    date: Joi.date().required(),
    time: Joi.string().required(),
  };

  onFileSelection = async (event) => {
    let file = event[0];

    if (file && file.size < 2880) {
      toast.error('Minimum File size should be 1MB.');
    } else if (file && file.size > 5242880) {
      toast.error('File size exceeded. Max size should be 5MB.');
    } else {
      // Update the state
      await this.setState({ selectedFile1: event[0] });
    }
  };
  ValidateEmail = (inputText) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (inputText.match(mailformat)) return true;
    return false;
  };

  onTimeChange = (date) => {
    var timeSplit = date.split(':'),
      hours,
      minutes,
      meridian;
    hours = timeSplit[0];
    minutes = timeSplit[1];
    if (hours > 12) {
      meridian = 'PM';
      hours -= 12;
    } else if (hours < 12) {
      meridian = 'AM';
      if (hours == 0) {
        hours = 12;
      }
    } else {
      meridian = 'PM';
    }
    return `${hours}:${minutes} ${meridian}`;
  };
  doSubmit = async () => {
    if (!this.ValidateEmail(this.state.formData.host_email)) {
      toast.error('Invalid Email');
      return;
    }
    if (!this.state.selectedFile1) {
      toast.error('Please select Cover Picture');
      return;
    }
    if (!this.state.formData.date) {
      toast.error('Please select Date');
      return;
    }
    if (new Date(this.state.formData.date) < new Date(Date.now())) {
      toast.error('Select an Upcoming Date');
      return;
    }
    let formData = new FormData();
    {
      let data = { ...this.state.formData };
      data.time = this.onTimeChange(this.state.formData.time);
      for (const item in data) {
        if (data[item] === '') {
          data[item] = 'N/A';
        }
        await formData.append(item, data[item]);
      }
    }

    await formData.append('eventImage', this.state.selectedFile1, this.state.selectedFile1.name);

    this.handleModalShowHide();
    const { error } = await postAdminService.createEventOrClass(formData);

    if (error) return;
    this.props.onAdd();
  };

  getDate = (value) => {
    if (new Date(value) < new Date(Date.now())) toast.error('Select an Upcoming Date');
    const formData = { ...this.state.formData };
    formData.date = new Date(value);

    this.setState({ formData });
  };
  handleChanges = (e) => {
    this.props.onSort(e.target.value);
  };

  render() {
    return (
      <div className="shorting-dashboard mt-5">
        <div className="main-section">
          <div className="left-part">
            <p>You have a total of {this.props.data.length} Events List</p>
          </div>
          <div className="right-part">
            <div className="form-group">
              <label>Sort By :</label>
              <select className="fomr-control" onChange={this.handleChanges}>
                <option value="All_Time">All Time</option>
                <option value="last_minute">Last Minute</option>
                <option value="last_hour">Last Hours</option>
                <option value="last_week">Last Week</option>
                <option value="last_month">Last Month</option>
                <option value="last_quarter">Last Quarter</option>
                <option value="Last_Year">Last Year</option>
              </select>
            </div>
            <Link onClick={() => this.handleModalShowHide()} className="add-events">
              Add Event
            </Link>
          </div>
          <Modal show={this.state.showHide} className="becomehost-modal adminevent ">
            <Modal.Body>
              <Link className="closeicon" onClick={() => this.handleModalShowHide()}>
                <i className="fa fa-close"></i>
              </Link>
              <div className="collection">
                <h4 className="text-center">Add an Event </h4>
                <p className="text-center">Select a Date to Continue</p>

                <div className="row">
                  <div className="col-md-6">
                    <div className="selectpart">
                      <div className="host-date">
                        <Calendar
                          onChange={(data) => {
                            this.getDate(data);
                          }}
                        />
                      </div>
                      {this.renderInput('time', 'Enter a Time', 'Enter a Time', 'time')}
                      {/* <TimePicker
        
      /> */}
                      {this.renderInput('name', 'Enter event name', 'Enter event name')}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="selectpart">
                      {this.renderSelect('type', 'Select event type', [
                        { _id: 'event', name: 'Event' },
                        { _id: 'class', name: 'Class' },
                      ])}
                      {this.renderInput('host_email', 'Add a Host Email', 'Add a Host Email')}

                      <div className="form-group">
                        <label>Add a Cover Image</label>
                        <div className="collection-image">
                          <ImageUploader
                            withIcon={true}
                            withPreview={true}
                            buttonText={<i className="fa fa-camera"></i>}
                            onChange={this.onFileSelection}
                            imgExtension={['.jpg', '.jpeg', '.gif', '.png']}
                            maxFileSize={5242880}
                            singleImage={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="admin-btn">{this.renderButton('Submit', this.handleSubmit)}</div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}

export default Eventbackpost;
