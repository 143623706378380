import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-tabs/style/react-tabs.css';
import { Tab, Tabs, TabList } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Modal } from 'react-bootstrap';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import httpService from '../../../services/httpService';
import { toast } from 'react-toastify';
import Form from '../../../macroComponents/form/form';
import parse from 'html-react-parser';
import moment from 'moment';
import httpAdminService from '../../../services/httpAdminService';
import * as postAdminService from '../../../services/postAdminService';
import ShowMoreText from 'react-show-more-text';
import EditLearnPost from '../pastevents/editLearnPost';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import Prince1 from '../../../assets/images/icons/Prince1.png';
import Heart1 from '../../../assets/images/icons/Heart1.png';
import Linkify from 'react-linkify';
import { RplFetcher } from '../../../lib/helpers';

class BThrivedetail extends Form {
  state = {
    listData: '',
    commentList: '',
    description: '',
    deleteId: '',
    updateStatus: {
      type: '',
      _id: '',
    },
    errors: {},

    allLikeData: [],
    allLikeCount: '',
    postId: '',
  };

  componentDidMount = async () => {
    try {
      const { data } = await httpAdminService.get(
        `${process.env.REACT_APP_APIENDPOINT}/admin/learnWithBthrivepost/post?id=${this.props.match.params.id}`,
      );

      if (data) {
        data.postList[0].data.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('MMM DD, YYYY h:mm A');
          item.description = parse(item.description);
        });
        const listData = data.postList[0].data[0];
        this.setState({ listData: listData });
        this.hitAllLikeDataApi(this.props.match.params.id, '');
        this.commentList('comment');

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  };

  emojiModalShowHide(postId = '', type = '') {
    if (postId) {
      this.setState({ postId: postId });
      this.hitAllLikeDataApi(postId, (type = ''));
    }
    if (type) {
      if (type == 'all') type = '';
      this.hitAllLikeDataApi(this.state.postId, type);
    } else {
      this.setState({ emojishowHide: !this.state.emojishowHide });
    }
  }

  async hitAllLikeDataApi(postId, type) {
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/likeList?id=${postId}&type=${type}`);

      if (data) {
        const allLikeData = data.likeList;
        this.setState({ allLikeData: allLikeData });
        this.setState({ allLikeCount: data.likes });
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  constructor(props) {
    super(props);
  }
  tabType = 'comment';

  async commentList(name) {
    try {
      const { data } = await httpAdminService.get(
        `${process.env.REACT_APP_APIENDPOINT}/admin/comment/${this.props.match.params.id}/${name}?modal=learn&admin=learn`,
      );
      if (data) {
        data.commentList.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('MMM DD, YYYY h:mm A');
        });
        const commentList = data.commentList;
        this.setState({ commentList: commentList });

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  hitAPI(tabName) {
    this.tabType = tabName;
    if (tabName == 'comment') {
      this.commentList('comment');
    } else {
      this.commentList('insight');
    }
  }

  async handleClick(type, commentId) {
    this.state.updateStatus.type = type;
    this.state.updateStatus._id = commentId;

    const { data, error } = await postAdminService.adminUpdateCommentStatus(this.state.updateStatus);
    if (error) return;
    else {
      this.hitAPI(this.tabType);

      return;
    }
  }

  handleModalShowHide1() {
    this.setState({ showHide: !this.state.showHide });
  }
  copyToClipboard(text) {
    var dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    toast.success('Link Copied');
  }
  deletePost = async () => {
    try {
      const { data } = await httpAdminService.get(
        `${process.env.REACT_APP_APIENDPOINT}/admin/deleteLearnWithBthrivePost/${this.state.deleteId}`,
      );

      if (data) {
        toast.success(data.message);
        return await this.props.history.push('/admin/event-list');
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  };
  deleteModalShowHide = (id) => {
    this.setState({ deleteId: id });
    this.setState({ deleteShowHide: !this.state.deleteShowHide });
  };
  editModalHandler() {
    this.setState({ editModalShowHide: !this.state.editModalShowHide });
  }
  onEdit = () => {
    this.editModalHandler('');
    this.componentDidMount();
  };
  nFormatter = (num) => {
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item ? (num / item.value).toFixed(1).replace(rx, '$1') + item.symbol : '0';
  };

  render() {
    const componentDecorator = (href, text, key) => (
      <a href={href} key={key} target="_blank">
        {text}
      </a>
    );
    return (
      <div className="post-display-section ">
        <div className="main-section">
          {this.state.listData.createdBy && (
            <div className="post-block mb-3  learnboder wow fadeInUp">
              <div className="post-toppart">
                <div className="left-part">
                  <Link className="nav-link">
                    <img src="../../assets/images/user/02.png" alt="User Profile" />
                    <p>Learn With B-thrive</p> <span>Posted : {this.state.listData.creationDate}</span>
                  </Link>
                </div>
                <div className="right-part">
                  <Link className="dotsbar">
                    <i className="fa fa-ellipsis-v"></i>
                    <div className="post-dot-option">
                      <ul>
                        <li>
                          <Link onClick={() => this.copyToClipboard(`${window.location.href}`)}>
                            <i className="fa fa-link"></i> Copy Link
                          </Link>
                        </li>
                        <li>
                          <Link onClick={() => this.editModalHandler()}>
                            <i className="fa fa-edit"></i> Edit Post
                          </Link>
                        </li>
                        <li>
                          <Link onClick={() => this.deleteModalShowHide(this.state.listData._id)}>
                            <i className="fa fa-trash"></i> Delete Post
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="messge-box">
                <p>
                  <ShowMoreText
                    /* Default options */
                    lines={3}
                    more="Read more"
                    less="Read less"
                    onClick={this.executeOnClick}
                    expanded={false}
                  >
                    {this.state.listData.description}
                  </ShowMoreText>
                </p>
                {this.state.listData.link ? (
                  <>
                    <LinkPreview
                      fetcher={RplFetcher}
                      url={
                        this.state.listData.link.includes('https://www.') || this.state.listData.link.includes('http')
                          ? this.state.listData.link
                          : `https://www.${this.state.listData.link.replace('www.', '')}`
                      }
                      width="680px"
                      height="350px"
                      imageHeight="320px"
                      descriptionLength="80"
                      className="link-preview ml-1"
                    />
                    <a
                      href={
                        this.state.listData.link.includes('https://www.') || this.state.listData.link.includes('http')
                          ? this.state.listData.link
                          : `https://www.${this.state.listData.link.replace('www.', '')}`
                      }
                      target="_blank"
                      className="postlinks"
                    >
                      <i className="fa fa-link"></i> {this.state.listData.link}
                    </a>
                  </>
                ) : (
                  ''
                )}
                {this.state.listData.web_link ? (
                  <>
                    <LinkPreview
                      fetcher={RplFetcher}
                      url={
                        this.state.listData.web_link.includes('https://www.') ||
                        this.state.listData.web_link.includes('http')
                          ? this.state.listData.web_link
                          : `https://www.${this.state.listData.web_link.replace('www.', '')}`
                      }
                      width="680px"
                      height="350px"
                      imageHeight="320px"
                      descriptionLength="80"
                      className="link-preview ml-1"
                    />
                    <a
                      href={
                        this.state.listData.web_link.includes('https://www.') ||
                        this.state.listData.web_link.includes('http')
                          ? this.state.listData.web_link
                          : `https://www.${this.state.listData.web_link.replace('www.', '')}`
                      }
                      target="_blank"
                      className="postlinks mt-0 mb-1"
                    >
                      <i className="fa fa-globe"></i> {this.state.listData.web_link}
                    </a>
                  </>
                ) : (
                  ''
                )}
              </div>

              {this.state.listData.images.length || this.state.listData.videos.length ? (
                <div className="image-post">
                  {this.state.listData.images.length ? (
                    <img src={this.state.listData.images[0]} alt="Event" />
                  ) : this.state.listData.videos.length ? (
                    <video controls height="400px" width="100%">
                      <source src={this.state.listData.videos[0]} type="video/mp4"></source>
                    </video>
                  ) : null}
                  <Link onClick={() => this.handleModalShowHide1()} className="view-all-image">
                    {' '}
                    {this.state.listData.images.length + this.state.listData.videos.length > 1 ? (
                      <p> View all </p>
                    ) : (
                      <p style={{ padding: '27px' }}> View </p>
                    )}
                  </Link>
                </div>
              ) : (
                ''
              )}

              <Modal show={this.state.showHide} className="allimage-modal ">
                <Modal.Body>
                  <div className="allimagepopup text-center">
                    <a className="closeicon" onClick={() => this.handleModalShowHide1()}>
                      <i className="fa fa-close"></i>
                    </a>
                    <div className="slider-part ">
                      <Carousel infiniteLoop useKeyboardArrows autoPlay>
                        {this.state.listData.images.map((name, index) => (
                          <div className="imageviews">
                            <img src={name} alt="Event" />
                          </div>
                        ))}
                        {this.state.listData.videos.map((name, index) => (
                          <div>
                            <video controls>
                              <source src={name} type="video/mp4" />
                            </video>
                          </div>
                        ))}
                      </Carousel>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              <Modal show={this.state.emojishowHide} className="emoji-modal ">
                <Modal.Body>
                  <Link className="closeicon" onClick={() => this.emojiModalShowHide()}>
                    <i className="fa fa-close"></i>
                  </Link>
                  <div className="collection">
                    <h4 className="">Reactions </h4>
                    <div className="reaction-parts">
                      <Tabs>
                        <TabList id="style-1">
                          <Tab onClick={() => this.emojiModalShowHide('', 'all')}>
                            All {this.nFormatter(this.state.allLikeCount.total_likes)}
                          </Tab>
                          <Tab onClick={() => this.emojiModalShowHide('', 11)}>
                            <span>
                              {' '}
                              <img className="emoji-list-icon2" src="/../assets/images/icons/Thumbsup1.png" />
                            </span>{' '}
                            {this.nFormatter(this.state.allLikeCount.total_likes_1)}
                          </Tab>
                          <Tab onClick={() => this.emojiModalShowHide('', 21)}>
                            <span>
                              {' '}
                              <img className="emoji-list-icon2" src="/../assets/images/icons/Handraise1.png" />{' '}
                            </span>{' '}
                            {this.nFormatter(this.state.allLikeCount.total_likes_2)}
                          </Tab>
                          <Tab onClick={() => this.emojiModalShowHide('', 31)}>
                            <span>
                              {' '}
                              <img className="emoji-list-icon2" src="/../assets/images/icons/Raised-fist1.png" />{' '}
                            </span>{' '}
                            {this.nFormatter(this.state.allLikeCount.total_likes_3)}
                          </Tab>
                          <Tab onClick={() => this.emojiModalShowHide('', 41)}>
                            <span>
                              {' '}
                              <img className="emoji-list-icon2" src="/../assets/images/icons/Folded-hand1.png" />{' '}
                            </span>{' '}
                            {this.nFormatter(this.state.allLikeCount.total_likes_4)}
                          </Tab>
                          <Tab onClick={() => this.emojiModalShowHide('', 5)}>
                            <span>
                              <img className="emoji-list-icon2" src={Heart1} />
                            </span>{' '}
                            {this.nFormatter(this.state.allLikeCount.total_likes_5)}
                          </Tab>
                          <Tab onClick={() => this.emojiModalShowHide('', 61)}>
                            <span>
                              {' '}
                              <img className="emoji-list-icon2" src="/../assets/images/icons/Princess1.png" />{' '}
                            </span>{' '}
                            {this.nFormatter(this.state.allLikeCount.total_likes_6)}
                          </Tab>
                          <Tab onClick={() => this.emojiModalShowHide('', 71)}>
                            <span>
                              {' '}
                              <img className="emoji-list-icon2" src={Prince1} />{' '}
                            </span>{' '}
                            {this.nFormatter(this.state.allLikeCount.total_likes_7)}
                          </Tab>
                        </TabList>
                      </Tabs>
                      <div className="likeuser-list">
                        <ul>
                          {this.state.allLikeData.length
                            ? this.state.allLikeData.map((data, index) => {
                                return (
                                  <li>
                                    <Link to={`/admin/user-profile-detail/${data.createdBy._id}`}>
                                      <h5>
                                        {data.createdBy.name}
                                        {data.createdBy.isBlogger ? (
                                          <span style={{ fontSize: '13px' }}>
                                            {' '}
                                            <i
                                              className="fa fa-circle ml-1 mr-2"
                                              style={{
                                                fontSize: '8px',
                                                color: 'black',
                                              }}
                                            ></i>
                                            Blogger
                                          </span>
                                        ) : null}
                                      </h5>
                                      {data.createdBy.location ? (
                                        <span>{data.createdBy.location}</span>
                                      ) : (
                                        <span style={{ color: 'white' }}>Noida</span>
                                      )}
                                      <img
                                        className={data.createdBy.isBlogger ? 'pic2' : ''}
                                        src={
                                          data.createdBy.profilePicture
                                            ? data.createdBy.profilePicture
                                            : '../../../assets/images/user/01.jpg'
                                        }
                                        alt="User Profile"
                                      />
                                    </Link>
                                  </li>
                                );
                              })
                            : ''}
                        </ul>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              <div className="bottom-part">
                <div className="left-part">
                  <ul>
                    {this.state.listData.interest.map((item) => {
                      <li>{item.name}</li>;
                    })}
                  </ul>
                </div>
                <div className="right-part">
                  <div className="liked-user mt-5 mb-5">
                    <Link onClick={() => this.emojiModalShowHide(this.state.listData._id)}>
                      {this.state.allLikeData.length
                        ? `Liked by ${this.state.allLikeData[this.state.allLikeData.length - 1].createdBy.name} ${
                            this.state.allLikeData.length > 1 ? `and others` : ''
                          } `
                        : ''}
                    </Link>
                  </div>
                  <div className="reactionpart">
                    <Link className="smile-icon" onClick={() => this.emojiModalShowHide(this.state.listData._id)}>
                      <span>
                        {' '}
                        <img style={{ width: '21px' }} src="/../assets/images/icons/Thumbsup1.png" />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="showing-comment">
                <div className="start-messege">
                  <p>Showing {this.tabType}</p>
                </div>
                <div className="comments-section">
                  <div className="tabs-section">
                    {this.state.listData.type == 'post' ? (
                      <Tabs>
                        <TabList>
                          <Tab onClick={() => this.hitAPI('comment')}>Comments</Tab>
                          <Tab onClick={() => this.hitAPI('insight')}>Insights</Tab>
                        </TabList>
                      </Tabs>
                    ) : (
                      ''
                    )}
                    <div className="commnets-box">
                      <div className="postsection"></div>
                    </div>
                    <div className="comments-list mt-3">
                      {this.state.commentList.length
                        ? this.state.commentList.map((item, index) => (
                            <div className="list-parts">
                              <div className="post-block mb-3 wow fadeInUp">
                                <div className="post-toppart">
                                  <div className="left-part">
                                    <Link className="nav-link" to={`/admin/user-profile-detail/${item.createdBy._id}`}>
                                      <img
                                        className={
                                          item.type === 'insight'
                                            ? 'insightimg'
                                            : item.createdBy.isBlogger
                                            ? 'blogger'
                                            : ''
                                        }
                                        src={
                                          item.createdBy.profilePicture
                                            ? item.createdBy.profilePicture
                                            : '../../../assets/images/user/01.jpg'
                                        }
                                        alt="User Profile"
                                      />
                                      <p>
                                        {item.createdBy.name}
                                        {item.createdBy.isBlogger ? (
                                          <span style={{ fontSize: '13px' }}>
                                            {' '}
                                            <i
                                              className="fa fa-circle ml-1 mr-2"
                                              style={{
                                                fontSize: '8px',
                                                color: 'black',
                                              }}
                                            ></i>
                                            Blogger
                                          </span>
                                        ) : null}
                                      </p>{' '}
                                      <span>Posted : {item.creationDate}</span>
                                    </Link>
                                  </div>
                                  {item.type == 'insight' ? (
                                    <div className="right-part">
                                      <Link className="insight-icons">
                                        <img src="../../assets/images/icons/icon.png" />
                                      </Link>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                                <div className="messge-box">
                                  <p>
                                    <Linkify componentDecorator={componentDecorator}>
                                      {item.description.length > 200 ? (
                                        <ShowMoreText
                                          lines={3}
                                          more="Read more"
                                          less="Read less"
                                          onClick={this.executeOnClick}
                                          expanded={false}
                                        >
                                          {item.description}
                                        </ShowMoreText>
                                      ) : (
                                        item.description
                                      )}
                                    </Linkify>
                                  </p>
                                </div>
                                {item.showStatus == 'waiting' ? (
                                  <div className="insight-buttons">
                                    <button
                                      type="button"
                                      onClick={() => this.handleClick('add', item._id)}
                                      className="btn btn-primary"
                                    >
                                      Add Insight
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => this.handleClick('decline', item._id)}
                                      className="btn btn-primary"
                                    >
                                      Decline Insight
                                    </button>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          ))
                        : ''}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <Modal show={this.state.deleteShowHide} className="addcollection-modal">
          <Modal.Body>
            <Link className="closeicon" onClick={() => this.deleteModalShowHide()}>
              <i className="fa fa-close"></i>
            </Link>
            <div className="dashboard-contactus-section">
              <div className="top-section text-center">
                <h3>Delete Post</h3>
                <p>Are you sure, You Want to delete this Post?</p>
              </div>
              <div className="d-flex new-btn">
                <div className="submit-buttons  new-collection  " style={{ marginRight: '15px' }}>
                  <Link type="button" className="" style={{ color: 'black' }} onClick={() => this.deletePost()}>
                    Yes
                  </Link>
                </div>
                <div className="submit-buttons  new-collection " style={{ marginLeft: '15px' }}>
                  <Link type="button" style={{ color: 'black' }} onClick={() => this.deleteModalShowHide()}>
                    No
                  </Link>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.editModalShowHide} className="messge-post-modal ">
          <Modal.Body>
            <div className="postsection">
              <a className="closeicon" onClick={() => this.editModalHandler()}>
                <i className="fa fa-close"></i>
              </a>
              <EditLearnPost data={this.state.listData} onSubmit={this.onEdit} />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default BThrivedetail;
