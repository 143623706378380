import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Footer from '../commonComponents/footer';
import Header from '../commonComponents/header';
class PrivacyPolicy extends Component {
  state = {};
  render() {
    return (
      <>
        <Header {...this.props} />
        <div className="guide mb-5">
          <div className="guidebody">
            <div className="rules">
              <center>
                <h1>
                  <b>B-Thrive Privacy Policy </b>
                </h1>
              </center>
              <ol>
                <li>
                  <b>Identification of the company</b>
                  <ol type="a">
                    <li>
                      B-thrive Community LTD (also defined as “we” “us” ”our”) is committed to protect your personal
                      data in accordance with the European General Data Protection Regulation (“GDPR”). The following
                      policy detailed our privacy practices, including how we collect, use and disclose your personal
                      information. This policy also provides your rights and our Data Privacy Officer contact.
                    </li>
                  </ol>
                </li>
                <li>
                  <b>Purpose of the treatment </b>
                  <ol type="a">
                    <li>
                      We collect your data to
                      <ol type="i">
                        <li>Provide you with you the services and activities requested, </li>
                        <li>
                          Communicate Information about our products, services, events and for other promotional
                          purposes,
                        </li>
                        <li>Respond to inquiries and other requests;</li>
                        <li>
                          Provide you with information that we think may interest you, including in regards to our
                          products and services
                        </li>
                        <li>Understand and analyze the usage trends and preferences of our users</li>
                        <li>Operate, improve and maintain our business, products and services</li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  <b>Type of collected data</b>
                  <ol type="a">
                    <li>
                      Depending on your contact with us, we collect different types of personal information. The minimum
                      personal data collected are:
                      <ol type="i">
                        <li>Connection data such as cookies,</li>
                        <li>IP addresses,</li>
                        <li>Referrer headers,</li>
                        <li>Data identifying your web browser and version, and web beacons and tags</li>
                      </ol>
                    </li>
                    <li>
                      To provide you with a better experience with us, we collect personal data when you request to use
                      our services. It might be in the following situations which are not exhaustive: when you create an
                      account profile with us, participate in our events, request to have more information regarding our
                      activities, buy our services.
                      <br />
                      The personal data includes:
                      <ol type="i">
                        <li>contact details including name, email, telephone number and shipping, billing address;</li>
                        <li>login and account information, including screen name, password and unique user ID;</li>
                        <li>personal details including gender, date of birth, location and purchase history;</li>
                        <li>your education level and professional situation, </li>
                        <li>personal preferences including your wish list, </li>
                        <li>payment or credit card information;</li>
                        <li>images, photos and videos; </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  <b>Legal basis</b>
                  <ul>
                    <li>
                      We are collecting your personal data based:
                      <ol type="a">
                        <li>On your consent voluntary provided,</li>
                        <li>On the contract’s performance, </li>
                        <li>On the company's legitimate interest as a business, and </li>
                        <li>On the compliance with the legal obligations of the company. </li>
                      </ol>
                    </li>
                    <li>
                      We shall seek your consent before collecting any additional Personal data and before using your
                      Personal data for a purpose which has not been notified to you (except where permitted or
                      authorised by law).
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Data recipients</b>
                  <ul>
                    <li>
                      The following may be recipients of all or part of your personal data:
                      <ol type="a">
                        <li>Founders</li>
                        <li>IT Developers </li>
                        <li>
                          In the course of our activities, we are required to call on providers or suppliers, to whom it
                          transmits or gives access to certain Personal data, within the limits of the fulfilment of
                          their mission to our profit. These intervene in the following areas:
                        </li>
                        <ul>
                          <li>IT (for example: hosting, maintenance, application support, supervision),</li>
                        </ul>
                        <li>
                          These "subcontractors" are required to respect the confidentiality of the Personal data
                          communicated to them and may use these Personal data only on our instructions to them.{' '}
                        </li>
                        <li>
                          Certain Personal data may also be disclosed or made available by us to third parties in form
                          of public authorities:
                        </li>
                        <ul>
                          <li>
                            to third parties, in the case of a legal obligation (public authorities, social and tax
                            administrations, bodies managing social protection schemes, etc.), or for their own needs,
                            requiring them to be transmitted or to give them access to some of your Personal data (for
                            example: customers and suppliers of the company with whom you may be in relation, including
                            your employer if you are not an employee of the company, training organizations).{' '}
                          </li>
                        </ul>
                      </ol>
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Retention period</b>
                  <ol type="a">
                    <li>
                      Your data will be retained for as long as it is necessary to fulfill the purpose for which that
                      personal information was collected, to perform your request and as permitted or required by law.
                      In case of legal actions or required by a supervision or an administrative authority, we will
                      retain your personal data.
                    </li>
                  </ol>
                </li>
                <li>
                  <b>Protection of your Personal Data:</b>
                  <ul>
                    <li>
                      To safeguard your personal data from unauthorised access, collection, use, disclosure, copying,
                      modification, disposal or similar risks, we have introduced appropriate measures such as:
                      <ol type="a">
                        <li>Passwords encryption</li>
                        <li>
                          Using a NPM package called Bcrypt. The bcrypt hashing function allows us to build a password
                          security platform that scales with computation power and always hashes every password
                        </li>
                        <li>All the data is hosted on AWS so has all the protection offered by AWS</li>
                      </ol>
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Rights of the data subjects</b>
                  <ol type="a">
                    <li>
                      Your personal information will be stored and processed in Ireland. As a result, your personal
                      information may be transferred to Countries outside your Country of residence, which may have
                      different data protection rules than in your Country. However, our practices regarding your
                      personal information will at all times continue to be governed by this Privacy Policy and we will
                      comply with the GDPR requirements of providing adequate protection for the transfer of personal
                      information from the EU/EEA to recipients in other countries. (clause with services providers)
                    </li>
                  </ol>
                </li>
                <li>
                  <b>Type of collected data</b>
                  <ol type="a">
                    <li>
                      In accordance with the European GDPR, as a data subjects, you can enforce the following rights:
                      <ol type="i">
                        <li>Information - access to a transparent information regarding the processing of your data</li>
                        <li>
                          Consent – where we are processing personal information with consent, withdraw your consent.
                        </li>
                        <li>
                          Access – ask if we are processing information and, if we are, request access to your personal
                          information. This enables you to receive a copy of the personal information we hold and
                          certain other information about you;
                        </li>
                        <li>
                          Correction – request that any incomplete or inaccurate personal information about you that we
                          hold be corrected;
                        </li>
                        <li>
                          Erasure – ask us to delete or remove your personal information in certain circumstances. There
                          are certain exceptions where we may refuse a request for erasure, for example, where the
                          personal information is required for compliance with law or in connection with legal claims;
                        </li>
                        <li>
                          Restriction – ask us to suspend the processing of your personal information, for example, to
                          establish its accuracy or the reason for processing it;
                        </li>
                        <li>Transfer – request the transfer of certain personal information to another party;</li>
                        <li>
                          Objection – challenge our processing of personal information based on a legitimate interest
                          (or those of a third party) or for direct marketing purposes. However, we may be entitled to
                          continue processing information in certain circumstances;
                        </li>
                        <li>
                          Automated decisions – contest any automated decision made where it has a legal or similar
                          significant effect and ask for it to be reconsidered;
                        </li>
                      </ol>
                    </li>
                    <li>
                      You also have a right to make a complaint with a data protection supervisory authority, in
                      particular in the Country where you normally reside, where we are based or where an alleged
                      infringement of data protection law has taken place.
                    </li>
                  </ol>
                </li>
                <li>
                  <b>Update of the Privacy Policy</b>
                  <br />
                  From time to time, and without prior warning, we may update, amend, supplement or otherwise revise
                  this privacy policy. If we make any significant changes, we will let you know but we recommend that
                  you regularly check this policy to make sure you have reviewed the latest version.
                </li>
                <li>
                  <b>DPO contact</b>
                  <ol type="a">
                    <li>
                      To request the exercise of your rights, to provide feedback or if you have questions or concerns
                      related to your personal data, please contact the B-Thrive team via{' '}
                      <u>contact@b-thrivecommunity.com</u>
                    </li>
                  </ol>
                </li>
              </ol>
              Others subjects:
              <ol>
                <li>Sensitive data</li>
                <li>
                  Payments
                  <br />
                  <a
                    href="https://digitalguardian.com/blog/what-pci-compliance#:~:text=The%20Payment%20Card%20Industry%20Data%20Security%20Standard%20(PCI%20DSS)%20is,information%20maintain%20a%20secure%20environment"
                    target="_blank"
                  >
                    (https://digitalguardian.com/blog/what-pci-compliance#:~:text=The%20Payment%20Card%20Industry%20Data%20Security%20Standard%20(PCI%20DSS)%20is,information%20maintain%20a%20secure%20environment.
                    )
                  </a>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default PrivacyPolicy;
