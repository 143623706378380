import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
class VisionMission extends Component {
  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide });
  }
  state = {};
  render() {
    return (
      <div className="vision-mission">
        <div className="container">
          <div className="main-section">
            <div className="row">
              <div className="col-md-6">
                <div className="leftpart">
                  <h3>B-THRIVE VISION</h3>
                  <p>
                    To provide a platform for all things African diaspora that relates to global cultural growth,
                    positive impact, and upward mobility within our culture.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="rightpart mt-2">
                  <img src="assets/images/about/vision.png" alt="Vision" />
                </div>
              </div>
            </div>
          </div>
          <div className="main-section">
            <div className="row">
              <div className="col-md-6">
                <div className="rightpart">
                  <img src="assets/images/about/mission.jpg" alt="mission" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="leftpart ml-5">
                  <h3>B-THRIVE MISSION</h3>
                  <p>
                    To use technology to connect the global Black community, enabling opportunities for socialization,
                    education, and the regeneration of our culture - in order to become our best selves.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VisionMission;
