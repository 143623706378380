import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Header from './../commonComponents/userheader';
import WOW from 'wowjs';
import Sidebar from './../commonComponents/sidebar';
import Events from './../commonComponents/events';
import { CircleArrow as ScrollUpButton } from 'react-scroll-up-button';
import Postdetail from '../commonComponents/postdetail';
import httpService from './../services/httpService';
import { toast } from 'react-toastify';
import moment from 'moment';
import parse from 'html-react-parser';
import Contactus from '../commonComponents/contactus';
import MediaQuery from 'react-responsive';

class PostDetailPage extends Component {
  state = {
    postDetail: '',
    user: '',
  };
  componentDidMount = async () => {
    let model;
    if (window.location.href.includes('/bookClubPost')) model = 'bookClubPost';
    if (model) this.postAPI();
    else this.postDetail();
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/myData`);
      if (data) {
        const user = data.user;
        this.setState({ user: user });

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }

    new WOW.WOW({
      live: false,
    }).init();
  };

  async postDetail() {
    try {
      const { data } = await httpService.get(
        `${process.env.REACT_APP_APIENDPOINT}/post-detail/${this.props.match.params.id}`,
      );
      if (data) {
        data.postDetail[0].creationDate = moment(data.postDetail[0].creationDate).format('MMM DD, YYYY h:mm A');
        data.postDetail[0].description = parse(data.postDetail[0].description);
        const postDetail = data.postDetail;
        this.setState({ postDetail: postDetail });

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }
  async postAPI(name, page, sort = '') {
    try {
      const { data } = await httpService.get(
        `${process.env.REACT_APP_APIENDPOINT}/bookClubPost/post?id=${this.props.match.params.id}&page=${1}`,
      );

      if (data) {
        data.postList[0].data.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('MMM DD, YYYY h:mm A');
          item.description = parse(item.description);
        });
        const listData = data.postList[0].data;
        this.setState({ postDetail: listData });

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  handleLike2 = (likeType, index, isChange) => {
    const postDetail = [...this.state.postDetail];

    if (isChange == 1) {
      postDetail[0].my_like[0].type = 1;
      if (postDetail[0].likeList.length) {
        var index2 = postDetail[0].likeList.findIndex((p) => p.createdBy._id === this.state.user._id);
        if (index2 > -1) {
          postDetail[0].likeList.splice(index2, 1);
        }
      }
    } else {
      if (postDetail[0].my_like.length) {
        postDetail[0].my_like[0].type = likeType;
      } else {
        postDetail[0].my_like.push({ type: likeType });
      }

      if (postDetail[0].likeList.length) {
        const found2 = postDetail[0].likeList.some((el) => el.createdBy._id === this.state.user._id);
        if (!found2) {
          postDetail[0].likeList.push({
            createdBy: { name: this.state.user.name, _id: this.state.user._id },
          });
        }
      } else {
        postDetail[0].likeList.push({
          createdBy: { name: this.state.user.name, _id: this.state.user._id },
        });
      }
    }
    this.setState({
      postDetail,
    });
    // this.setState({language: langValue});
  };

  render() {
    return (
      <>
        <div className="userhomepage">
          <div className="container-fluid menu-parts wow slideInDown">
            <Header user={this.state.user} {...this.props} />
          </div>
          <div className="container-fluid user-activity">
            <div className="container">
              <Contactus />
              <div className="row">
                <div className="col-2 ">
                  <Sidebar {...this.props} />
                </div>
                <div className="col-8 wow bounceIn">
                  <Postdetail
                    postDetail={this.state.postDetail}
                    {...this.props}
                    user={this.state.user}
                    {...this.props}
                    postComment={this.state.postComment}
                    {...this.props}
                    onLikeChange2={this.handleLike2}
                    onEdit={this.componentDidMount}
                  />
                </div>
                <div className="col-2 ">
                  <Events />
                </div>
              </div>
            </div>
          </div>
          <ScrollUpButton />
        </div>
        <MediaQuery query="(max-width:767px)">
          <div className="userhomepage1">
            <div className="container-fluid menu-parts wow slideInDown">
              <Header user={this.state.user} {...this.props} />
            </div>
            <div className=" user-activity">
              <div className="">
                <div className="row">
                  <div className="col-2 ">
                    <Sidebar {...this.props} />
                  </div>
                  <div className="col-12 wow bounceIn">
                    <Postdetail
                      postDetail={this.state.postDetail}
                      {...this.props}
                      user={this.state.user}
                      {...this.props}
                      postComment={this.state.postComment}
                      {...this.props}
                      onLikeChange2={this.handleLike2}
                      onEdit={this.componentDidMount}
                    />
                  </div>
                </div>
              </div>
            </div>
            <ScrollUpButton />
          </div>
        </MediaQuery>
      </>
    );
  }
}

export default PostDetailPage;
