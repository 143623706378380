import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { toast } from 'react-toastify';
import moment from 'moment';
import $ from 'jquery';
import CollectionListSection from '../../../../collectionsComponent/collectionsSection/collectionListSection';
import httpAdminService from '../../../../services/httpAdminService';

class Collection extends Component {
  state = {
    listData: '',
    page: Number(1),
  };
  totalData = '';
  pageData = [];

  async componentDidMount() {
    this.collectionApi(this.state.page);
    window.addEventListener('scroll', this.loadMore);
    document.body.addEventListener('touchmove', this.loadMore);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.loadMore);
    document.body.removeEventListener('touchmove', this.loadMore);
  }

  loader(type) {
    var shwload = document.getElementById('page_loader');
    shwload.style.display = type;
  }

  loadMore = () => {
    if (
      $(window).scrollTop() + $(window).height() == $(document).height() ||
      $(window).scrollTop() + window.innerHeight >= document.body.scrollHeight
    ) {
      if (this.pageData.length != this.totalData) {
        var page_count = this.state.page + Number(1);
        this.setState({
          page: page_count,
        });
        this.collectionApi(this.state.page);
      }
    }
  };

  async collectionApi(page) {
    //this.loader('block');
    try {
      const { data } = await httpAdminService.get(
        `${process.env.REACT_APP_APIENDPOINT}/admin/otherCollectionList/${this.props.user._id}/?page=${page}&type=my`,
      );

      if (data) {
        data.collectionList[0].data.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('MMM DD, YYYY h:mm A');
        });
        const listData = data.collectionList[0].data;
        this.totalData = data.collectionList[0].metadata[0] ? data.collectionList[0].metadata[0].total : '';
        this.pageData = [...this.pageData, ...listData];
        this.setState({ listData: this.pageData });
        return;
      }
    } catch (error) {
      this.loader('none');
      toast.error(error.message);
      return;
    }
  }

  render() {
    return (
      <div>
        <CollectionListSection listData={this.state.listData} {...this.props} />
      </div>
    );
  }
}

export default Collection;
