import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-tabs/style/react-tabs.css';
import { Tab, Tabs, TabList } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Modal } from 'react-bootstrap';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import WOW from 'wowjs';
import httpService from './../services/httpService';
import { toast } from 'react-toastify';
import * as postService from './../services/postServices';
import Form from '../macroComponents/form/form';
import Joi from 'joi-browser';
import moment from 'moment';
import ShowMoreText from 'react-show-more-text';
import ReadMoreAndLess from 'react-read-more-less';
import ReportModal from './reportModal';
import EditPost from './editPost';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import Prince1 from '../assets/images/icons/Prince1.png';
import Prince2 from '../assets/images/icons/Prince2.png';
import Prince3 from '../assets/images/icons/Prince3.png';
import Prince4 from '../assets/images/icons/Prince4.png';
import Heart1 from '../assets/images/icons/Heart1.png';
import Linkify from 'react-linkify';
import { RplFetcher } from '../lib/helpers';
class Postdetail extends Form {
  handleModalShowHide = (data = '') => {
    if (data) {
      this.setState({
        collectionData: '',
      });
      this.setState({
        collectionData: data,
      });
    }
    this.setState({ formData: {} });
    this.setState({ showHide1: !this.state.showHide1 });
  };
  state = {
    count: 0,
    commentList: '',
    description: '',
    collectionData: '',
    formData2: {
      type: '',
      postId: '',
    },
    formData1: {
      description: '',
      type: '',
      postId: '',
    },
    formData: {
      collection_name: '',
    },
    updateStatus: {
      type: '',
      _id: '',
    },
    errors: {},
    collectionListData: [],
    allLikeData: [],
    allLikeCount: '',
    postId: '',
    deleteId: '',
    likeshowHide: false,
    likeshowHide2: false,
    likeshowHide3: false,
    likeshowHide4: false,
    likeshowHide5: false,
    likeshowHide7: false,
  };
  schema = {
    collection_name: Joi.string().required(),
    //  selectedFile1: Joi.required(),
  };

  emojiModalShowHide(postId = '', type = '') {
    if (postId) {
      this.setState({ postId: postId });
      this.hitAllLikeDataApi(postId, (type = ''));
    }
    if (type) {
      if (type == 'all') type = '';
      this.hitAllLikeDataApi(this.state.postId, type);
    } else {
      this.setState({ emojishowHide: !this.state.emojishowHide });
    }
  }

  async hitAllLikeDataApi(postId, type) {
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/likeList?id=${postId}&type=${type}`);
      if (data) {
        const allLikeData = data.likeList;
        this.setState({ allLikeData: allLikeData });
        this.setState({ allLikeCount: data.likes });

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  doSubmit = async () => {
    const { error } = await postService.addPostToCollection(
      this.state.formData,
      this.state.collectionthis.props.postDetail[0]._id,
    );
    if (error) return;
    this.setState({ showHide1: !this.state.showHide1 });
    return;
  };

  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit1 = this.handleSubmit1.bind(this);
  }
  tabType = 'comment';
  selectedTab = 0;
  async componentDidMount() {
    var qs = require('qs');
    const type = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).type;
    if (type) {
      this.tabType = 'insight';
      this.selectedTab = 1;
      this.hitAPI('insight');
    } else this.commentList('comment');
    this.collectionList();
    new WOW.WOW({
      live: false,
    }).init();
  }

  likePopup = () => {
    this.setState({ likeshowHide: !this.state.likeshowHide });
    this.setState({
      likeshowHide2: false,
      likeshowHide3: false,
      likeshowHide4: false,
      likeshowHide5: false,
      likeshowHide7: false,
    });
    setTimeout(() => {
      this.setState({
        likeshowHide: false,
      });
    }, 6000);
  };
  likePopup2 = () => {
    this.setState({ likeshowHide2: !this.state.likeshowHide2 });
    this.setState({
      likeshowHide: false,
      likeshowHide3: false,
      likeshowHide4: false,
      likeshowHide5: false,
      likeshowHide7: false,
    });
    setTimeout(() => {
      this.setState({
        likeshowHide2: false,
      });
    }, 6000);
  };
  likePopup3 = () => {
    this.setState({ likeshowHide3: !this.state.likeshowHide3 });
    this.setState({
      likeshowHide2: false,
      likeshowHide: false,
      likeshowHide4: false,
      likeshowHide5: false,
      likeshowHide7: false,
    });
    setTimeout(() => {
      this.setState({
        likeshowHide3: false,
      });
    }, 6000);
  };
  likePopup4 = () => {
    this.setState({ likeshowHide4: !this.state.likeshowHide4 });
    this.setState({
      likeshowHide2: false,
      likeshowHide3: false,
      likeshowHide: false,
      likeshowHide5: false,
      likeshowHide7: false,
    });
    setTimeout(() => {
      this.setState({
        likeshowHide4: false,
      });
    }, 6000);
  };
  likePopup5 = () => {
    this.setState({ likeshowHide5: !this.state.likeshowHide5 });
    this.setState({
      likeshowHide2: false,
      likeshowHide3: false,
      likeshowHide4: false,
      likeshowHide: false,
      likeshowHide7: false,
    });
    setTimeout(() => {
      this.setState({
        likeshowHide5: false,
      });
    }, 6000);
  };
  likePopup7 = () => {
    this.setState({ likeshowHide7: !this.state.likeshowHide7 });
    this.setState({
      likeshowHide2: false,
      likeshowHide3: false,
      likeshowHide4: false,
      likeshowHide5: false,
      likeshowHide: false,
    });
    setTimeout(() => {
      this.setState({
        likeshowHide7: false,
      });
    }, 6000);
  };

  async collectionList() {
    try {
      let page = 1;
      let collectionListData = [];
      let count = 1;
      while (count) {
        let { data } = await httpService.get(
          `${process.env.REACT_APP_APIENDPOINT}/collectionList?type=my&page=${page}`,
        );
        if (data.collectionList[0].data.length) {
          collectionListData = [...collectionListData, ...data.collectionList[0].data];
          page++;
        } else count = 0;
      }
      collectionListData?.splice(0, 0, {
        _id: 'select',
        name: 'Select Option',
      });
      this.setState({ collectionListData: collectionListData });
      return;
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  async commentList(name) {
    this.setState({
      count: 0,
    });
    let model;
    if (window.location.href.includes('/bookClubPost')) model = 'bookClubPost';
    try {
      const { data } = await httpService.get(
        `${process.env.REACT_APP_APIENDPOINT}/comment/${this.props.match.params.id}/${name}?model=${model}`,
      );
      if (data) {
        data.commentList.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('h:mm a');
        });
        const commentList = data.commentList;
        if (this.props.user) {
          if (!window.location.href.includes('admin') && this.props.user.usersBlocked) {
            for (var index = 0; index < commentList.length; index++) {
              if (
                this.props.user.usersBlocked.includes(commentList[index].createdBy._id) ||
                commentList[index].createdBy.usersBlocked.includes(this.props.user._id)
              ) {
                commentList.splice(index, 1);
                index--;
              }
            }
          }
        }
        this.setState({ commentList: commentList });

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  hitAPI(tabName) {
    if (this.props.postDetail[0]) {
      if (this.tabType != 'insight' || this.props.postDetail[0].createdBy._id != this.props.user._id)
        document.getElementById('output').value = '';
    }
    this.tabType = tabName;
    if (tabName == 'comment') {
      this.selectedTab = 0;
      this.commentList('comment');
    } else {
      this.selectedTab = 1;
      this.commentList('insight');
    }
  }

  async handleClick(type, commentId) {
    this.state.updateStatus.type = type;
    this.state.updateStatus._id = commentId;

    const { data, error } = await postService.updateCommentStatus(this.state.updateStatus);
    if (error) return;
    else {
      this.hitAPI(this.tabType);

      return;
    }
  }

  handleSubmit1 = async (e) => {
    if (this.state.count) return;

    e.preventDefault();
    if (this.state.description == '') {
      toast.error('Please enter description');

      return;
    }

    // alert(this.props.match.params.id)

    this.state.formData1.type = this.tabType;
    this.state.formData1.description = this.state.description;
    this.state.formData1.postId = this.props.match.params.id;

    this.setState({
      count: this.state.count + 1,
    });
    const { data, error } = await postService.createComment(this.state.formData1);

    if (error) return;
    else {
      this.commentList(this.tabType);
      this.setState({
        description: '',
        formData1: {
          description: '',
        },
      });
      document.getElementById('output').value = '';
      // this.state.description = '';

      return;
    }
  };

  handleInputChange(event) {
    const target = event.target;
    const value = event.target.value;
    const name = event.target.name;
    this.setState({
      [name]: value,
    });
  }

  handleModalShowHide1() {
    this.setState({ showHide: !this.state.showHide });
  }
  editModalHandler() {
    this.setState({ editModalShowHide: !this.state.editModalShowHide });
  }
  onEdit = () => {
    this.editModalHandler();
    this.props.onEdit();
  };
  async userBlockChangeApi(id) {
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/blockUser/${id}`);

      if (data) {
        toast.success(data.message);
        return await this.props.history.push('/user-home');
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  async hitLikeApi(postId, likeType, index) {
    this.state.formData2.type = likeType;
    this.state.formData2.postId = postId;

    const { data, error } = await postService.createLike(this.state.formData2);
    if (error) {
      // this.loader('none');
      return;
    } else {
      if (data.isLike == 1) {
        this.props.onLikeChange2(likeType, index, 1);
      } else {
        this.props.onLikeChange2(likeType, index, 0);
      }
      // alert('Liked successfully')

      return;
    }
  }
  copyToClipboard(text) {
    var dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    toast.success('Link Copied');
  }

  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide });
  }
  contactModalShowHide = () => {
    this.setState({ contactshowHide: !this.state.contactshowHide });
  };
  deleteModalShowHide = (id) => {
    this.setState({ deleteId: id });
    this.setState({ deleteShowHide: !this.state.deleteShowHide });
  };
  deletePost = async () => {
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/deletePost/${this.state.deleteId}`);

      if (data) {
        toast.success(data.message);
        return await this.props.history.push('/user-home');
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  };
  nFormatter = (num) => {
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item ? (num / item.value).toFixed(1).replace(rx, '$1') + item.symbol : '0';
  };
  render() {
    if (!this.props.postDetail[0]) return null;
    let model;
    if (window.location.href.includes('/bookClubPost')) model = 'bookClubPost';
    const componentDecorator = (href, text, key) => (
      <a href={href} key={key} target="_blank">
        {text}
      </a>
    );
    return (
      <div>
        <div className="back-post">
          <div className="main-section">
            <h3>
              <Link onClick={() => this.props.history.goBack()}>
                <i className="fa fa-angle-double-left"></i>
              </Link>{' '}
              Viewing {this.props.postDetail[0].type == 'post' ? 'Post' : 'Question'}{' '}
            </h3>
          </div>
        </div>

        <div className="post-display-section ">
          <div className="main-section">
            <div className="post-block mb-3 wow fadeInUp">
              <div className="post-toppart">
                <div className="left-part">
                  <Link
                    className="nav-link"
                    to={
                      this.props.user._id != this.props.postDetail[0].createdBy._id
                        ? `/blogger-profile/${this.props.postDetail[0].createdBy._id}`
                        : `/user-profile`
                    }
                  >
                    <img
                      className={this.props.postDetail[0].createdBy.isBlogger ? 'pic2' : ''}
                      src={
                        this.props.postDetail[0].createdBy.profilePicture
                          ? this.props.postDetail[0].createdBy.profilePicture
                          : '/../assets/images/user/01.jpg'
                      }
                      alt="User Profile"
                    />

                    <Link
                      to={
                        this.props.user._id != this.props.postDetail[0].createdBy._id
                          ? `/blogger-profile/${this.props.postDetail[0].createdBy._id}`
                          : `/user-profile`
                      }
                    >
                      <p style={{ marginTop: '-20px' }}>
                        {this.props.postDetail[0].createdBy.name}
                        {this.props.postDetail[0].createdBy.isBlogger ? (
                          <span style={{ fontSize: '13px' }}>
                            {' '}
                            <i className="fa fa-circle ml-1 mr-2" style={{ fontSize: '8px', color: 'black' }}></i>
                            Blogger
                          </span>
                        ) : null}
                      </p>{' '}
                    </Link>
                    <span>Posted : {this.props.postDetail[0].creationDate}</span>
                  </Link>
                </div>
                <div className="right-part">
                  <Link className="dotsbar">
                    <i className="fa fa-ellipsis-v"></i>
                    <div className="post-dot-option">
                      <ul>
                        <li>
                          <Link onClick={() => this.copyToClipboard(`${window.location.href}`)}>
                            <i className="fa fa-link"></i> Copy Link
                          </Link>
                        </li>
                        {this.props.postDetail[0].type == 'post' ? (
                          <li>
                            <Link onClick={() => this.handleModalShowHide(this.props.postDetail[0])}>
                              <i className="fa fa-heart"></i> Save to Collection
                            </Link>
                          </li>
                        ) : (
                          ''
                        )}
                        {this.props.user._id != this.props.postDetail[0].createdBy._id && (
                          <li>
                            <Link onClick={() => this.contactModalShowHide()}>
                              <i className="fa fa-file"></i> Report Post
                            </Link>
                          </li>
                        )}
                        <li>
                          <Link
                            to={
                              this.props.user._id != this.props.postDetail[0].createdBy._id
                                ? `/blogger-profile/${this.props.postDetail[0].createdBy._id}`
                                : `/user-profile`
                            }
                          >
                            <i className="fa fa-user"></i> View Author
                          </Link>
                        </li>
                        {this.props.user._id != this.props.postDetail[0].createdBy._id && (
                          <li>
                            <Link
                              onClick={() => this.userBlockChangeApi(this.props.postDetail[0].createdBy._id)}
                              style={{ color: '#FF7E55' }}
                            >
                              <i className="fa fa-ban" style={{ color: '#FF7E55' }}></i> Block This User
                            </Link>
                          </li>
                        )}
                        {this.props.user._id == this.props.postDetail[0].createdBy._id && (
                          <li style={{ textTransform: 'capitalize' }}>
                            <Link onClick={() => this.editModalHandler()}>
                              <i className="fa fa-edit"></i> Edit {this.props.postDetail[0].type}
                            </Link>
                          </li>
                        )}
                        {this.props.user._id == this.props.postDetail[0].createdBy._id && (
                          <li style={{ textTransform: 'capitalize' }}>
                            <Link onClick={() => this.deleteModalShowHide(this.props.postDetail[0]._id)}>
                              <i className="fa fa-trash "></i> Delete {this.props.postDetail[0].type}
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                  </Link>
                  {this.props.postDetail[0].web_link ? (
                    <a
                      target="_blank"
                      className="source-globe"
                      href={
                        this.props.postDetail[0].web_link.includes('https://www.') ||
                        this.props.postDetail[0].web_link.includes('http')
                          ? this.props.postDetail[0].web_link
                          : `https://www.${this.props.postDetail[0].web_link.replace('www.', '')}`
                      }
                    >
                      <i className="fa fa-globe"></i> Source
                    </a>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="messge-box">
                <p>
                  <ShowMoreText
                    /* Default options */
                    lines={3}
                    more="Read more"
                    less="Read less"
                    onClick={this.executeOnClick}
                    expanded={true}
                  >
                    {this.props.postDetail[0].description}
                  </ShowMoreText>
                </p>
                {this.props.postDetail[0].link ? (
                  <>
                    <LinkPreview
                      fetcher={RplFetcher}
                      url={
                        this.props.postDetail[0].link.includes('https://www.') ||
                        this.props.postDetail[0].link.includes('http')
                          ? this.props.postDetail[0].link
                          : `https://www.${this.props.postDetail[0].link.replace('www.', '')}`
                      }
                      width="680px"
                      height="350px"
                      imageHeight="320px"
                      descriptionLength="80"
                      className="link-preview ml-1"
                    />
                    <a
                      href={
                        this.props.postDetail[0].link.includes('https://www.') ||
                        this.props.postDetail[0].link.includes('http')
                          ? this.props.postDetail[0].link
                          : `https://www.${this.props.postDetail[0].link.replace('www.', '')}`
                      }
                      target="_blank"
                      className="postlinks"
                    >
                      <i className="fa fa-link"></i> {this.props.postDetail[0].link}
                    </a>
                  </>
                ) : (
                  ''
                )}
                {this.props.postDetail[0].web_link ? (
                  <>
                    <LinkPreview
                      fetcher={RplFetcher}
                      url={
                        this.props.postDetail[0].web_link.includes('https://www.') ||
                        this.props.postDetail[0].web_link.includes('http')
                          ? this.props.postDetail[0].web_link
                          : `https://www.${this.props.postDetail[0].web_link.replace('www.', '')}`
                      }
                      width="680px"
                      height="350px"
                      imageHeight="320px"
                      descriptionLength="80"
                      className="link-preview ml-1"
                    />
                    <a
                      href={
                        this.props.postDetail[0].web_link.includes('https://www.') ||
                        this.props.postDetail[0].web_link.includes('http')
                          ? this.props.postDetail[0].web_link
                          : `https://www.${this.props.postDetail[0].web_link.replace('www.', '')}`
                      }
                      target="_blank"
                      className="postlinks mt-0 mb-1"
                    >
                      <i className="fa fa-globe"></i> {this.props.postDetail[0].web_link}
                    </a>
                  </>
                ) : (
                  ''
                )}
              </div>
              {this.props.postDetail[0].images.length || this.props.postDetail[0].videos.length ? (
                <div className="image-post">
                  {this.props.postDetail[0].images.length ? (
                    <img src={this.props.postDetail[0].images[0]} alt="Event" />
                  ) : this.props.postDetail[0].videos.length ? (
                    <video controls height="400px" width="100%">
                      <source src={this.props.postDetail[0].videos[0]} type="video/mp4"></source>
                    </video>
                  ) : null}
                  <Link onClick={() => this.handleModalShowHide1()} className="view-all-image">
                    {' '}
                    {this.props.postDetail[0].images.length + this.props.postDetail[0].videos.length > 1 ? (
                      <p> View all </p>
                    ) : (
                      <p style={{ padding: '27px' }}> View </p>
                    )}
                  </Link>
                </div>
              ) : (
                ''
              )}

              <Modal show={this.state.showHide} className="allimage-modal ">
                <Modal.Body>
                  <div className="allimagepopup text-center">
                    <a className="closeicon" onClick={() => this.handleModalShowHide1()}>
                      <i className="fa fa-close"></i>
                    </a>
                    <div className="slider-part ">
                      <Carousel infiniteLoop useKeyboardArrows autoPlay>
                        {this.props.postDetail[0].images.map((name, index) => (
                          <div className="imageviews" key={index}>
                            <img src={name} alt="Slider 1" />
                          </div>
                        ))}
                        {this.props.postDetail[0].videos.map((name, index) => (
                          <div key={index}>
                            <video controls>
                              <source src={name} type="video/mp4" />
                            </video>
                          </div>
                        ))}
                      </Carousel>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              <Modal show={this.state.showHide1} className="collection-modal">
                <Modal.Body>
                  <Link className="closeicon" onClick={() => this.handleModalShowHide()}>
                    <i className="fa fa-close"></i>
                  </Link>
                  <div className="collection">
                    <h4 className="text-center">Add Post to Collections </h4>
                    <p className="text-center">Save this post to collection you can view later.</p>
                    <div className="messege-bos-parts">
                      <div className="post-block mb-3 wow fadeInUp">
                        <div className="post-toppart">
                          <div className="left-part">
                            <Link className="nav-link">
                              <img
                                className={this.state.collectionData?.createdBy?.isBlogger ? 'blogger' : ''}
                                src={
                                  this.state.collectionData
                                    ? this.state.collectionData.createdBy.profilePicture
                                      ? this.state.collectionData.createdBy.profilePicture
                                      : '../assets/images/user/01.jpg'
                                    : ''
                                }
                                alt="User Profile"
                              />
                              <p>
                                {this.state.collectionData ? this.state.collectionData.createdBy.name : ''}
                                {this.state.collectionData?.createdBy?.isBlogger ? (
                                  <span style={{ fontSize: '13px' }}>
                                    {' '}
                                    <i
                                      className="fa fa-circle ml-1 mr-2"
                                      style={{
                                        fontSize: '8px',
                                        color: 'black',
                                      }}
                                    ></i>
                                    Blogger
                                  </span>
                                ) : null}
                              </p>{' '}
                              <span>
                                Posted : {this.state.collectionData ? this.state.collectionData.creationDate : ''}
                              </span>
                            </Link>
                          </div>
                        </div>
                        <div className="messge-box">
                          <Link>
                            <p>
                              {this.state.collectionData ? (
                                <ShowMoreText
                                  /* Default options */
                                  lines={3}
                                  more="Read more"
                                  less="Read less"
                                  onClick={this.executeOnClick}
                                  expanded={false}
                                >
                                  {this.state.collectionData.description}
                                </ShowMoreText>
                              ) : (
                                ''
                              )}
                            </p>
                          </Link>
                        </div>
                      </div>
                    </div>
                    {this.state.collectionListData.length > 1 ? (
                      <div className="selectpart">
                        {this.renderSelect('collection_name', 'Select Collection', this.state.collectionListData)}
                      </div>
                    ) : null}
                    <div className="d-flex justify-content-center">
                      <div className="submit-buttons  new-collection ">
                        <Link target="_blank" to={'/collections'} style={{ color: 'black' }}>
                          Create New Collection
                        </Link>
                      </div>
                      {this.state.collectionListData.length > 1 ? (
                        <div className="submit-buttons">
                          {this.renderButton('Add to collection', this.handleSubmit)}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              <Modal show={this.state.emojishowHide} className="emoji-modal ">
                <Modal.Body>
                  <Link className="closeicon" onClick={() => this.emojiModalShowHide()}>
                    <i className="fa fa-close"></i>
                  </Link>
                  <div className="collection">
                    <h4 className="">Reactions </h4>
                    <div className="reaction-parts">
                      <Tabs>
                        <TabList id="style-1">
                          <Tab onClick={() => this.emojiModalShowHide('', 'all')}>
                            All {this.nFormatter(this.state.allLikeCount.total_likes)}
                          </Tab>
                          <Tab onClick={() => this.emojiModalShowHide('', 11)}>
                            <span>
                              {' '}
                              <img className="emoji-list-icon2" src="/../assets/images/icons/Thumbsup1.png" />
                            </span>{' '}
                            {this.nFormatter(this.state.allLikeCount.total_likes_1)}
                          </Tab>
                          <Tab onClick={() => this.emojiModalShowHide('', 21)}>
                            <span>
                              {' '}
                              <img className="emoji-list-icon2" src="/../assets/images/icons/Handraise1.png" />{' '}
                            </span>{' '}
                            {this.nFormatter(this.state.allLikeCount.total_likes_2)}
                          </Tab>
                          <Tab onClick={() => this.emojiModalShowHide('', 31)}>
                            <span>
                              {' '}
                              <img className="emoji-list-icon2" src="/../assets/images/icons/Raised-fist1.png" />{' '}
                            </span>{' '}
                            {this.nFormatter(this.state.allLikeCount.total_likes_3)}
                          </Tab>
                          <Tab onClick={() => this.emojiModalShowHide('', 41)}>
                            <span>
                              {' '}
                              <img className="emoji-list-icon2" src="/../assets/images/icons/Folded-hand1.png" />{' '}
                            </span>{' '}
                            {this.nFormatter(this.state.allLikeCount.total_likes_4)}
                          </Tab>
                          <Tab onClick={() => this.emojiModalShowHide('', 5)}>
                            <span>
                              <img className="emoji-list-icon2" src={Heart1} />
                            </span>{' '}
                            {this.nFormatter(this.state.allLikeCount.total_likes_5)}
                          </Tab>
                          <Tab onClick={() => this.emojiModalShowHide('', 61)}>
                            <span>
                              {' '}
                              <img className="emoji-list-icon2" src="/../assets/images/icons/Princess1.png" />{' '}
                            </span>{' '}
                            {this.nFormatter(this.state.allLikeCount.total_likes_6)}
                          </Tab>
                          <Tab onClick={() => this.emojiModalShowHide('', 71)}>
                            <span>
                              {' '}
                              <img className="emoji-list-icon2" src={Prince1} />{' '}
                            </span>{' '}
                            {this.nFormatter(this.state.allLikeCount.total_likes_7)}
                          </Tab>
                        </TabList>
                      </Tabs>
                      <div className="likeuser-list">
                        <ul>
                          {this.state.allLikeData.length
                            ? this.state.allLikeData.map((data, index) => {
                                return (
                                  <li key={index}>
                                    <Link
                                      to={
                                        this.props.user._id != data.createdBy._id
                                          ? `/blogger-profile/${data.createdBy._id}`
                                          : `/user-profile`
                                      }
                                    >
                                      <h5>
                                        {data.createdBy.name}
                                        {data.createdBy.isBlogger ? (
                                          <span style={{ fontSize: '13px' }}>
                                            {' '}
                                            <i
                                              className="fa fa-circle ml-1 mr-2"
                                              style={{
                                                fontSize: '8px',
                                                color: 'black',
                                              }}
                                            ></i>
                                            Blogger
                                          </span>
                                        ) : null}
                                      </h5>
                                      {data.createdBy.location ? (
                                        <span>{data.createdBy.location}</span>
                                      ) : (
                                        <span style={{ color: 'white' }}>Noida</span>
                                      )}
                                      <img
                                        className={data.createdBy.isBlogger ? 'pic2' : ''}
                                        src={
                                          data.createdBy.profilePicture
                                            ? data.createdBy.profilePicture
                                            : '/../assets/images/user/01.jpg'
                                        }
                                        alt="User Profile"
                                      />
                                    </Link>
                                  </li>
                                );
                              })
                            : ''}
                        </ul>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              <div className="bottom-part">
                <div className="left-part">
                  <ul>
                    {this.props.postDetail[0].interest.map((tagName, index) => (
                      <li key={index}>{tagName.name}</li>
                    ))}
                  </ul>
                </div>
                <div className="right-part">
                  <div className="liked-user mt-5 mb-5">
                    <Link onClick={() => this.emojiModalShowHide(this.props.postDetail[0]._id)}>
                      {this.props.postDetail[0].likeList.length
                        ? `Liked by ${
                            this.props.postDetail[0].likeList[this.props.postDetail[0].likeList.length - 1].createdBy
                              .name
                          } ${this.props.postDetail[0].likeList.length > 1 ? `and others` : ''} `
                        : ''}
                    </Link>
                  </div>
                  <div className="reactionpart">
                    <Link className="smile-icon" onClick={() => this.emojiModalShowHide(this.props.postDetail[0]._id)}>
                      {this.props.postDetail[0].my_like.length ? (
                        this.props.postDetail[0].my_like[0].type == '11' ? (
                          <span>
                            {' '}
                            <img className="emoji-list-icon3" src="/../assets/images/icons/Thumbsup1.png" />
                          </span>
                        ) : this.props.postDetail[0].my_like[0].type == '12' ? (
                          <span>
                            {' '}
                            <img className="emoji-list-icon3" src="/../assets/images/icons/Thumbsup2.png" />
                          </span>
                        ) : this.props.postDetail[0].my_like[0].type == '13' ? (
                          <span>
                            {' '}
                            <img className="emoji-list-icon3" src="/../assets/images/icons/Thumbsup3.png" />
                          </span>
                        ) : this.props.postDetail[0].my_like[0].type == '14' ? (
                          <span>
                            {' '}
                            <img className="emoji-list-icon3" src="/../assets/images/icons/Thumbsup4.png" />
                          </span>
                        ) : this.props.postDetail[0].my_like[0].type == '21' ? (
                          <span>
                            {' '}
                            <img className="emoji-list-icon3" src="/../assets/images/icons/Handraise1.png" />
                          </span>
                        ) : this.props.postDetail[0].my_like[0].type == '22' ? (
                          <span>
                            {' '}
                            <img className="emoji-list-icon3" src="/../assets/images/icons/Handraise2.png" />
                          </span>
                        ) : this.props.postDetail[0].my_like[0].type == '23' ? (
                          <span>
                            {' '}
                            <img className="emoji-list-icon3" src="/../assets/images/icons/Handraise3.png" />
                          </span>
                        ) : this.props.postDetail[0].my_like[0].type == '24' ? (
                          <span>
                            {' '}
                            <img className="emoji-list-icon3" src="/../assets/images/icons/Handraise4.png" />
                          </span>
                        ) : this.props.postDetail[0].my_like[0].type == '31' ? (
                          <span>
                            {' '}
                            <img className="emoji-list-icon3" src="/../assets/images/icons/Raised-fist1.png" />
                          </span>
                        ) : this.props.postDetail[0].my_like[0].type == '32' ? (
                          <span>
                            {' '}
                            <img className="emoji-list-icon3" src="/../assets/images/icons/Raised-fist2.png" />
                          </span>
                        ) : this.props.postDetail[0].my_like[0].type == '33' ? (
                          <span>
                            {' '}
                            <img className="emoji-list-icon3" src="/../assets/images/icons/Raised-fist3.png" />
                          </span>
                        ) : this.props.postDetail[0].my_like[0].type == '34' ? (
                          <span>
                            {' '}
                            <img className="emoji-list-icon3" src="/../assets/images/icons/Raised-fist4.png" />
                          </span>
                        ) : this.props.postDetail[0].my_like[0].type == '41' ? (
                          <span>
                            {' '}
                            <img className="emoji-list-icon3" src="/../assets/images/icons/Folded-hand1.png" />
                          </span>
                        ) : this.props.postDetail[0].my_like[0].type == '42' ? (
                          <span>
                            {' '}
                            <img className="emoji-list-icon3" src="/../assets/images/icons/Folded-hand2.png" />
                          </span>
                        ) : this.props.postDetail[0].my_like[0].type == '43' ? (
                          <span>
                            {' '}
                            <img className="emoji-list-icon3" src="/../assets/images/icons/Folded-hand3.png" />
                          </span>
                        ) : this.props.postDetail[0].my_like[0].type == '44' ? (
                          <span>
                            {' '}
                            <img className="emoji-list-icon3" src="/../assets/images/icons/Folded-hand4.png" />
                          </span>
                        ) : this.props.postDetail[0].my_like[0].type == '61' ? (
                          <span>
                            {' '}
                            <img className="emoji-list-icon3" src="/../assets/images/icons/Princess1.png" />
                          </span>
                        ) : this.props.postDetail[0].my_like[0].type == '62' ? (
                          <span>
                            {' '}
                            <img className="emoji-list-icon3" src="/../assets/images/icons/Princess2.png" />
                          </span>
                        ) : this.props.postDetail[0].my_like[0].type == '63' ? (
                          <span>
                            {' '}
                            <img className="emoji-list-icon3" src="/../assets/images/icons/Princess3.png" />
                          </span>
                        ) : this.props.postDetail[0].my_like[0].type == '64' ? (
                          <span>
                            {' '}
                            <img className="emoji-list-icon3" src="/../assets/images/icons/Princess4.png" />
                          </span>
                        ) : this.props.postDetail[0].my_like[0].type == '71' ? (
                          <span>
                            {' '}
                            <img className="emoji-list-icon3" src={Prince1} />
                          </span>
                        ) : this.props.postDetail[0].my_like[0].type == '72' ? (
                          <span>
                            {' '}
                            <img className="emoji-list-icon3" src={Prince2} />
                          </span>
                        ) : this.props.postDetail[0].my_like[0].type == '73' ? (
                          <span>
                            {' '}
                            <img className="emoji-list-icon3" src={Prince3} />
                          </span>
                        ) : this.props.postDetail[0].my_like[0].type == '74' ? (
                          <span>
                            {' '}
                            <img className="emoji-list-icon3" src={Prince4} />
                          </span>
                        ) : (
                          <span>
                            {' '}
                            <img className="emoji-list-icon3" style={{ fontSize: '25px!important' }} src={Heart1} />
                          </span>
                        )
                      ) : (
                        <span>
                          {' '}
                          <img style={{ width: '21px' }} src="/../assets/images/icons/Thumbsup1.png" />
                        </span>
                      )}{' '}
                    </Link>

                    <div className="emoji-list">
                      <ul>
                        <li className="dropUp" onClick={() => this.likePopup()}>
                          <Link>
                            <img className="emoji-list-icon" src="/../assets/images/icons/Thumbsup1.png" />

                            <div className={this.state.likeshowHide ? 'emoji-list_active' : 'emoji-list2'}>
                              <ul>
                                <li className="dropUp">
                                  <div className="d-flex">
                                    <Link
                                      onClick={() => this.hitLikeApi(this.props.postDetail[0]._id, 11)}
                                      className=" ml-5"
                                    >
                                      <img className="emoji-list-icon " src="/../assets/images/icons/Thumbsup1.png" />
                                    </Link>
                                    <Link
                                      onClick={() => this.hitLikeApi(this.props.postDetail[0]._id, 12)}
                                      className="mr-3 ml-3"
                                    >
                                      <img className="emoji-list-icon" src="/../assets/images/icons/Thumbsup2.png" />
                                    </Link>
                                    <Link
                                      onClick={() => this.hitLikeApi(this.props.postDetail[0]._id, 13)}
                                      className="mr-3"
                                    >
                                      <img className="emoji-list-icon" src="/../assets/images/icons/Thumbsup3.png" />
                                    </Link>
                                    <Link
                                      onClick={() => this.hitLikeApi(this.props.postDetail[0]._id, 14)}
                                      className=""
                                    >
                                      <img className="emoji-list-icon" src="/../assets/images/icons/Thumbsup4.png" />
                                    </Link>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </Link>
                        </li>

                        <li className="dropUp3" onClick={() => this.likePopup2()}>
                          <Link>
                            <img className="emoji-list-icon" src="/../assets/images/icons/Handraise1.png" />

                            <div className={this.state.likeshowHide2 ? 'emoji-list3_active' : 'emoji-list3'}>
                              <ul>
                                <li className="dropUp">
                                  <div className="d-flex">
                                    <Link
                                      onClick={() => this.hitLikeApi(this.props.postDetail[0]._id, 21)}
                                      className=" ml-5"
                                    >
                                      <img className="emoji-list-icon" src="/../assets/images/icons/Handraise1.png" />
                                    </Link>
                                    <Link
                                      onClick={() => this.hitLikeApi(this.props.postDetail[0]._id, 22)}
                                      className="mr-3 ml-3"
                                    >
                                      <img className="emoji-list-icon" src="/../assets/images/icons/Handraise2.png" />
                                    </Link>
                                    <Link
                                      onClick={() => this.hitLikeApi(this.props.postDetail[0]._id, 23)}
                                      className="mr-3"
                                    >
                                      <img className="emoji-list-icon" src="/../assets/images/icons/Handraise3.png" />
                                    </Link>
                                    <Link
                                      onClick={() => this.hitLikeApi(this.props.postDetail[0]._id, 24)}
                                      className=""
                                    >
                                      <img className="emoji-list-icon" src="/../assets/images/icons/Handraise4.png" />
                                    </Link>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </Link>
                        </li>
                        <li className="dropUp4" onClick={() => this.likePopup3()}>
                          <Link>
                            <img className="emoji-list-icon" src="/../assets/images/icons/Raised-fist1.png" />
                            <div className={this.state.likeshowHide3 ? 'emoji-list4_active' : 'emoji-list4'}>
                              <ul>
                                <li className="dropUp">
                                  <div className="d-flex">
                                    <Link
                                      onClick={() => this.hitLikeApi(this.props.postDetail[0]._id, 31)}
                                      className=" ml-5"
                                    >
                                      <img className="emoji-list-icon" src="/../assets/images/icons/Raised-fist1.png" />
                                    </Link>
                                    <Link
                                      onClick={() => this.hitLikeApi(this.props.postDetail[0]._id, 32)}
                                      className="mr-3 ml-3"
                                    >
                                      <img className="emoji-list-icon" src="/../assets/images/icons/Raised-fist2.png" />
                                    </Link>
                                    <Link
                                      onClick={() => this.hitLikeApi(this.props.postDetail[0]._id, 33)}
                                      className="mr-3"
                                    >
                                      <img className="emoji-list-icon" src="/../assets/images/icons/Raised-fist3.png" />
                                    </Link>
                                    <Link
                                      onClick={() => this.hitLikeApi(this.props.postDetail[0]._id, 34)}
                                      className=""
                                    >
                                      <img className="emoji-list-icon" src="/../assets/images/icons/Raised-fist4.png" />
                                    </Link>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </Link>
                        </li>
                        <li className="dropUp5" onClick={() => this.likePopup4()}>
                          <Link>
                            <img className="emoji-list-icon" src="/../assets/images/icons/Folded-hand1.png" />

                            <div className={this.state.likeshowHide4 ? 'emoji-list5_active' : 'emoji-list5'}>
                              <ul>
                                <li className="dropUp">
                                  <div className="d-flex">
                                    <Link
                                      onClick={() => this.hitLikeApi(this.props.postDetail[0]._id, 41)}
                                      className=" ml-5"
                                    >
                                      <img className="emoji-list-icon" src="/../assets/images/icons/Folded-hand1.png" />
                                    </Link>
                                    <Link
                                      onClick={() => this.hitLikeApi(this.props.postDetail[0]._id, 42)}
                                      className="mr-3 ml-3"
                                    >
                                      <img className="emoji-list-icon" src="/../assets/images/icons/Folded-hand2.png" />
                                    </Link>

                                    <Link
                                      onClick={() => this.hitLikeApi(this.props.postDetail[0]._id, 43)}
                                      className="mr-3"
                                    >
                                      <img className="emoji-list-icon" src="/../assets/images/icons/Folded-hand3.png" />
                                    </Link>
                                    <Link
                                      onClick={() => this.hitLikeApi(this.props.postDetail[0]._id, 44)}
                                      className=""
                                    >
                                      <img className="emoji-list-icon" src="/../assets/images/icons/Folded-hand4.png" />
                                    </Link>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </Link>
                        </li>
                        <li className="dropUp">
                          <Link onClick={() => this.hitLikeApi(this.props.postDetail[0]._id, 5)}>
                            <img
                              className="emoji-list-icon"
                              style={{
                                fontSize: '25px!important',
                              }}
                              src={Heart1}
                            />
                          </Link>
                        </li>
                        <li className="dropUp6" onClick={() => this.likePopup5()}>
                          <Link>
                            <img className="emoji-list-icon" src="/../assets/images/icons/Princess1.png" />

                            <div className={this.state.likeshowHide5 ? 'emoji-list6_active' : 'emoji-list6'}>
                              <ul>
                                <li className="dropUp">
                                  <div className="d-flex">
                                    <Link
                                      onClick={() => this.hitLikeApi(this.props.postDetail[0]._id, 61)}
                                      className=" ml-5"
                                    >
                                      <img className="emoji-list-icon" src="/../assets/images/icons/Princess1.png" />
                                    </Link>
                                    <Link
                                      onClick={() => this.hitLikeApi(this.props.postDetail[0]._id, 62)}
                                      className="mr-3 ml-3"
                                    >
                                      <img className="emoji-list-icon" src="/../assets/images/icons/Princess2.png" />
                                    </Link>
                                    <Link
                                      onClick={() => this.hitLikeApi(this.props.postDetail[0]._id, 63)}
                                      className="mr-3"
                                    >
                                      <img className="emoji-list-icon" src="/../assets/images/icons/Princess3.png" />
                                    </Link>
                                    <Link
                                      onClick={() => this.hitLikeApi(this.props.postDetail[0]._id, 64)}
                                      className=""
                                    >
                                      <img className="emoji-list-icon" src="/../assets/images/icons/Princess4.png" />
                                    </Link>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </Link>
                        </li>
                        <li className="dropUp7" onClick={() => this.likePopup7()}>
                          <Link>
                            <img className="emoji-list-icon" src={Prince1} />
                            <div className={this.state.likeshowHide7 ? 'emoji-list7_active' : 'emoji-list7'}>
                              <ul>
                                <li className="dropUp">
                                  <div className="d-flex">
                                    <Link
                                      onClick={() => this.hitLikeApi(this.props.postDetail[0]._id, 71)}
                                      className=" ml-5"
                                    >
                                      <img className="emoji-list-icon" src={Prince1} />
                                    </Link>
                                    <Link
                                      onClick={() => this.hitLikeApi(this.props.postDetail[0]._id, 72)}
                                      className="mr-3 ml-3"
                                    >
                                      <img className="emoji-list-icon" src={Prince2} />
                                    </Link>
                                    <Link
                                      onClick={() => this.hitLikeApi(this.props.postDetail[0]._id, 73)}
                                      className="mr-3"
                                    >
                                      <img className="emoji-list-icon" src={Prince3} />
                                    </Link>
                                    <Link
                                      onClick={() => this.hitLikeApi(this.props.postDetail[0]._id, 74)}
                                      className=""
                                    >
                                      <img className="emoji-list-icon" src={Prince4} />
                                    </Link>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="showing-comment">
                <div className="start-messege">
                  <p>Showing {this.tabType}</p>
                </div>
                <div className="comments-section">
                  <div className="tabs-section">
                    {this.props.postDetail[0].type == 'post' && !model ? (
                      <Tabs selectedIndex={this.selectedTab}>
                        <TabList>
                          <Tab onClick={() => this.hitAPI('comment')}>Comments</Tab>
                          <Tab onClick={() => this.hitAPI('insight')}>Insights </Tab>
                        </TabList>
                      </Tabs>
                    ) : (
                      ''
                    )}
                    {(this.tabType != 'insight' || this.props.postDetail[0].createdBy._id != this.props.user._id) && (
                      <div className="commnets-box">
                        <div className="postsection">
                          <div className="write-messge-post">
                            <div className="textarea-part">
                              <textarea
                                rows="1"
                                id="output"
                                name="description"
                                onChange={this.handleInputChange}
                                placeholder={`Share your ${
                                  this.props.postDetail[0].type == 'post' ? 'thoughts' : 'Answer'
                                }`}
                              ></textarea>
                              <img
                                src={
                                  this.props.user.profilePicture
                                    ? this.props.user.profilePicture
                                    : '../assets/images/user/01.jpg'
                                }
                                alt="user"
                                className={this.props.user.isBlogger ? 'user-icon2' : 'user-icon'}
                              />
                            </div>
                            <div className="post-footer-part">
                              <div className="right-part">
                                <div className="button-part">
                                  <button onClick={this.handleSubmit1} className="btn btn-primary" type="button ">
                                    Add {this.props.postDetail[0].type == 'post' ? this.tabType : 'Answer'}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="comments-list mt-3">
                      {this.state.commentList.length
                        ? this.state.commentList.map((item, index) => (
                            <div className="list-parts" key={index}>
                              <div className="post-block mb-3 wow fadeInUp">
                                <div className="post-toppart">
                                  <div className="left-part">
                                    <Link
                                      className="nav-link"
                                      to={
                                        this.props.user._id != item.createdBy._id
                                          ? `/blogger-profile/${item.createdBy._id}`
                                          : `/user-profile`
                                      }
                                    >
                                      <img
                                        className={
                                          item.type === 'insight'
                                            ? 'insightimg'
                                            : item.createdBy.isBlogger
                                            ? 'blogger'
                                            : ''
                                        }
                                        src={
                                          item.createdBy.profilePicture
                                            ? item.createdBy.profilePicture
                                            : '../assets/images/user/01.jpg'
                                        }
                                      />
                                      <p>
                                        {item.createdBy.name}
                                        {item.createdBy.isBlogger ? (
                                          <span style={{ fontSize: '13px' }}>
                                            {' '}
                                            <i
                                              className="fa fa-circle ml-1 mr-2"
                                              style={{
                                                fontSize: '8px',
                                                color: 'black',
                                              }}
                                            ></i>
                                            Blogger
                                          </span>
                                        ) : null}
                                      </p>{' '}
                                      <span>Posted : {item.creationDate}</span>
                                    </Link>
                                  </div>
                                  {item.type == 'insight' ? (
                                    <div className="right-part">
                                      <Link className="insight-icons">
                                        <img src="../../assets/images/icons/icon.png" />
                                      </Link>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                                <div className="messge-box">
                                  <p>
                                    <Linkify componentDecorator={componentDecorator}>
                                      {item.description.length > 200 ? (
                                        <ShowMoreText
                                          lines={3}
                                          more="Read more"
                                          less="Read less"
                                          onClick={this.executeOnClick}
                                          expanded={false}
                                        >
                                          {item.description}
                                        </ShowMoreText>
                                      ) : (
                                        item.description
                                      )}
                                    </Linkify>
                                  </p>
                                </div>
                                {item.type == 'insight' &&
                                this.props.user._id == this.props.postDetail[0].createdBy._id &&
                                item.showStatus == 'waiting' ? (
                                  <div className="insight-buttons">
                                    <button
                                      type="button"
                                      onClick={() => this.handleClick('add', item._id)}
                                      className="btn btn-primary"
                                    >
                                      Add Insight
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => this.handleClick('decline', item._id)}
                                      className="btn btn-primary"
                                    >
                                      Decline Insight
                                    </button>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          ))
                        : ''}
                      <Modal show={this.state.contactshowHide} className="addcollection-modal ">
                        <Modal.Body>
                          <Link className="closeicon" onClick={() => this.contactModalShowHide()}>
                            <i className="fa fa-close"></i>
                          </Link>

                          <ReportModal postId={this.props.postDetail[0]._id} onReport={this.contactModalShowHide} />
                        </Modal.Body>
                      </Modal>
                      <Modal show={this.state.deleteShowHide} className="addcollection-modal">
                        <Modal.Body>
                          <Link className="closeicon" onClick={() => this.deleteModalShowHide()}>
                            <i className="fa fa-close"></i>
                          </Link>
                          <div className="dashboard-contactus-section">
                            <div className="top-section text-center">
                              <h3>Delete Post</h3>
                              <p>Are you sure, You Want to delete this Post?</p>
                            </div>
                            <div className="d-flex new-btn justify-content-center">
                              <div className="submit-buttons delete_btn" style={{ marginRight: '15px' }}>
                                <Link
                                  type="button"
                                  className=""
                                  style={{ color: 'black' }}
                                  onClick={() => this.deletePost()}
                                >
                                  Yes
                                </Link>
                              </div>
                              <div className="submit-buttons delete_btn" style={{ marginLeft: '15px' }}>
                                <Link
                                  type="button"
                                  style={{ color: 'black' }}
                                  onClick={() => this.deleteModalShowHide()}
                                >
                                  No
                                </Link>
                              </div>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                      <Modal show={this.state.editModalShowHide} className="messge-post-modal ">
                        <Modal.Body>
                          <div className="postsection">
                            <a className="closeicon" onClick={() => this.editModalHandler()}>
                              <i className="fa fa-close"></i>
                            </a>
                            <EditPost user={this.props.user} data={this.props.postDetail[0]} onSubmit={this.onEdit} />
                          </div>
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Postdetail;
