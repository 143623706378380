import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import WOW from 'wowjs';
import Form from '../../macroComponents/form/form';
import Joi from 'joi-browser';
import * as registerService from './../../services/registerServices';
class AdminResetPasswordPage extends Form {
  state = {
    formData: {
      password: '',
    },
    errors: {},
  };

  schema = {
    password: Joi.string().required().min(8),
  };

  doSubmit = async () => {
    let formData = { ...this.state.formData };
    formData.email = this.props.location.email;
    const { data } = await registerService.adminResetPassword(formData);
    if (data) {
      this.props.history.push(`/admin/login`);
    }
  };
  async componentDidMount() {
    if (!this.props.location.email) return await this.props.history.push(`/admin/login`);
    new WOW.WOW({
      live: false,
    }).init();
  }
  render() {
    return (
      <div className="loginpage">
        <div className="login-section ">
          <div className="container">
            <div className="top-section text-center">
              <Link to="#">
                <img src="../assets/images/icons/reset.png" alt="reset logo" />{' '}
              </Link>
              <h3>Password Reset!</h3>
              <p>
                We Successfuly reset your password, Please enter a new <br></br> password to continue{' '}
              </p>
            </div>
            <div className="form-section wow bounceIn">
              <form>
                {this.renderInput('password', 'Password', 'Enter Strong Password', 'password')}
                {this.renderButton('Reset Password', this.handleSubmit)}
              </form>
            </div>
            <div className="login-footer text-center mt-4">
              <p>
                Back to <Link to="/admin/login">Login</Link> Page
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminResetPasswordPage;
