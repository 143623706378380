import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Header from './../commonComponents/userheader';
import WOW from 'wowjs';
import Sidebar from './../commonComponents/sidebar';
import Events from './../commonComponents/events';
import { CircleArrow as ScrollUpButton } from 'react-scroll-up-button';
import httpService from './../services/httpService';
import { toast } from 'react-toastify';
import ResultsTabs from './search/resultsTabs';
import Contactus from '../commonComponents/contactus';
import MediaQuery from 'react-responsive';
class SearchResult extends Component {
  state = {
    user: '',
  };

  componentDidMount = async () => {
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/myData`);
      if (data) {
        const user = data.user;
        this.setState({ user: user });

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }

    new WOW.WOW({
      live: false,
    }).init();
  };

  render() {
    return (
      <>
        <div className="userhomepage">
          <div className="container-fluid menu-parts wow slideInDown">
            <Header user={this.state.user} {...this.props} />
          </div>
          <div className="container-fluid user-activity">
            <div className="container">
              <Contactus />
              <div className="row">
                <div className="col-2 wow slideInLeft">
                  <Sidebar />
                </div>
                <div className="col-8 wow bounceIn">
                  <ResultsTabs user={this.state.user} {...this.props} />
                </div>
                <div className="col-2 wow slideInRight">
                  <Events />
                </div>
              </div>
            </div>
          </div>
          <ScrollUpButton />
        </div>
        <MediaQuery query="(max-width:767px)">
          <div className="userhomepage1">
            <div className="container-fluid menu-parts wow slideInDown">
              <Header user={this.state.user} {...this.props} />
            </div>
            <div className="user-activity">
              <div className="">
                <div className="row">
                  <div className="col-2 wow slideInLeft">
                    <Sidebar />
                  </div>
                  <div className="col-12 wow bounceIn">
                    <ResultsTabs user={this.state.user} {...this.props} />
                  </div>
                </div>
              </div>
            </div>
            <ScrollUpButton />
          </div>
        </MediaQuery>
      </>
    );
  }
}

export default SearchResult;
