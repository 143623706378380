import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import WOW from 'wowjs';
import AdminHeader from './../commonComponents/adminheader';
import { CircleArrow as ScrollUpButton } from 'react-scroll-up-button';
import Usertopmenubar from '../commonComponents/usertopmenubar';
import httpAdminService from './../../services/httpAdminService';
import { toast } from 'react-toastify';
import UserusageSection from './userusageSection/userusageSection';
class Useractivitylist extends Component {
  state = {
    usersData: '',
  };
  async componentDidMount() {
    try {
      const { data } = await httpAdminService.get(
        `${process.env.REACT_APP_APIENDPOINT}/admin/usersProfileDetail/${this.props.match.params.id}`,
      );
      if (data) {
        this.setState({ usersData: data.adminUsersData[0] });
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }

    new WOW.WOW({
      live: false,
    }).init();
  }
  render() {
    return (
      <div className="userhomepage">
        <div className="container-fluid menu-parts wow slideInDown">
          <AdminHeader />
        </div>
        <div className="container-fluid user-activity">
          <div className="container">
            <div className="row">
              <div className="col-12 wow fadeInUp">
                <Usertopmenubar user={this.state.usersData} {...this.props} />
                <div className="row">
                  <div className="col-12 wow fadeInUp">
                    <UserusageSection user={this.state.usersData} {...this.props} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollUpButton />
      </div>
    );
  }
}

export default Useractivitylist;
