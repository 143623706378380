import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
class BlogsSlider extends Component {
  render() {
    const settings = {
      draggable: true,
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        { breakpoint: 767, settings: { autoplay: true, slidesToShow: 1 } },
        { breakpoint: 1024, settings: { autoplay: true, slidesToShow: 2 } },
      ],
      speed: 500,
      centerPadding: 0,
    };

    return (
      <div className="blogs-slider">
        <div className="container">
          <div className="main-section">
            <div className="heading-section text-center">
              <h3 className="wow fadeInUp mb-5" style={{ fontSize: '25px', fontWeight: '800' }}>
                Featured Creators
              </h3>
            </div>
            <div className="slider-parts">
              <div style={{ marginLeft: '50px', marginRight: '50px' }}>
                <Slider {...settings}>
                  <div>
                    <img
                      className="blog-slider-img"
                      src="assets/images/blogger/1.png"
                      alt="Slider 1"
                      height={'200px'}
                      width={'200px'}
                    />
                    <p className="bloggers-name">
                      <b>Tsiporra</b>
                    </p>
                  </div>
                  <div>
                    <img
                      className="blog-slider-img"
                      src="assets/images/blogger/2.jpeg"
                      alt="Slider 2"
                      height={'200px'}
                      width={'200px'}
                    />
                    <p className="bloggers-name">
                      <b>Spry Scott</b>
                    </p>
                  </div>
                  <div>
                    <img
                      className="blog-slider-img"
                      src="assets/images/blogger/3.jpg"
                      alt="Slider 3"
                      height={'200px'}
                      width={'200px'}
                    />
                    <p className="bloggers-name">
                      <b>Dagny Zenovia</b>
                    </p>
                  </div>
                  <div>
                    <img
                      className="blog-slider-img"
                      src="assets/images/blogger/4.jpg"
                      alt="Slider 4"
                      height={'200px'}
                      width={'200px'}
                    />
                    <p className="bloggers-name">
                      <b>Superfood of Africa</b>
                    </p>
                  </div>
                  <div>
                    <img
                      className="blog-slider-img"
                      src="assets/images/blogger/5.jpg"
                      alt="Slider 5"
                      height={'200px'}
                      width={'200px'}
                    />
                    <p className="bloggers-name">
                      <b>Ancestral Voices</b>
                    </p>
                  </div>
                  <div>
                    <img
                      className="blog-slider-img"
                      src="assets/images/blogger/6.jpg"
                      alt="Slider 6"
                      height={'200px'}
                      width={'200px'}
                    />
                    <p className="bloggers-name">
                      <b>Nateya</b>
                    </p>
                  </div>
                  <div>
                    <img
                      className="blog-slider-img"
                      src="assets/images/blogger/7.jpeg"
                      alt="Slider 6"
                      height={'200px'}
                      width={'200px'}
                    />
                    <p className="bloggers-name">
                      <b>Caribbean People</b>
                    </p>
                  </div>
                  <div>
                    <img
                      className="blog-slider-img"
                      src="assets/images/blogger/8.png"
                      alt="Slider 6"
                      height={'200px'}
                      width={'200px'}
                    />
                    <p className="bloggers-name">
                      <b>Ade Africa</b>
                    </p>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BlogsSlider;
