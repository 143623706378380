import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-tabs/style/react-tabs.css';
import Form from '../macroComponents/form/form';
import Joi from 'joi-browser';
import * as postService from './../services/postServices';
class ReportModal extends Form {
  state = {
    formData: {
      reason: '',
    },
    errors: {},
  };
  schema = {
    reason: Joi.string().required(),
  };

  doSubmit = async () => {
    const reason = this.state.formData;
    this.props.onReport();
    this.setState({
      formData: {
        reason: '',
      },
    });
    const { error } = await postService.report(reason, this.props.postId);
    if (error) return;
    this.state.report = false;
  };
  constructor() {
    super();
    this.state.report = false;
  }

  render() {
    return (
      <div className="report-section">
        <div className="top-section text-center">
          <h3>Report Us</h3>
        </div>
        <div className="form-section wow bounceIn">
          <form>
            <div className="mt-4">{this.renderTextArea('reason', 'Report Reason')}</div>
            <div className="mt-5">{this.renderButton('Submit', this.handleSubmit)}</div>
          </form>
        </div>
      </div>
    );
  }
}

export default ReportModal;
