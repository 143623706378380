import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import WOW from 'wowjs';
import AdminHeader from './../commonComponents/adminheader';
import { CircleArrow as ScrollUpButton } from 'react-scroll-up-button';
import Topmenubar from '../commonComponents/topmenubar';
import InterestSection from './interestSection/interestSection';
import httpService from '../../services/httpService';
import httpAdminService from '../../services/httpAdminService';
import { toast } from 'react-toastify';

class InterestList extends Component {
  state = {
    interestList: [],
    admin: [],
    user: [],
  };
  async componentDidMount() {
    this.hitInterestApi();
    try {
      const { data } = await httpAdminService.get(`${process.env.REACT_APP_APIENDPOINT}/admin/adminData`);
      if (data) {
        this.setState({ admin: data.admin });

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }

    new WOW.WOW({
      live: false,
    }).init();
  }

  async hitInterestApi() {
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/trendingInterest`);
      this.setState({ interestList: data.interests });
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  changeState = () => {
    this.hitInterestApi();
  };
  render() {
    return (
      <div className="userhomepage">
        <div className="container-fluid menu-parts wow slideInDown">
          <AdminHeader />
        </div>
        <div className="container-fluid user-activity">
          <div className="container">
            <div className="row">
              <div className="col-12 wow fadeInUp">
                <Topmenubar user={this.props.user} {...this.props} />
                <InterestSection
                  interestList={this.state.interestList}
                  {...this.props}
                  changeState={this.changeState}
                  admin={this.state.admin}
                  {...this.props}
                />
              </div>
            </div>
          </div>
        </div>
        <ScrollUpButton />
      </div>
    );
  }
}

export default InterestList;
