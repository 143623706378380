import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../../commonComponents/userheader';
import WOW from 'wowjs';
import Events from '../../commonComponents/events';
import Contactus from '../../commonComponents/contactus';
import Sidebar from '../../commonComponents/sidebar';
import BloggerProfileImage from './bloggerProfileTabs/bloggerProfileImage';
import BloggerProfileTabs from './bloggerProfileTabs/bloggerProfileTabs';
import httpService from '../../services/httpService';
import { toast } from 'react-toastify';
import MediaQuery from 'react-responsive';

class BloggerProfile extends Component {
  state = {
    user: '',
    usersData: '',
  };
  async componentDidMount() {
    this.BloggerProfile();
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/myData`);
      if (data) {
        const user = data.user;
        this.setState({ user: user });

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }

    new WOW.WOW({
      live: false,
    }).init();
  }

  async BloggerProfile() {
    try {
      const { data } = await httpService.get(
        `${process.env.REACT_APP_APIENDPOINT}/bloggersProfile/${this.props.match.params.id}`,
      );
      if (data) {
        this.setState({
          usersData: data.usersData,
        });

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }

    new WOW.WOW({
      live: false,
    }).init();
  }

  render() {
    return (
      <>
        <div className="userhomepage">
          <div className="container-fluid menu-parts wow slideInDown">
            <Header user={this.state.user} {...this.props} />
          </div>
          <div className="container-fluid user-activity">
            <div className="container">
              <Contactus />
              <div className="row">
                <div className="col-2 wow slideInLeft">
                  <Sidebar {...this.props} />
                </div>
                <div className="col-8 wow bounceIn">
                  <BloggerProfileImage user={this.state.usersData} {...this.props} />
                  <BloggerProfileTabs user={this.state.user} {...this.props} />
                </div>
                <div className="col-2 wow slideInRight">
                  <Events />
                </div>
              </div>
            </div>
          </div>
          <reactScrollUpButton />
        </div>
        <MediaQuery query="(max-width:767px)">
          <div className="userhomepage1">
            <div className="container-fluid menu-parts wow slideInDown">
              <Header user={this.state.user} {...this.props} />
            </div>
            <div className=" user-activity">
              <div className="">
                {/* <Contactus /> */}
                <div className="row">
                  <div className="col-2 wow slideInLeft">
                    <Sidebar {...this.props} />
                  </div>

                  <div className="col-12 wow bounceIn">
                    <BloggerProfileImage user={this.state.usersData} {...this.props} />
                    <BloggerProfileTabs user={this.state.user} {...this.props} />
                  </div>
                </div>
              </div>
            </div>
            <reactScrollUpButton />
          </div>
        </MediaQuery>
      </>
    );
  }
}

export default BloggerProfile;
