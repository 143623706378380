import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import httpService from '../../services/httpService';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import * as postService from '../../services/postServices';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import { RplFetcher } from '../../lib/helpers';
class BookclubTop extends Component {
  fileArray = [];
  sendFile = [];
  modalType = '';
  state = {
    selectedTags: [],
    interestList: [],
    old_arr: [],
    description: '',
    link: '',
    web_link: '',
    file: [],
    bookClubData: [],
    showHide: false,
  };
  async follow(id) {
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/follow/${id}`);
      if (data) {
        this.props.onFollow();
        toast.success(data.message);
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  async componentDidMount() {
    await this.getBookClubData();
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/getInterest`);
      this.setState({ interestList: data.interests });
      this.setState({ old_arr: data.interests });
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  async getBookClubData() {
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/getBookClubData`);
      this.setState({ bookClubData: data.data });
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.openPost === true && prevProps.openPost !== this.props.openPost) {
      this.postModalHandler('post');
    }
  };

  loader(type) {
    var shwload = document.getElementById('page_loader');
    shwload.style.display = type;
  }

  constructor(props) {
    super(props);
    this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  toggleLinkpart = () => {
    this.setState({ linkpart: !this.state.linkpart });
  };
  toggleWebsitepart = () => {
    this.setState({ websitepart: !this.state.websitepart });
  };
  uploadMultipleFiles(e) {
    for (let i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].size > 20242880) {
        toast.error('File size exceeded. Max size should be 20MB.');
        continue;
      }
      let obj = {
        name: e.target.files[i].name,
        type: e.target.files[i].type,
        fileurl: URL.createObjectURL(e.target.files[i]),
      };
      this.fileArray.push(obj);
      this.sendFile.push(e.target.files[i]);
    }

    this.setState({ file: this.sendFile });
  }
  getExtension(name) {
    if (name) {
      return name.split('.')[name.split('.').length - 1].toLowerCase();
    }
  }

  postModalHandler(type) {
    if (type == '') {
      this.setState({ linkpart: false });
      this.setState({ websitepart: false });
    }
    this.modalType = type;
    this.fileArray = [];
    this.sendFile = [];
    this.setState({ web_link: '' });
    this.setState({ link: '' });
    this.setState({ description: '' });
    this.setState({ selectedTags: [] });
    this.setState({ file: [] });
    this.setState({ interestList: [] });
    this.setState({ showHide: !this.state.showHide });
  }

  onRemoveFileSelection(index) {
    this.fileArray.splice(index, 1);
    this.sendFile.splice(index, 1);
    this.setState({ file: this.sendFile });
  }
  handleInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({
      [name]: value,
    });
  }

  handleKeyPress(e) {
    let originalArr = JSON.parse(JSON.stringify(this.state.old_arr));
    if (e.target.value) {
      let interestList = this.state.old_arr.filter((item, index) => {
        if (item['name'].toLowerCase().includes(e.target.value.toLowerCase())) {
          return item;
        }
      });
      this.setState({ interestList: interestList });
    } else {
      this.setState({ interestList: [...originalArr] });
    }
  }

  tags(name) {
    if (this.state.selectedTags.indexOf(name) == -1) {
      this.state.selectedTags.push(name);
    }
  }

  removeTags(name) {
    var array = [...this.state.selectedTags]; // make a separate copy of the array
    var index = array.indexOf(name);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ selectedTags: array });
    }
  }

  isUrl(s) {
    var shortLink = /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/;
    var fullLink = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return fullLink.test(s) || shortLink.test(s);
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.state.link) {
      let checkUrl = this.isUrl(this.state.link);
      if (!checkUrl) {
        toast.error('Link is invalid');
        return;
      }
    }
    if (this.state.web_link) {
      let checkUrlWebsite = this.isUrl(this.state.web_link);
      if (!checkUrlWebsite) {
        toast.error('Website Link is invalid');
        return;
      }
    }
    const files = this.state.file;
    if (this.state.description == '' && this.state.link == '' && files.length == 0) {
      toast.error('Please enter description');
      return;
    }
    if (files.length == 0 && this.state.description == '') {
      toast.error('Please enter description or add image');
      return;
    }
    this.loader('block');
    const interests = this.state.selectedTags;
    const formData = new FormData();
    formData.append('type', this.modalType);
    formData.append('description', this.state.description);
    formData.append('link', this.state.link);
    formData.append('web_link', this.state.web_link);
    for (let i = 0; i < interests.length; i++) {
      formData.append('interest', interests[i]);
    }
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }

    const { data, error } = await postService.createBookClubPost(formData);
    if (error) {
      this.loader('none');
      return;
    } else {
      this.setState({ showHide: !this.state.showHide });
      this.setState({ linkpart: false });
      this.setState({ websitepart: false });
      this.setState({ selectedTags: [] });
      this.setState({ link: '' });
      this.setState({ web_link: '' });
      this.fileArray = [];
      this.props.onAdd();
      this.loader('none');

      return;
    }
  };
  onEditorStateChange = (e) => {
    this.setState({ description: e.target.value });
  };

  redirect = () => {
    const link = document.createElement('a');
    link.href = this.state.bookClubData[0]?.link;
    link.target = '_blank';
    link.click();
  };

  render() {
    return (
      <div>
        <div className="learn-itemislider mb-0">
          <div className="heading-parts">
            <h3>Book Club</h3>
            {this.props.user.followedBookClub ? (
              <Link onClick={() => this.postModalHandler('post')}>Make a Post</Link>
            ) : null}
          </div>
        </div>
        <Modal show={this.state.showHide} className="messge-post-modal ">
          <Modal.Body>
            <div className="postsection">
              <a className="closeicon" onClick={() => this.postModalHandler('')}>
                <i className="fa fa-close"></i>
              </a>
              <div className="heading-part">
                <h3>{this.modalType == 'post' ? 'Make a Post' : 'Ask a question'}</h3>
              </div>
              <div className="write-messge-post">
                <div className="textarea-part">
                  {this.modalType == 'post' ? (
                    <textarea
                      rows="3"
                      id="description"
                      name="description"
                      className="demo-editor"
                      onChange={this.onEditorStateChange}
                      placeholder="Write your Post"
                    ></textarea>
                  ) : (
                    <textarea
                      rows="3"
                      id="description"
                      name="description"
                      className="demo-editor"
                      onChange={this.onEditorStateChange}
                      placeholder="Ask your Question"
                    ></textarea>
                  )}

                  <img
                    src={
                      this.props.user.profilePicture ? this.props.user.profilePicture : '../assets/images/user/01.jpg'
                    }
                    alt="user"
                    className={this.props.user.isBlogger ? 'user-icon1' : 'user-icon'}
                  />
                </div>
                <div className="form-group preview">
                  <div className="row" style={{ width: '100%' }}>
                    {this.fileArray.map((item, index) => {
                      return (
                        <div className="col-md-3">
                          <i className="fa fa-times close-icon" onClick={() => this.onRemoveFileSelection(index)}></i>
                          {this.getExtension(item.name) == 'png' ||
                          this.getExtension(item.name) == 'jpg' ||
                          this.getExtension(item.name) == 'jpeg' ? (
                            <img src={item.fileurl} alt="..." height="120" width="120" />
                          ) : (
                            <video height="120" width="120" controls>
                              <source src={item.fileurl} type="video/mp4" />
                            </video>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="tags-part">
                  <ul>
                    {this.state.selectedTags.map((item, index) => {
                      return (
                        <li key={index}>
                          {item}
                          <a href="#" onClick={() => this.removeTags(item)}>
                            <i className="fa fa-times-circle-o"></i>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                {this.state.linkpart && this.state.link ? (
                  <div>
                    <LinkPreview
                      fetcher={RplFetcher}
                      url={this.state.link}
                      width="580px"
                      height="250px"
                      imageHeight="320px"
                      descriptionLength="80"
                      className="link-preview ml-5"
                    />
                  </div>
                ) : null}
                <div className={this.state.linkpart ? 'link-part active' : 'link-part'}>
                  <div className="form-group">
                    <input
                      type="text"
                      name="link"
                      onChange={this.handleInputChange}
                      className="form-control"
                      placeholder="Enter Your Link"
                    />
                  </div>
                </div>
                {this.state.websitepart && this.state.web_link ? (
                  <div>
                    <LinkPreview
                      fetcher={RplFetcher}
                      url={this.state.web_link}
                      width="580px"
                      height="250px"
                      imageHeight="320px"
                      descriptionLength="80"
                      className="link-preview ml-5 mt-3"
                    />
                  </div>
                ) : null}
                <div className={this.state.websitepart ? 'link-part active' : 'link-part'}>
                  <div className="form-group">
                    <input
                      type="text"
                      name="web_link"
                      onChange={this.handleInputChange}
                      className="form-control"
                      placeholder="Enter Your Website Link"
                    />
                  </div>
                </div>
                <div className="post-footer-part">
                  <div className="left-part">
                    <ul>
                      <li>
                        <div className="upload-image">
                          <label>
                            <i className="fa fa-picture-o"></i>
                            <input
                              type="file"
                              className="form-control"
                              accept=".jpeg,.jpg,.png"
                              onChange={this.uploadMultipleFiles}
                              multiple
                            />{' '}
                          </label>
                        </div>
                      </li>
                      <li>
                        <div className="upload-image">
                          <label>
                            <i className="fa fa-play-circle"></i>
                            <input
                              type="file"
                              className="form-control"
                              accept=".mp4"
                              onChange={this.uploadMultipleFiles}
                            />{' '}
                          </label>
                        </div>
                      </li>
                      <li>
                        <Link onClick={this.toggleLinkpart}>
                          <i className="fa fa-link"></i>
                        </Link>
                      </li>
                      <li>
                        <Link onClick={this.toggleWebsitepart}>
                          <i className="fa fa-globe"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="right-part">
                    <div className="button-part">
                      <button className="btn btn-primary" onClick={this.handleSubmit} type="button ">
                        {this.modalType == 'post' ? 'Post' : 'Ask'}
                      </button>
                    </div>
                    <div className="search-part">
                      <input
                        className="form-control mr-sm-2"
                        onChange={(e) => this.handleKeyPress(e)}
                        type="search "
                        placeholder="Add Tags"
                        aria-label="Search"
                      />
                      <div className="tags-search">
                        <ul>
                          {this.state.interestList.map((user, index) => (
                            <li onClick={() => this.tags(user.name)}>
                              <a href="#"> {user.name}</a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <span className="imagesizedefine">Max size of video should be 20MB</span>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <div className="collection-section">
          <div className="bookclub-display-section ">
            <div className="main-section">
              <div className="post-block mb-3 wow fadeInUp">
                <div className="row">
                  <div className="col-md-5">
                    <div className="collection-image-part">
                      <img src="assets/images/interests/Book Club Cover.jpeg" alt="Book Club" />
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="collection-content">
                      <div className="collection-toppart">
                        <div className="left-part">
                          <Link>
                            <p>Monthly Virtual Discussion Series</p>{' '}
                          </Link>
                        </div>
                      </div>
                      <div className="messge-box">
                        <p>
                          Led by a monthly host, our book club brings the community together to discuss and deep dive
                          into the life lessons learned from exciting new books every month. Let's thrive together!
                        </p>
                      </div>
                      <div className="d-flex">
                        {this.state.bookClubData.length && this.props.user.followedBookClub ? (
                          <div className="zoom-link mb-2">
                            <Link onClick={this.redirect} style={{ color: 'black' }}>
                              Launch Meeting
                            </Link>
                          </div>
                        ) : null}
                        <div className="link-attend">
                          <Link onClick={() => this.follow(this.props.user._id)}>
                            {!this.props.user.followedBookClub ? <Link>Follow</Link> : <Link>UnFollow</Link>}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BookclubTop;
