import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Eventbackpost from './eventbackpost';
import EventsSection from './eventsSection';
import Learnbackpost from './learnbackpost';
import LearnWithBthrivePosts from '../../../commonComponents/learnWithBthriveAdminPosts';
import * as eventServices from '../../../services/learnWithBthriveServices';
import { toast } from 'react-toastify';
import httpAdminService from '../../../services/httpAdminService';
import moment from 'moment';
import parse from 'html-react-parser';
class EventsTabs extends Component {
  state = {
    eventList: [],
    listData: '',
  };

  async componentDidMount() {
    this.LearnWithBthrivePostsApi();
    const events = (await eventServices.adminGetEvents('')).data.events;
    const classes = (await eventServices.adminGetClasses('')).data.classes;

    let temp = events.concat(classes);

    temp.sort((a, b) => (a.creationDate < b.creationDate ? 1 : b.creationDate < a.creationDate ? -1 : 0));
    // temp.forEach((item) => {
    //   item.date = item.date && moment(item.date).format("LL");
    // });
    this.setState({ eventList: temp });
  }

  eventSortApi = async (sort = '') => {
    const events = (await eventServices.adminGetEvents(sort)).data.events;
    const classes = (await eventServices.adminGetClasses(sort)).data.classes;

    let temp = events.concat(classes);

    temp.sort((a, b) => (a.creationDate < b.creationDate ? 1 : b.creationDate < a.creationDate ? -1 : 0));
    // temp.forEach((item) => {
    //   item.date = item.date && moment(item.date).format("LL");
    // });
    this.setState({ eventList: temp });
  };
  LearnWithBthrivePostsApi = async (sort = '') => {
    try {
      const { data } = await httpAdminService.get(
        `${process.env.REACT_APP_APIENDPOINT}/admin/learnWithBthrivepost/post?page=${Number(1)}&sort=${sort}`,
      );

      if (data) {
        data.postList[0].data.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('MMM DD, YYYY h:mm a');
          item.description = parse(item.description);
        });

        const listData = data.postList[0].data;

        // this.pageData = [...this.pageData, ...listData];
        this.setState({ listData: listData });

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  };

  deleteEvent = () => {
    this.componentDidMount();
  };

  addEvent = () => {
    this.componentDidMount();
  };
  render() {
    return (
      <div className="tabs-profile-section mt-3">
        <div className="tabs-section eventstabs">
          <Tabs>
            <TabList>
              <Tab>Events List</Tab>
              <Tab>Learn with B-Thrive </Tab>
            </TabList>
            <TabPanel>
              <Eventbackpost onSort={this.eventSortApi} onAdd={this.addEvent} data={this.state.eventList} />
              <EventsSection data={this.state.eventList} delete={this.deleteEvent} />
            </TabPanel>
            <TabPanel>
              <Learnbackpost
                user={this.props.user}
                onSort={this.LearnWithBthrivePostsApi}
                data={this.state.listData}
                onAdd={this.addEvent}
              />
              <LearnWithBthrivePosts data={this.state.listData} admin={true} />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default EventsTabs;
