import React from 'react';
import { Redirect } from 'react-router-dom';

class ConditionalRoute extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const Component = this.props.component;
    const isAuthenticated = localStorage.getItem('token');
    return isAuthenticated ? <Redirect to={{ pathname: '/user-home' }} /> : <Component />;
  }
}

export default ConditionalRoute;
