import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Header from './../commonComponents/userheader';
import WOW from 'wowjs';
import Sidebar from './../commonComponents/sidebar';
import { CircleArrow as ScrollUpButton } from 'react-scroll-up-button';
import httpService from './../services/httpService';
import { toast } from 'react-toastify';
import BookclubTop from './bookclubSection/bookclubTop';
import RightPart from './bookclubSection/rightPart';
import Contactus from '../commonComponents/contactus';
import BookClubPostquestion from './../commonComponents/bookClubPostQuestion';
import MediaQuery from 'react-responsive';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
class BookclubPage extends Component {
  state = {
    user: '',
  };
  componentDidMount = async () => {
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/myData`);
      if (data) {
        const user = data.user;
        this.setState({ user: user });

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }

    new WOW.WOW({
      live: false,
    }).init();
  };

  reload = async () => {
    window.location.reload(false);
  };

  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide });
  }
  render() {
    return (
      <>
        <div className="userhomepage">
          <div className="container-fluid menu-parts wow slideInDown">
            <Header user={this.state.user} {...this.props} />
          </div>
          <div className="container-fluid user-activity">
            <div className="container">
              <Contactus />
              <div className="row">
                <div className="col-2 wow slideInLeft">
                  <Sidebar {...this.props} />
                </div>
                <div className="col-8 wow bounceIn">
                  <BookclubTop user={this.state.user} onFollow={this.reload} onAdd={this.reload} />
                  <BookClubPostquestion user={this.state.user} />
                </div>
                <div className=" wow slideInLeft">
                  <RightPart />
                </div>
              </div>
            </div>
          </div>
          <ScrollUpButton />
        </div>
        <MediaQuery query="(max-width:767px)">
          <div className="userhomepage1">
            <div className="container-fluid menu-parts wow slideInDown">
              <Header user={this.state.user} {...this.props} />
            </div>
            <div className="user-activity">
              <div className="">
                <div className="row">
                  <div className="col-2 wow slideInLeft">
                    <Sidebar {...this.props} />
                  </div>
                  <Modal show={this.state.showHide} className="upcommingevent-modal ">
                    <Modal.Body>
                      <Link className="closeicon" onClick={() => this.handleModalShowHide()}>
                        <i className="fa fa-close"></i>
                      </Link>

                      <RightPart />
                    </Modal.Body>
                  </Modal>

                  <div className="col-12 wow bounceIn">
                    <BookclubTop user={this.state.user} onFollow={this.reload} onAdd={this.reload} />
                    <BookClubPostquestion user={this.state.user} />
                  </div>
                </div>
              </div>
            </div>
            <div className="plus">
              <p className="plus-btn" onClick={() => this.handleModalShowHide()}>
                <i className="fa fa-plus "></i>
              </p>
            </div>

            <ScrollUpButton />
          </div>
        </MediaQuery>
      </>
    );
  }
}

export default BookclubPage;
