import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import Header from './../commonComponents/userheader';
import WOW from 'wowjs';
import Sidebar from './../commonComponents/sidebar';
import { CircleArrow as ScrollUpButton } from 'react-scroll-up-button';
import httpService from './../services/httpService';
import { toast } from 'react-toastify';
import moment from 'moment';
import Events from './../commonComponents/events';
import CollectionListSection from './collectionsSection/collectionListSection';
import Form from '../macroComponents/form/form';
import Joi from 'joi-browser';
import { Button, Modal } from 'react-bootstrap';
import * as postService from '../services/postServices';
import ImageUploader from 'react-images-upload';
import $ from 'jquery';
import Contactus from '../commonComponents/contactus';
import MediaQuery from 'react-responsive';

class CollectionListPage extends Form {
  state = {
    postDetail: '',
    user: '',
    listData: '',
    page: Number(1),
    formData: {
      name: '',
      description: '',
    },
    selectedFile1: null,
    errors: {},
    collectionshowHide: false,
  };
  totalData = '';
  pageData = [];

  componentDidMount = async () => {
    this.collectionApi(this.state.page);
    window.addEventListener('scroll', this.loadMore);
    document.body.addEventListener('touchmove', this.loadMore);
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/myData`);
      if (data) {
        const user = data.user;
        this.setState({ user: user });

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }

    new WOW.WOW({
      live: false,
    }).init();
  };

  loader(type) {
    var shwload = document.getElementById('page_loader');
    shwload.style.display = type;
  }

  loadMore = () => {
    if (
      $(window).scrollTop() + $(window).height() == $(document).height() ||
      $(window).scrollTop() + window.innerHeight >= document.body.scrollHeight
    ) {
      if (this.pageData.length != this.totalData) {
        var page_count = this.state.page + Number(1);
        this.setState({
          page: page_count,
        });
        this.collectionApi(this.state.page);
      }
    }
  };

  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide });
  }
  async collectionApi(page) {
    //this.loader("block");
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/collectionList?page=${page}`);

      if (data) {
        data.collectionList[0].data.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('MMM DD, YYYY h:mm A');
        });
        const listData = data.collectionList[0].data;
        this.totalData = data.collectionList[0].metadata[0] ? data.collectionList[0].metadata[0].total : '';
        this.pageData = [...this.pageData, ...listData];
        this.setState({ listData: this.pageData });
        if (!window.location.href.includes('admin')) this.filterlist(this.state.listData);
        //this.loader("none");

        return;
      }
    } catch (error) {
      this.loader('none');
      toast.error(error.message);
      return;
    }
  }
  filterlist = (data) => {
    let listData = data;
    if (this.state.user.usersBlocked) {
      for (var index = 0; index < listData.length; index++) {
        if (
          this.state.user.usersBlocked.includes(listData[index].createdBy._id) ||
          listData[index].createdBy.usersBlocked.includes(this.state.user._id)
        ) {
          listData.splice(index, 1);
          index--;
        }
      }
      this.setState({ listData: listData });
    }
  };
  schema = {
    name: Joi.string().min(3).max(25).required(),
    description: Joi.string().min(3).required(),
  };
  collectionModalShowHide() {
    this.setState({ collectionshowHide: !this.state.collectionshowHide });
    this.setState({
      formData: {
        name: '',
        description: '',
      },
    });
  }
  doSubmit = async () => {
    if (!this.state.selectedFile1) {
      toast.error('Please select Cover Picture');
      return;
    }
    this.collectionModalShowHide();
    const formData = new FormData();
    {
      let data = { ...this.state.formData };
      for (const item in data) {
        if (data[item] === '') {
          data[item] = 'N/A';
        }
        formData.append(item, data[item]);
      }
    }
    formData.append('image', this.state.selectedFile1, this.state.selectedFile1.name);
    const { error } = await postService.createCollection(formData);
    this.setState({ selectedFile1: null });
    if (error) return;
    this.pageData = [];
    this.collectionApi(Number(1));
    return;
  };
  onFileSelection = async (event) => {
    let file = event[0];
    if (file && file.size < 2880) {
      toast.error('Minimum File size should be 1MB.');
    } else if (file && file.size > 5242880) {
      toast.error('File size exceeded. Max size should be 5MB.');
    } else {
      await this.setState({ selectedFile1: event[0] });
    }
  };

  handleLike = (likeType, index, isChange) => {
    const listData = [...this.state.listData];
    if (isChange == 1) {
      listData[index].my_like[0].type = 1;
      if (listData[index].likeList.length) {
        var index2 = listData[index].likeList.findIndex((p) => p.createdBy._id === this.state.user._id);
        if (index2 > -1) {
          listData[index].likeList.splice(index2, 1);
        }
      }
    } else {
      if (listData[index].my_like.length) {
        listData[index].my_like[0].type = likeType;
      } else {
        listData[index].my_like.push({ type: likeType });
      }

      if (listData[index].likeList.length) {
        const found2 = listData[index].likeList.some((el) => el.createdBy._id === this.state.user._id);
        if (!found2) {
          listData[index].likeList.push({
            createdBy: { name: this.state.user.name, _id: this.state.user._id },
          });
        }
      } else {
        listData[index].likeList.push({
          createdBy: { name: this.state.user.name, _id: this.state.user._id },
        });
      }
    }
    this.setState({
      listData,
    });
    // this.setState({language: langValue});
  };

  onDelete = () => {
    this.setState({ page: Number(1) });
    this.pageData = [];
    this.componentDidMount();
  };

  render() {
    if (!this.state.listData) return null;
    return (
      <>
        <div className="userhomepage">
          <div className="container-fluid menu-parts wow slideInDown">
            <Header user={this.state.user} {...this.props} />
          </div>
          <div className="container-fluid user-activity">
            <div className="container">
              <Contactus />
              <div className="row">
                <div className="col-2 wow slideInLeft">
                  <Sidebar {...this.props} />
                </div>
                <div className="col-8 wow bounceIn">
                  <div>
                    <div className="space-itemislider collectionss">
                      <div className="heading-parts">
                        <h3>Collections</h3>
                        <p>Create a collection out of different posts and share with the community</p>
                      </div>
                      <div className="button-part">
                        <Link onClick={() => this.collectionModalShowHide()} className="btn btn-primary" type="button ">
                          New Collections
                        </Link>
                      </div>
                      <Modal show={this.state.collectionshowHide} className="addcollection-modal ">
                        <Modal.Body>
                          <Link className="closeicon" onClick={() => this.collectionModalShowHide()}>
                            <i className="fa fa-close"></i>
                          </Link>
                          <div className="collection">
                            <form>
                              <h4 className="text-center">Create a New Collections </h4>
                              <p className="text-center">Save this post to collection you can view later.</p>
                              <div className="selectpart">
                                <div className="form-group">
                                  {this.renderInput('name', 'Collection Name', 'Enter Collection Name')}
                                </div>
                                <div className="form-group">
                                  {this.renderTextArea('description', 'Enter Description')}
                                </div>
                                <div className="form-group">
                                  <label>Cover Image</label>
                                  <div className="collection-image">
                                    <ImageUploader
                                      withIcon={true}
                                      withPreview={true}
                                      buttonText={<i className="fa fa-camera"></i>}
                                      onChange={this.onFileSelection}
                                      imgExtension={['.jpg', '.jpeg', '.gif', '.png']}
                                      maxFileSize={5242880}
                                      singleImage={true}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="submit-buttons text-center">
                                {this.renderButton('Continue', this.handleSubmit)}
                              </div>
                            </form>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>
                  <CollectionListSection
                    listData={this.state.listData}
                    {...this.props}
                    onLikeChange={this.handleLike}
                    user={this.state.user}
                    onDelete={this.onDelete}
                  />
                </div>
                <div className="col-2 wow slideInLeft">
                  <Events />
                </div>
              </div>
            </div>
          </div>
          <ScrollUpButton />
        </div>
        <MediaQuery query="(max-width:767px)">
          <div className="userhomepage1">
            <div className="container-fluid menu-parts wow slideInDown">
              <Header user={this.state.user} {...this.props} />
            </div>
            <div className=" user-activity">
              <div className="">
                <div className="row">
                  <div className="col-2 wow slideInLeft">
                    <Sidebar {...this.props} />
                  </div>
                  <Modal show={this.state.showHide} className="upcommingevent-modal ">
                    <Modal.Body>
                      <Link className="closeicon" onClick={() => this.handleModalShowHide()}>
                        <i className="fa fa-close"></i>
                      </Link>
                      <Events />
                    </Modal.Body>
                  </Modal>
                  <div className="col-12 wow bounceIn " style={{ marginTop: '60px' }}>
                    <div>
                      <div className="space-itemislider collectionss">
                        <div className="heading-parts">
                          <h3>Collections</h3>
                          <p style={{ width: '190px' }}>
                            Create a collection out of different posts and share with the community
                          </p>
                        </div>
                        <div className="button-part">
                          <Link
                            onClick={() => this.collectionModalShowHide()}
                            className="btn btn-primary"
                            type="button "
                          >
                            New Collections
                          </Link>
                        </div>
                        <Modal show={this.state.collectionshowHide} className="addcollection-modal ">
                          <Modal.Body>
                            <Link className="closeicon" onClick={() => this.collectionModalShowHide()}>
                              <i className="fa fa-close"></i>
                            </Link>
                            <div className="collection">
                              <form>
                                <h4 className="text-center">Create a New Collections </h4>
                                <p className="text-center">Save this post to collection you can view later.</p>
                                <div className="selectpart">
                                  <div className="form-group">
                                    {this.renderInput('name', 'Collection Name', 'Enter Collection Name')}
                                  </div>
                                  <div className="form-group">
                                    {this.renderTextArea('description', 'Enter Description')}
                                  </div>
                                  <div className="form-group">
                                    <label>Cover Image</label>
                                    <div className="collection-image">
                                      <ImageUploader
                                        withIcon={true}
                                        withPreview={true}
                                        buttonText={<i className="fa fa-camera"></i>}
                                        onChange={this.onFileSelection}
                                        imgExtension={['.jpg', '.jpeg', '.gif', '.png']}
                                        maxFileSize={5242880}
                                        singleImage={true}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="submit-buttons text-center">
                                  {this.renderButton('Continue', this.handleSubmit)}
                                </div>
                              </form>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>
                    </div>
                    <CollectionListSection
                      listData={this.state.listData}
                      {...this.props}
                      onLikeChange={this.handleLike}
                      user={this.state.user}
                      onDelete={this.onDelete}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="plus">
              <p className="plus-btn" onClick={() => this.handleModalShowHide()}>
                <i className="fa fa-plus "></i>
              </p>
            </div>
            <ScrollUpButton />
          </div>
        </MediaQuery>
      </>
    );
  }
}

export default CollectionListPage;
