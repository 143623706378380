import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Header from './../commonComponents/userheader';
import WOW from 'wowjs';
import Events from './../commonComponents/events';
import Contactus from '../commonComponents/contactus';
import Sidebar from './../commonComponents/sidebar';
import ProfileImage from './profileComponents/profileImage';
import ProfileTabs from './profileComponents/profileTabs';
import httpService from './../services/httpService';
import { toast } from 'react-toastify';
import MediaQuery from 'react-responsive';

class UserProfilePage extends Component {
  state = {
    user: null,
    checkUser: null,
  };

  async componentDidMount() {
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/myData`);
      if (data) {
        const user = data.user;
        const checkUser = data.checkUser;
        this.setState({ user: user });
        this.setState({ checkUser: checkUser });

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }

    new WOW.WOW({
      live: false,
    }).init();
  }

  render() {
    return (
      <>
        <div className="userhomepage">
          <div className="container-fluid menu-parts wow slideInDown">
            <Header user={this.state.user} {...this.props} />
          </div>
          <div className="container-fluid user-activity">
            <div className="container">
              <Contactus />
              <div className="row">
                <div className="col-2 wow slideInLeft">
                  <Sidebar {...this.props} />
                </div>
                <div className="col-8 wow bounceIn">
                  <ProfileImage
                    user={this.state.user}
                    {...this.props}
                    checkUser={this.state.checkUser}
                    {...this.props}
                  />
                  <ProfileTabs user={this.state.user} {...this.props} />
                </div>
                <div className="col-2 wow slideInRight">
                  <Events />
                </div>
              </div>
            </div>
          </div>
          <reactScrollUpButton />
        </div>
        <MediaQuery query="(max-width:767px)">
          <div className="userhomepage1">
            <div className="container-fluid menu-parts wow slideInDown">
              <Header user={this.state.user} {...this.props} />
            </div>
            <div className=" user-activity">
              <div className="">
                {/* <Contactus /> */}
                <div className="row">
                  <div className="col-2 wow slideInLeft">
                    <Sidebar {...this.props} />
                  </div>

                  <div className="col-12 wow bounceIn">
                    <ProfileImage
                      user={this.state.user}
                      {...this.props}
                      checkUser={this.state.checkUser}
                      {...this.props}
                    />
                    <ProfileTabs user={this.state.user} {...this.props} />
                  </div>
                </div>
              </div>
            </div>
            <reactScrollUpButton />
          </div>
        </MediaQuery>
      </>
    );
  }
}

export default UserProfilePage;
