import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-tabs/style/react-tabs.css';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Form from '../../../macroComponents/form/form';
import * as postAdminService from '../../../services/postAdminService';
import Joi from 'joi-browser';
import { toast } from 'react-toastify';
import validator from 'validator';
class BookClubShorting extends Form {
  state = {
    formData: {
      link: '',
    },
    errors: {},
  };
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  schema = {
    link: Joi.string().required(),
  };
  doSubmit = async () => {
    if (!validator.isURL(this.state.formData.link)) {
      toast.error('Enter Valid Link');
      return;
    }
    this.eventsModalShowHide();
    const { data, error } = await postAdminService.addZoomLink(this.state.formData);
    if (error) return;
  };

  handleChange1 = (e) => {
    this.props.onSortChange(e.target.value);
  };
  eventsModalShowHide() {
    this.setState({ eventsshowHide: !this.state.eventsshowHide });
    this.setState({
      formData: {
        link: '',
      },
    });
  }
  render() {
    return (
      <div className="shorting-dashboard mt-3 ">
        <div className="main-section">
          <div className="left-part">
            <p>Become a Host List</p>
          </div>
          <div className="right-part">
            <Link onClick={() => this.eventsModalShowHide()} className="add-events">
              Add Zoom Link
            </Link>

            <Modal show={this.state.eventsshowHide} className="addcollection-modal">
              <Modal.Body>
                <Link className="closeicon" onClick={() => this.eventsModalShowHide()}>
                  <i className="fa fa-close"></i>
                </Link>
                <div className="collection">
                  <h4 className="text-center">Add Zoom Link </h4>
                  <div className="selectpart">{this.renderInput('link', 'Enter Link', 'Enter Link')}</div>
                  <div className="admin-btn">{this.renderButton('Add', this.handleSubmit)}</div>
                </div>
              </Modal.Body>
            </Modal>
            <div className="form-group">
              <label>Sort By :</label>
              <select className="fomr-control" onChange={this.handleChange1}>
                <option value="All_Time">All Time</option>
                <option value="last_minute">Last Minute</option>
                <option value="last_hour">Last Hours</option>
                <option value="last_week">Last Week</option>
                <option value="last_month">Last Month</option>
                <option value="last_quarter">Last Quarter</option>
                <option value="Last_Year">Last Year</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BookClubShorting;
