import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import ReactApexChart from 'react-apexcharts';
import WOW from 'wowjs';
import httpAdminService from './../../../services/httpAdminService';
import { toast } from 'react-toastify';
import moment from 'moment';
class Linechart extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    series: [
      {
        name: 'New Signup',
        data: [0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: 'Post',
        data: [0, 0, 0, 0, 0, 0, 0],
      },
    ],
    options: {
      chart: {
        height: 550,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      title: {
        text: 'weekly Activity Log',
        align: 'left',
      },
      grid: {
        row: {
          colors: ['transparent', 'transparent'],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [
          moment(new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 6)).format('MMMM, DD'),
          moment(new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 5)).format('MMMM, DD'),
          moment(new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 4)).format('MMMM, DD'),
          moment(new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 3)).format('MMMM, DD'),
          moment(new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 2)).format('MMMM, DD'),
          moment(new Date(new Date().valueOf() - 1000 * 60 * 60 * 24)).format('MMMM, DD'),
          moment(Date.now()).format('MMMM, DD'),
        ],
      },
    },
  };
  componentDidMount = async () => {
    try {
      const { data } = await httpAdminService.get(`${process.env.REACT_APP_APIENDPOINT}/admin/graph`);
      if (data) {
        this.setState({
          series: [
            {
              name: 'New Signup',
              data: [
                data.newSignup7,
                data.newSignup6,
                data.newSignup5,
                data.newSignup4,
                data.newSignup3,
                data.newSignup2,
                data.newSignup1,
              ],
            },
            {
              name: 'Post',
              data: [data.posts7, data.posts6, data.posts5, data.posts4, data.posts3, data.posts2, data.posts1],
            },
            {
              name: 'Collection',
              data: [
                data.collections7,
                data.collections6,
                data.collections5,
                data.collections4,
                data.collections3,
                data.collections2,
                data.collections1,
              ],
            },
          ],
        });
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
    new WOW.WOW({
      live: false,
    }).init();
  };
  render() {
    return (
      <div className="line-chart-view wow fadeInUp">
        <div className="main-section ">
          <div>
            <div id="chart ">
              <ReactApexChart options={this.state.options} series={this.state.series} type="area" height={400} />
            </div>
            <div id="html-dist"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Linechart;
