import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../commonComponents/userheader';
import WOW from 'wowjs';
import httpService from '../services/httpService';
import { toast } from 'react-toastify';
import Sidebar from '../commonComponents/sidebar';
import Events from '../commonComponents/events';
import Contactus from '../commonComponents/contactus';
import EditPassword from './editPassword';
import Bounce from 'react-reveal/Bounce';
import MediaQuery from 'react-responsive';

class EditPasswordSection extends Component {
  state = {
    user: '',
  };

  async componentDidMount() {
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/myData`);
      if (data) {
        const user = data.user;
        this.setState({ user: user });

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }

    new WOW.WOW({
      live: false,
    }).init();
  }
  render() {
    if (!this.state.user) return null;
    return (
      <>
        <div className="userhomepage">
          <div className="container-fluid menu-parts wow slideInDown">
            <Header user={this.state.user} {...this.props} />
          </div>
          <div className="container-fluid user-activity">
            <div className="container">
              <Contactus />

              <div className="row">
                <div className="col-2">
                  <Sidebar {...this.props} />
                </div>
                <Bounce>
                  <div className="col-8 wow bounceIn">
                    <EditPassword />
                  </div>
                </Bounce>
                <div className="col-2">
                  <Events />
                </div>
              </div>
            </div>
          </div>
          <reactScrollUpButton />
        </div>
        <MediaQuery query="(max-width:767px)">
          <div className="userhomepage1">
            <div className="container-fluid menu-parts wow slideInDown">
              <Header user={this.state.user} {...this.props} />
            </div>
            <div className=" user-activity">
              <div className="">
                {/* <Contactus /> */}
                <div className="row">
                  <div className="col-2">
                    <Sidebar {...this.props} />
                  </div>
                  <Bounce>
                    <div className="col-12 wow bounceIn">
                      <EditPassword />
                    </div>
                  </Bounce>
                </div>
              </div>
            </div>
            <reactScrollUpButton />
          </div>
        </MediaQuery>
      </>
    );
  }
}

export default EditPasswordSection;
