import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
class ClassBackPost extends Component {
  render() {
    return (
      <div>
        <div className="eventsback-post">
          <div className="main-section">
            <h3>
              <Link to="/learn-with-bthrive">
                <i className="fa fa-angle-double-left"></i>
              </Link>{' '}
              Showing All Classes{' '}
            </h3>
          </div>
        </div>
      </div>
    );
  }
}

export default ClassBackPost;
