import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Modal, Button } from 'react-bootstrap';

const settings = {
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 4,
  arrows: true,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

class HowItWork extends Component {
  state = {};
  handleModalShowHide1() {
    this.setState({ showHide1: !this.state.showHide1 });
  }
  handleModalShowHide2() {
    this.setState({ showHide2: !this.state.showHide2 });
  }
  handleModalShowHide3() {
    this.setState({ showHide3: !this.state.showHide3 });
  }
  handleModalShowHide4() {
    this.setState({ showHide4: !this.state.showHide4 });
  }
  handleModalShowHide5() {
    this.setState({ showHide5: !this.state.showHide5 });
  }
  handleModalShowHide6() {
    this.setState({ showHide6: !this.state.showHide6 });
  }
  render() {
    return (
      <div className="howit-work">
        <div className="container">
          <div className="main-section">
            <div className="heading-section text-center">
              <h3 className="wow fadeInUp">Meet the Team</h3>
            </div>
            <div className="space-itemislider ourteams">
              <div className="slider-parts">
                <div>
                  <Slider {...settings}>
                    <Link className="active">
                      <div className="rightpart">
                        <img src="assets/images/teams/3.png" alt="teams" />
                        <p>
                          <b>Biko Somuah</b> <br />
                          Co-Founder & Co-CEO
                        </p>
                        <p className="des">
                          Black empowerment - there is nothing I’m more passionate about. As a conscious techpreneur,
                          building platforms such as B-Thrive is near and dear to my heart, as self and spiritual
                          empowerment, creativity, mindful business...{' '}
                          <Link className="color" onClick={() => this.handleModalShowHide4()}>
                            Read More
                          </Link>
                        </p>
                      </div>
                    </Link>
                    <Link className="active">
                      <div className="rightpart">
                        <img src="assets/images/teams/2.jpg" alt="teams" />
                        <p>
                          <b>Grace lbot-lbot</b> <br />
                          Co-Founder & Co-CEO
                        </p>
                        <p className="des">
                          I am a social entrepreneur passionate about implementing solutions to social, cultural
                          challenges, and solving community-based problems. As a kid I visualized my older self with a
                          willingness to take on the risks and efforts to create positive...{' '}
                          <Link className="color" onClick={() => this.handleModalShowHide5()}>
                            Read More
                          </Link>
                        </p>
                      </div>
                    </Link>
                    <Link className="active">
                      <div className="rightpart">
                        <img src="assets/images/teams/4.png" alt="teams" />
                        <p>
                          <b>Steven Kinvi</b> <br />
                          Head of Product & Revenue
                        </p>
                        <p className="des">
                          I strongly believe in B-Thrive’s vision and its founders, which drove my interest to join the
                          team. I am highly passionate about connecting the Black diasporas, along with building
                          innovative technologies that make a valuable...{' '}
                          <Link className="color" onClick={() => this.handleModalShowHide6()}>
                            Read More
                          </Link>
                        </p>
                      </div>
                    </Link>
                    <Link className="active">
                      <div className="rightpart">
                        <img src="assets/images/teams/1.jpg" alt="teams" />
                        <p>
                          <b>Tima Fofana</b> <br />
                          Marketing Manager
                        </p>
                        <p className="des">
                          As a storyteller with an enthusiasm for all things self-empowerment, ancestral wisdom, and
                          entrepreneurship, I wanted to join the B-Thrive team to empower my people. It’s time for us to
                          take control, shift, and spread our own narratives....{' '}
                          <Link className="color" onClick={() => this.handleModalShowHide1()}>
                            Read More
                          </Link>
                        </p>
                      </div>
                    </Link>
                    <Link className="active">
                      <div className="rightpart">
                        <img src="assets/images/teams/7.jpeg" alt="teams" />
                        <p>
                          <b>Damilola Durowoju</b> <br />
                          Partnership Manager
                        </p>
                        <p className="des">
                          I am a pharmaceutical scientist by day and startup partnership manager by night. In addition,
                          I’m currently rediscovering my connection with the continent of Africa through its glorious
                          foods and music.
                          <Link className="color" onClick={() => this.handleModalShowHide2()}>
                            Read More
                          </Link>
                        </p>
                      </div>
                    </Link>
                    <Link className="active">
                      <div className="rightpart">
                        <img src="assets/images/teams/5.png" alt="teams" />
                        <p>
                          <b>Bianca Mondestin</b>
                          <br />
                          Diversity and Inclusion Manager
                        </p>
                        <p className="des">
                          As a passionate advocate for underrepresented groups, namely women and black people,
                          B-Thrive’s mission and vision really spoke to me. Joining B-Thrive means joining a revolution.
                          There’s no better time than now ...{' '}
                          <Link className="color" onClick={() => this.handleModalShowHide3()}>
                            Read More
                          </Link>
                        </p>
                      </div>
                    </Link>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.showHide1} className="newupdate-modal2 ">
          <Modal.Body>
            <div className="newupdate2">
              <h4 className="text-center">Tima Fofana, Mandinka with roots in Gambia and Mali </h4>
              <p className="text-center">
                <b>Marketing Manager</b>
              </p>
              <div className="messege-box">
                <p>
                  As a storyteller with an enthusiasm for all things self-empowerment, ancestral wisdom, and
                  entrepreneurship, I wanted to join the B-Thrive team to empower my people. It’s time for us to take
                  control, shift, and spread our own narratives. I want my people to have a sense of pride in their
                  heritage, a positive outlook about their future, and a desire to unite as a people - to ensure we
                  thrive together.
                </p>
              </div>
              <div className="submit-buttons text-center">
                <Button variant="secondary" onClick={() => this.handleModalShowHide1()}>
                  Close
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.showHide2} className="newupdate-modal2 ">
          <Modal.Body>
            <div className="newupdate2">
              <h4 className="text-center">Damilola Durowoju, Yoruba, Nigerian- Irish</h4>
              <p className="text-center">
                <b>Partnership Manager</b>
              </p>
              <div className="messege-box">
                <p>
                  I am a pharmaceutical scientist by day and startup partnership manager by night. In addition, I’m
                  currently rediscovering my connection with the continent of Africa through its glorious foods and
                  music. That said, I joined the B-Thrive team to further celebrate black culture and history. Being a
                  part of B-thrive has not only allowed me to learn more about the richness and greatness of Africa and
                  its diaspora, it’s enabled me to be able to share that knowledge as well. This is the perfect
                  opportunity for us to unite, empower, and connect.
                </p>
              </div>
              <div className="submit-buttons text-center">
                <Button variant="secondary" onClick={() => this.handleModalShowHide2()}>
                  Close
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.showHide3} className="newupdate-modal2 ">
          <Modal.Body>
            <div className="newupdate2">
              <h4 className="text-center">Bianca Mondestin</h4>
              <p className="text-center">
                <b>Diversity and Inclusion Manager</b>
              </p>
              <div className="messege-box">
                <p>
                  As a passionate advocate for underrepresented groups, namely women and black people, B-Thrive’s
                  mission and vision really spoke to me. Joining B-Thrive means joining a revolution. There’s no better
                  time than now to create lasting and impactful changes for our community. As a management consultant,
                  I’m striving to do the same, as there is a lack of people who look like me in the field. Within my
                  career, I am mirroring the B-Thrive vision by helping companies across Canada and APAC make a positive
                  impact on their employees and communities through a strong DI strategy that empowers minorities to
                  thrive and reach their full potential - empower them to reach excellence.
                </p>
              </div>
              <div className="submit-buttons text-center">
                <Button variant="secondary" onClick={() => this.handleModalShowHide3()}>
                  Close
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.showHide4} className="newupdate-modal2 ">
          <Modal.Body>
            <div className="newupdate2">
              <h4 className="text-center">Biko Somuah, Ghanaian Afro-German Co-founder </h4>
              <p className="text-center">
                <b>Co-Founder & Co-CEO</b>
              </p>
              <div className="messege-box">
                <p>
                  Black empowerment - there is nothing I’m more passionate about. As a conscious techpreneur, building
                  platforms such as B-Thrive is near and dear to my heart, as self and spiritual empowerment,
                  creativity, mindful business, and entrepreneurship are avenues for our overall growth. Co-founding
                  B-Thrive is more than a “tech venture” to me. B-Thrive is a powerful platform where Black and African
                  descendants can build community, connect with their roots, celebrate, restore, and also heal from the
                  past, and forge a path towards a bright, united future.
                </p>
              </div>
              <div className="submit-buttons text-center">
                <Button variant="secondary" onClick={() => this.handleModalShowHide4()}>
                  Close
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.showHide5} className="newupdate-modal2 ">
          <Modal.Body>
            <div className="newupdate2">
              <h4 className="text-center">Grace lbot-lbot, Cameroonian, Afro-French, Co-founder </h4>
              <p className="text-center">
                <b>Co-Founder & Co-CEO</b>
              </p>
              <div className="messege-box">
                <p>
                  I am a social entrepreneur passionate about implementing solutions to social, cultural challenges, and
                  solving community-based problems. As a kid I visualized my older self with a willingness to take on
                  the risks and efforts to create positive changes in society, hence the creation of B-Thrive. From
                  empowerment and unification among Black and African descendants to making knowledge accessible to the
                  masses, it is our job to pay it forward. Just as our ancestors cleared a path for us - it’s our job to
                  clear the next generation’s path and create generational wealth, while studying our history and roots
                  as a community.
                </p>
              </div>
              <div className="submit-buttons text-center">
                <Button variant="secondary" onClick={() => this.handleModalShowHide5()}>
                  Close
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.showHide6} className="newupdate-modal2 ">
          <Modal.Body>
            <div className="newupdate2">
              <h4 className="text-center">Steven Kinvi, Togolese with roots in Ghana </h4>
              <p className="text-center">
                <b>Head of Product & Revenue</b>
              </p>
              <div className="messege-box">
                <p>
                  I strongly believe in B-Thrive’s vision and its founders, which drove my interest to join the team. I
                  am highly passionate about connecting the Black diasporas, along with building innovative technologies
                  that make a valuable, impactful difference in the lives of those in our community. I am happy to be on
                  this journey as a B-Thrive team member.
                </p>
              </div>
              <div className="submit-buttons text-center">
                <Button variant="secondary" onClick={() => this.handleModalShowHide6()}>
                  Close
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default HowItWork;
