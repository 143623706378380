import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { withRouter } from 'react-router-dom';
class Eventbackpost extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <div className="eventsback-post">
          <div className="main-section">
            <h3>
              <Link onClick={() => this.props.history.goBack()}>
                <i className="fa fa-angle-double-left"></i>
              </Link>{' '}
              Showing All Events{' '}
            </h3>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Eventbackpost);
