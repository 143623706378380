import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
class CreatingNewWorld extends Component {
  state = {};
  render() {
    return (
      <div className="creating-newworld">
        <div className="container">
          <div className="main-section">
            <div className="heading-section text-center">
              <h3 className="wow fadeInUp">
                CREATING A NEW WORLD WHERE
                <br /> ALL BLACK PEOPLE THRIVE
              </h3>
            </div>
            <div className="boxes-section">
              <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <div className="grid-section">
                    <img src="assets/images/icons/01.jpg" alt="Creating World " className="wow zoomIn" />
                    <h3 className="wow fadeInUp">
                      Connecting and educating one another is the first step in realizing our greatness. We are the
                      change our ancestors prayed for and dreamed of. Let’s astound them.
                    </h3>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <div className="grid-section">
                    <img src="assets/images/icons/02.jpg" alt="Creating World " className="wow zoomIn" />
                    <h3 className="wow fadeInUp">
                      Our identities are our superpowers. From ancient roots to modern traditions, our global impact and
                      experiences are just as diverse as the whole African diaspora.
                    </h3>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <div className="grid-section">
                    <img src="assets/images/icons/03.jpg" alt="Creating World " className="wow zoomIn" />
                    <h3 className="wow fadeInUp">
                      History has shown us - there is power in community and unity. Our collective story is one of
                      distinction, royalty, ingenuity, and survival. It’s time for history to repeat itself.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreatingNewWorld;
