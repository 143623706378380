import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
class UpCommingEvents extends Component {
  state = {};
  render() {
    return (
      <div className="upcomming-events">
        <div className="container">
          <div className="main-section">
            <div className="heading-section text-center">
              <h3 className="wow fadeInUp">JOURNEY WITH US</h3>
              <p>
                Join our community of descendants of the Black community, as we reconnect with our roots, reclaim our
                identities,
                <br />
                embrace our true selves, and forge paths towards cultural prosperity, through online experiences.
              </p>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="events-list pull-right">
                  <h5 className="wow fadeInUp">Explore the Intricacies of Black economics</h5>
                  <p className="wow fadeInUp">
                    <i className="fa fa-calendar"></i> 12 March 2021
                  </p>
                  <p className="wow fadeInUp">
                    <i className="fa fa-clock-o"></i> 4pm - 5pm
                  </p>
                  <img src="assets/images/home/map.jpg" alt="Map " className="wow zoomIn" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="events-list ">
                  <h5 className="wow fadeInUp">Explore the Intricacies of Black economics</h5>
                  <p className="wow fadeInUp">
                    <i className="fa fa-calendar"></i> 12 March 2021
                  </p>
                  <p className="wow fadeInUp">
                    <i className="fa fa-clock-o"></i> 4pm - 5pm
                  </p>
                  <img src="assets/images/home/map.jpg" alt="Map " className="wow zoomIn" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UpCommingEvents;
