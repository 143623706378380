import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import ShowMoreText from 'react-show-more-text';
import httpService from '../../../services/httpService';
import { toast } from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import Feedbacksupport from '../../../commonComponents/feedbacksupport';
class About extends Component {
  constructor(props) {
    super(props);
    this.deactivate = this.deactivate.bind(this);
  }
  state = {};

  deactivate = async () => {
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/deactivate`);
      if (data) {
        toast.success(data.message);
        await this.props.history.push('/signOut');
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  };
  handleModalShowHide = () => {
    this.setState({ showHide: !this.state.showHide });
  };

  render() {
    if (!this.props.user) return null;
    return (
      <div className="about-section">
        <div className="about-me">
          <h3>About Me</h3>
          <p>
            <ShowMoreText
              /* Default options */
              lines={3}
              more="Read more"
              less="Read less"
              onClick={this.executeOnClick}
              expanded={false}
            >
              {this.props.user.description}
            </ShowMoreText>
          </p>
        </div>
        <div className="about-me">
          <h3>Interests</h3>
          <ul>
            {this.props.user.interest.map((user, index) => (
              <li>{user ? <Link to="/spaces">{user.name}</Link> : null}</li>
            ))}
          </ul>
        </div>
        <div className="suggection-part text-center">
          <p>Got suggections or improvements?</p>
          <Link onClick={() => this.handleModalShowHide()}>Give Us Feedback?</Link>
        </div>
        <Modal show={this.state.showHide} className="addcollection-modal">
          <Modal.Body>
            <Link className="closeicon" onClick={() => this.handleModalShowHide()}>
              <i className="fa fa-close"></i>
            </Link>
            <Feedbacksupport onSubmit={this.handleModalShowHide} />
          </Modal.Body>
        </Modal>
        <div className="bottom-links text-center mt-5 mb-5">
          <Link to="/edit-profile">Edit Profile</Link>
          <Link onClick={this.deactivate}>Deactivate</Link>
          <Link to="/signOut">Logout</Link>
        </div>
      </div>
    );
  }
}

export default withRouter(About);
