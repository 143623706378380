import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import WOW from 'wowjs';
import AdminHeader from '../commonComponents/adminheader';
import { CircleArrow as ScrollUpButton } from 'react-scroll-up-button';
import Topmenubar from '../commonComponents/topmenubar';
import httpAdminService from './../../services/httpAdminService';
import { toast } from 'react-toastify';
import BookClubShorting from './bookClubSection/bookClubShorting';
import BookClubtable from './bookClubSection/bookClublisttable';
import moment from 'moment';
class BookClublist extends Component {
  state = {
    usersData: '',
    host: '',
  };
  async componentDidMount() {
    this.getHosts();
    try {
      const { data } = await httpAdminService.get(`${process.env.REACT_APP_APIENDPOINT}/admin/usersData`);
      if (data) {
        this.setState({ usersData: data.usersData });
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }

    new WOW.WOW({
      live: false,
    }).init();
  }

  async getHosts() {
    try {
      const { data } = await httpAdminService.get(`${process.env.REACT_APP_APIENDPOINT}/admin/getHostRequests`);
      if (data) {
        data.host.forEach((item) => {
          item.date = item.date && moment(item.date).format('LLL');
        });
        this.setState({ host: data.host });
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }

    new WOW.WOW({
      live: false,
    }).init();
  }
  sortHosts = async (sort) => {
    try {
      const { data } = await httpAdminService.get(
        `${process.env.REACT_APP_APIENDPOINT}/admin/getHostRequests?sort=${sort}`,
      );
      if (data) {
        data.host.forEach((item) => {
          item.date = item.date && moment(item.date).format('LLL');
        });
        this.setState({ host: data.host });
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }

    new WOW.WOW({
      live: false,
    }).init();
  };

  render() {
    if (!this.state.usersData) return null;
    return (
      <div className="userhomepage">
        <div className="container-fluid menu-parts wow slideInDown">
          <AdminHeader />
        </div>
        <div className="container-fluid user-activity">
          <div className="container">
            <div className="row">
              <div className="col-12 wow fadeInUp">
                <Topmenubar user={this.props.user} {...this.props} />
                <BookClubShorting onSortChange={this.sortHosts} />
                <BookClubtable data={this.state.host} />
              </div>
            </div>
          </div>
        </div>
        {/* <ScrollUpButton /> */}
      </div>
    );
  }
}

export default BookClublist;
